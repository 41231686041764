import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Surface_MODE from './Surface_MODE';
import Matcher_MODE from './Matcher_MODE';
import reportWebVitals from './reportWebVitals';

import {BrowserRouter, Routes, Route} from "react-router-dom";


ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Surface_MODE/>}/>
        <Route path="/match" element={<Matcher_MODE/>}/>
        <Route path="/surface" element={<Surface_MODE/>}/>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
