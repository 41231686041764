import logo from './logo.svg';
import './App.css';
//////////////////////////////

//THREE STUFF
  import React from 'react';
  import ReactDOM from 'react-dom';
  import * as THREE from 'three'
  import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
  import { DragControls } from 'three/examples/jsm/controls/DragControls'
  import { PLYLoader } from 'three/examples/jsm/loaders/PLYLoader'
  import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader'
  import Stats from 'three/examples/jsm/libs/stats.module'
  import { SelectionBox } from 'three/examples/jsm/interactive/SelectionBox.js';
  import { SelectionHelper } from 'three/examples/jsm/interactive/SelectionHelper.js';
  import {PNG} from 'pngjs'

  import add from './media/icon_add.svg'
  import mark from './media/icon_mark.svg'
  import orient from './media/icon_orient.svg'
  import match from './media/icon_match.svg'

  const scene = new THREE.Scene()
    scene.add(new THREE.AxesHelper(5))
  const light = new THREE.SpotLight()
    light.position.set(20, 20, 20)
    scene.add(light)
  const camera = new THREE.PerspectiveCamera(
      75,
      window.innerWidth / window.innerHeight,
      0.1,
      1000
  )
    camera.position.z = 2
    camera.far = 200
    camera.near = .001
  const renderer = new THREE.WebGLRenderer()
    renderer.outputEncoding = THREE.sRGBEncoding
    renderer.setSize(window.innerWidth, window.innerHeight)
  const controls = new OrbitControls(camera, renderer.domElement)
    controls.enableDamping = true
    controls.zoomSpeed = 0.5

  const material = new THREE.MeshPhysicalMaterial({
      color: 0xb2ffc8,
      metalness: 0,
      roughness: 0,
      transparent: true,
      transmission: 1.0,
      side: THREE.DoubleSide,
      clearcoat: 1.0,
      clearcoatRoughness: 0.25
  })
  const mat_default = new THREE.PointsMaterial({
    size: 0.00040,
    vertexColors: THREE.VertexColors,
   })
  const mat_red = new THREE.PointsMaterial({ color: 0xFF0000, size: 0.00025 })
  const mat_blue = new THREE.PointsMaterial({ color: 0x0000FF, size: 0.00025 })
  const mat_green = new THREE.PointsMaterial({ color: 0x00FF00, size: 0.00025 })
  const mat_cyan = new THREE.PointsMaterial({ color: 0x00FFFF, size: 0.00025 })
  const mat_white = new THREE.PointsMaterial({ color: 0xFFFFFF, size: 0.00025 })


  function setup(){
    //console.log("yep")
    var parentdiv = document.getElementById("webglcontext_match")
    if(parentdiv==undefined){
      //try again later...
      setTimeout(setup,10)
    }else{
      parentdiv.appendChild(renderer.domElement)
    }
  }
  setup()



  //UTILITY
  const screen_xy = {x:0,y:0,px:0,py:0}
  function getCanvasRelativePosition(event) /*utility, get normalized {x,y}*/ {
    var canvas = renderer.domElement
    const rect = canvas.getBoundingClientRect();

    return {
      x: (event.clientX - rect.left) * canvas.width  / rect.width,
      y: (event.clientY - rect.top ) * canvas.height / rect.height,
    };
  }
  function set_screen_xy(event) {
    const pos = getCanvasRelativePosition(event);
    var canvas = renderer.domElement
    screen_xy.px = pos.x
    screen_xy.py = pos.y
    screen_xy.x = (pos.x / canvas.width ) *  2 - 1;
    screen_xy.y = (pos.y / canvas.height) * -2 + 1;  // note we flip Y

  }
  function clearPickPosition() {
    screen_xy.x = -100000;
    screen_xy.y = -100000;
    screen_xy.px = -100;
    screen_xy.py = -100;
  }


  //FEATURES
  function maskEvaluate() /*first pass at point selection set*/ {
    var lidar
    for (var i = 0; i < scene.children.length; i++) {
      if(scene.children[i].name =="lidar"){
        lidar = scene.children[i].geometry.attributes
      }
    }
      //create mask
      var mask = new Array( lidar.position.count ).fill(false)
      var sum = 0
      console.log(lidar)

      for (var i = 0; i < lidar.position.count; i++) {
        var x = lidar.position.array[i*3]
        var y = lidar.position.array[i*3+1]
        var z = lidar.position.array[i*3+2]
        //console.log("hello?")
        //console.log(x)
        if( x  > 0   ){
          mask[i] = true
          sum++
        }else{mask.push(false)}
      }  //MASK ATTEMPT 1

      console.log("sum: "+sum + " length: "+ mask.length )

      //now that we have mask calculated, create and display geometry
      const mask_geo = new THREE.BufferGeometry();
      mask_geo.name="mask"
      var vertices = new Float32Array(sum*3)
      var count = 0
      for (var j = 0; j < lidar.position.count; j++) {
        if(mask[j]==true){

          vertices[count*3+0] = lidar.position.array[j*3+0]
          vertices[count*3+1] = lidar.position.array[j*3+1]
          vertices[count*3+2] = lidar.position.array[j*3+2]
          count++
        }
      }
      console.log(vertices)
      mask_geo.setAttribute('position', new THREE.BufferAttribute(vertices,3))
      const mesh2 = new THREE.Points(mask_geo, mat_green)
      mesh2.name = "mask_frustum"
      scene.add(mesh2)
      console.log(scene)
  }
  function frustumGeoEvaluate(frustum,obj) /*lets use the frustum to create some geometry output*/ {
    if(obj!=undefined){
      var mask = new Array( obj.position.count ).fill(false)
      var sum = 0

      for (var i = 0; i < obj.position.count; i++) {
        var x = obj.position.array[i*3]
        var y = obj.position.array[i*3+1]
        var z = obj.position.array[i*3+2]

        if(frustum.containsPoint( new THREE.Vector3(x,y,z) )){
          if(i<5){
            console.log(new THREE.Vector3(x,y,z))
            console.log(frustum)
          }
          mask[i] = true
          sum++
        }else{
          mask[i] = false
        }
      }


      console.log("sum: "+sum + " length: "+ mask.length )

      //now that we have mask calculated, create and display geometry
      const mask_geo = new THREE.BufferGeometry();
      //mask_geo.name="rmask"
      var vertices = new Float32Array(sum*3)
      var count = 0
      for (var j = 0; j < obj.position.count; j++) {
        if(mask[j]==true){

          vertices[count*3+0] = obj.position.array[j*3+0]
          vertices[count*3+1] = obj.position.array[j*3+1]
          vertices[count*3+2] = obj.position.array[j*3+2]
          count++
        }
      }
      console.log(vertices)
      mask_geo.setAttribute('position', new THREE.BufferAttribute(vertices,3))
      const mesh2 = new THREE.Points(mask_geo, mat_cyan)
      mesh2.name = "rmask"
      scene.add(mesh2)
      console.log(scene)
    }
  }


  var selectionRectangle = {
    div_add: document.getElementById("ADDrect"),
    div_sub: document.getElementById("SUBrect"),
    type: 0, //0=false, 1=add, 2=subtract
    state: -1, // number of clicks, -1=inactive, 0=unanchored, 1=pinned, 2=fully defined
    start: {x:0,y:0,px:0,py:0},
    end: {x:0,y:0,px:0,py:0},
    cameraData: {}, //to rebuild camera frustum (optional)
    termination: function(){/*console.log("rectangle done!")*/}
  }

  //start a selection rectangle
  function startRectangle(mode) {

    selectionRectangle.div_add = document.getElementById("ADDrect")
    selectionRectangle.div_sub = document.getElementById("SUBrect")
    selectionRectangle.div_add.style.left = screen_xy.px.toString()+"px"
    selectionRectangle.div_add.style.top = screen_xy.py.toString()+"px"
    selectionRectangle.state = 0 //begins anchored to mouse
    selectionRectangle.div_add.style.display = "block"
    if(mode=="add"){
      selectionRectangle.div_add.style.display = ""
      selectionRectangle.div_sub.style.display = "none"
      selectionRectangle.type = 1 //additive
    }
    if(mode=="sub"){
      selectionRectangle.div_add.style.display = "none"
      selectionRectangle.div_sub.style.display = ""
      selectionRectangle.type = 2 //subtractive
    }

    window.addEventListener('touchstart', rectangle_start);
    window.addEventListener('mousedown', rectangle_start);
    window.addEventListener('touchend', rectangle_end);
    window.addEventListener('mouseup', rectangle_end);
  }
  function rectangle_begin()/*pre selection*/ {
    selectionRectangle.state=0 //no points are defined
    selectionRectangle.start = JSON.parse(JSON.stringify( screen_xy))

  }
  function rectangle_start()/*one point down*/{
    selectionRectangle.state=1 //one point is now defined.
    selectionRectangle.start = JSON.parse(JSON.stringify( screen_xy))

  }
  function rectangle_end() /*two points down, remove events*/{
    selectionRectangle.state=2 //both points are now defined
    selectionRectangle.end = JSON.parse(JSON.stringify( screen_xy))
    selectionRectangle.div_add.style.display = "none"
    selectionRectangle.div_sub.style.display = "none"
    selectionRectangle.termination()

    //calculate and display frustum
    console.log(frustumFromRect(camera, selectionRectangle.start, selectionRectangle.end))
    frustumGeoEvaluate(frustumFromRect(camera, selectionRectangle.start, selectionRectangle.end))

    window.removeEventListener('touchstart', rectangle_start);
    window.removeEventListener('mousedown', rectangle_start);
    window.removeEventListener('touchend', rectangle_end);
    window.removeEventListener('mouseup', rectangle_end);
  }
  function rectangle_update() /*called from animation loop*/ {
    if(selectionRectangle.state==0){ //unanchored
      selectionRectangle.div_add.style.left =  (screen_xy.px-20).toString()+"px"
      selectionRectangle.div_add.style.top =  (screen_xy.py-20).toString()+"px"
      selectionRectangle.div_add.style.width =  "20px"
      selectionRectangle.div_add.style.height = "20px"

      selectionRectangle.div_sub.style.left =  (screen_xy.px-20).toString()+"px"
      selectionRectangle.div_sub.style.top =  (screen_xy.py-20).toString()+"px"
      selectionRectangle.div_sub.style.width =  "20px"
      selectionRectangle.div_sub.style.height = "20px"
    }
    if(selectionRectangle.state==1){ //anchored
      //use minimum coordinate to start
      var minx = Math.min(screen_xy.px, selectionRectangle.start.px)
      var miny = Math.min(screen_xy.py, selectionRectangle.start.py)
      selectionRectangle.div_add.style.left =  (minx).toString()+"px"
      selectionRectangle.div_add.style.top =  (miny).toString()+"px"
      selectionRectangle.div_add.style.width =  Math.abs(screen_xy.px - selectionRectangle.start.px).toString()+"px"
      selectionRectangle.div_add.style.height =   Math.abs(screen_xy.py - selectionRectangle.start.py).toString()+"px"

      selectionRectangle.div_sub.style.left =  selectionRectangle.div_add.style.left
      selectionRectangle.div_sub.style.top =  selectionRectangle.div_add.style.top
      selectionRectangle.div_sub.style.width =  selectionRectangle.div_add.style.width
      selectionRectangle.div_sub.style.height =   selectionRectangle.div_add.style.height


    }
    if(selectionRectangle.state==2){ //fully defined
      //use minimum coordinate to start
      var minx = Math.min(selectionRectangle.end.px, selectionRectangle.start.px)
      var miny = Math.min(selectionRectangle.end.py, selectionRectangle.start.py)
      selectionRectangle.div_add.style.left =  (minx).toString()+"px"
      selectionRectangle.div_add.style.top =  (miny).toString()+"px"
      selectionRectangle.div_add.style.width =  Math.abs(selectionRectangle.end.px - selectionRectangle.start.px).toString()+"px"
      selectionRectangle.div_add.style.height =   Math.abs(selectionRectangle.end.py - selectionRectangle.start.py).toString()+"px"

      selectionRectangle.div_sub.style.left =  selectionRectangle.div_add.style.left
      selectionRectangle.div_sub.style.top =  selectionRectangle.div_add.style.top
      selectionRectangle.div_sub.style.width =  selectionRectangle.div_add.style.width
      selectionRectangle.div_sub.style.height =   selectionRectangle.div_add.style.height

      //animate box fade out?
    }
  }
  function maskBox() /*EXAMPLE, Detect point using camera view and screen space coordinates.*/ {
    camera.updateMatrix();
    camera.updateMatrixWorld();
    var a = new THREE.Frustum();
    console.log(camera)
    var frustum = a.setFromMatrix( new THREE.Matrix4().multiplyMatrices( camera.projectionMatrix, camera.matrixWorldInverse ) );
    console.log(frustum)
    //test to see if origin is in view
    var origin = new THREE.Vector3(0,0,0)
    if(frustum.containsPoint(origin)){
      console.log("VIEW CONTAINS ORIGIN")
    }else{
      console.log("NOT DETECTED")
    }
  }
  function frustumFromRect(camera,p1,p2){
    var frustum = new THREE.Frustum();
    var pos0 = new THREE.Vector3(Math.min(p1.x, p2.x), Math.min(p1.y, p2.y));
    var pos1 = new THREE.Vector3(Math.max(p1.x, p2.x), Math.max(p1.y, p2.y));
      // camera direction IS normal vector for near frustum plane
      // say - plane is looking "away" from you
      var cameraDir = new THREE.Vector3();
      camera.getWorldDirection(cameraDir);
      var cameraDirInv = cameraDir.clone().negate();


      // calc the point that is in the middle of the view, and lies on the near plane
      let cameraNear = camera.position.clone().add(cameraDir.clone().multiplyScalar(camera.near));
      // calc the point that is in the middle of the view, and lies on the far plane
      let cameraFar = camera.position.clone().add(cameraDir.clone().multiplyScalar(camera.far));

      // just build near and far planes by normal+point
      frustum.planes[0].setFromNormalAndCoplanarPoint(cameraDir, cameraNear);
      frustum.planes[1].setFromNormalAndCoplanarPoint(cameraDirInv, cameraFar);

    // next 4 planes (left, right, top and bottom) are built by 3 points:
    // camera postion + two points on the far plane
    // each time we build a ray casting from camera through mouse coordinate,
    // and finding intersection with far plane.
    //
    // To build a plane we need 2 intersections with far plane.
    // This is why mouse coordinate will be duplicated and
    // "adjusted" either in vertical or horizontal direction

    //Javascript syntax note: This code uses if statements to create smaller blocks of code where the "let" keyword denotes variable names that are used more than once.
    if (true)/*build frustrum plane on the left*/ {
      let ray = new THREE.Ray();
      ray.origin.setFromMatrixPosition(camera.matrixWorld);
      // Here's the example, - we take X coordinate of a mouse, and Y we set to -0.25 and 0.25
      // values do not matter here, - important that ray will cast two different points to form
      // the vertically aligned frustum plane.
      ray.direction.set(pos0.x, -0.25, 1).unproject(camera).sub(ray.origin).normalize();
      let far1 = new THREE.Vector3();
      ray.intersectPlane(frustum.planes[1], far1);

      ray.origin.setFromMatrixPosition(camera.matrixWorld);
      // Same as before, making 2nd ray
      ray.direction.set(pos0.x, 0.25, 1).unproject(camera).sub(ray.origin).normalize();
      let far2 = new THREE.Vector3();
      ray.intersectPlane(frustum.planes[1], far2);

      frustum.planes[2].setFromCoplanarPoints(camera.position, far1, far2);
    }
    if (true)/*build frustrum plane on the right*/  {
      let ray = new THREE.Ray();
      ray.origin.setFromMatrixPosition(camera.matrixWorld);
      ray.direction.set(pos1.x, 0.25, 1).unproject(camera).sub(ray.origin).normalize();
      let far1 = new THREE.Vector3();
      ray.intersectPlane(frustum.planes[1], far1);

      ray.origin.setFromMatrixPosition(camera.matrixWorld);
      ray.direction.set(pos1.x, -0.25, 1).unproject(camera).sub(ray.origin).normalize();
      let far2 = new THREE.Vector3();
      ray.intersectPlane(frustum.planes[1], far2);

      frustum.planes[3].setFromCoplanarPoints(camera.position, far1, far2);
    }
    if (true)/*build frustrum plane on the top*/  {
      let ray = new THREE.Ray();
      ray.origin.setFromMatrixPosition(camera.matrixWorld);
      ray.direction.set(0.25, pos0.y, 1).unproject(camera).sub(ray.origin).normalize();
      let far1 = new THREE.Vector3();
      ray.intersectPlane(frustum.planes[1], far1);

      ray.origin.setFromMatrixPosition(camera.matrixWorld);
      ray.direction.set(-0.25, pos0.y, 1).unproject(camera).sub(ray.origin).normalize();
      let far2 = new THREE.Vector3();
      ray.intersectPlane(frustum.planes[1], far2);

      frustum.planes[4].setFromCoplanarPoints(camera.position, far1, far2);
    }
    if (true)/*build frustrum plane on the bottom*/  {
      let ray = new THREE.Ray();
      ray.origin.setFromMatrixPosition(camera.matrixWorld);
      ray.direction.set(-0.25, pos1.y, 1).unproject(camera).sub(ray.origin).normalize();
      let far1 = new THREE.Vector3();
      ray.intersectPlane(frustum.planes[1], far1);

      ray.origin.setFromMatrixPosition(camera.matrixWorld);
      ray.direction.set(0.25, pos1.y, 1).unproject(camera).sub(ray.origin).normalize();
      let far2 = new THREE.Vector3();
      ray.intersectPlane(frustum.planes[1], far2);

      frustum.planes[5].setFromCoplanarPoints(camera.position, far1, far2);
    }
    return(frustum)
  }

  //now we select a point for the markers
  //process:
  //  click button to enter marker placement
  //  show something to indicate that user is placing marker x,y, or z
  //  click again to get raycast location
  //  on mouseup, we remove note, add marker geo, and remove events
  const heel_style ={
    backgroundColor:"hsla(60, 100%, 40%, 0.2)",
    borderColor:"hsla(60, 100%, 75%, .8)",
    color:"hsla(60, 100%, 50%, 1.0)",
    padding: "1em",
    borderStyle:"solid",
    position:"absolute",
    left: "1em",
    bottom: "1em",
    borderWidth:"0.1px",
    zIndex:"2",
    display:"visible",

  }
  var heel_UI_note = <div id="heel" style={heel_style}>specify heel marker (heel = 0) ... (inner metatarsal = 1) ... (outer metatarsal = 2)</div>
  var heel_UI_note_none = <div id="heel" style={heel_style}></div>
  var markerUpdate = -1 // -1 is no, 0 is heel, 1 is 1st metatarsal, 5 is 5th metatarsal
  var markerpositions = {
    a:new THREE.Vector3(),
    b:new THREE.Vector3(),
    c:new THREE.Vector3()
  }
  function start_marker() /*user has specified that they want to place a heel marker*/{
    //Give the User some feedback that something has happened.
    ReactDOM.render(heel_UI_note, document.getElementById('uistate'));
    console.log(markerpositions)
    //add the event listeners
    markerUpdate = 0;
    window.addEventListener('touchstart', click_marker);
    window.addEventListener('mousedown', click_marker);
    //window.addEventListener('touchend', unclick_marker);
    //window.addEventListener('mouseup', unclick_marker);
  }
  function click_marker() {

    var lidar //object we want to place markers on. (is default for now, could be selected object later)
    for (var i = 0; i < scene.children.length; i++) {
      if(scene.children[i].name =="lidar"){
        lidar = scene.children[i]
      }
    }

    //do raycast
    const raycaster = new THREE.Raycaster();
    raycaster.params.Points.threshold = 0.01;
    raycaster.setFromCamera(screen_xy,camera)

    const intersects = raycaster.intersectObjects( [lidar] ); //only test against one object (optimize)
    if(intersects[0]!=undefined){
      var sconstruct=  new THREE.SphereGeometry(0.01, 6, 6)
      const material = new THREE.MeshBasicMaterial( { color: 0xffff00 } );
      const sphere = new THREE.Mesh( sconstruct, material );

      sphere.position.x = intersects[0].point.x
      sphere.position.y = intersects[0].point.y
      sphere.position.z = intersects[0].point.z
      scene.add(sphere)
      if(markerUpdate==0){markerpositions.a=intersects[0].point}
      if(markerUpdate==1){markerpositions.b=intersects[0].point}
      if(markerUpdate==2){markerpositions.c=intersects[0].point}
    }

    markerUpdate++
    if(markerUpdate>2){unclick_marker()}
  }
  function unclick_marker(){
    markerUpdate = -1
    window.removeEventListener('touchstart', click_marker);
    window.removeEventListener('mousedown', click_marker);
    window.removeEventListener('touchend', unclick_marker);
    window.removeEventListener('mouseup', unclick_marker);
    ReactDOM.render(heel_UI_note_none, document.getElementById('uistate'));
  }
  function counter_update(){
    var output = document.getElementById("markerCounter")
    if(output!=null && markerUpdate!=-1 )
    {    //set the x and y position to mouse coordinate.
        //set innerHTML to current marker.
        output.style.left = (screen_xy.px+10).toString() +"px"
        output.style.top = (screen_xy.py+20).toString() +"px"
        output.innerHTML = markerUpdate
    }
  }

  function calc_orient(){
    //forward vector
    var frame_forward = new THREE.Vector3()
    var frame_normal = new THREE.Vector3()
    var frame_tangent = new THREE.Vector3()
    var frame_tangent2 = new THREE.Vector3()

    var w_forward = new THREE.Vector3(1,0,0)
    var w_right =   new THREE.Vector3(0,1,0)
    var w_up =      new THREE.Vector3(0,0,1)
    var w_down =    new THREE.Vector3(0,0,-1)


    frame_forward.subVectors(markerpositions.b,markerpositions.a)
    frame_tangent.subVectors(markerpositions.c,markerpositions.a)
    frame_normal.crossVectors(frame_forward,frame_tangent)

    //ALIGN CENTER OF FOOT TO THE MIDDLE OF SURFACE
    var middle_forward = new THREE.Vector3()
    middle_forward = markerpositions.b.clone().lerp(markerpositions.c,0.5)
    middle_forward.sub(markerpositions.a)
    frame_tangent2.crossVectors(middle_forward,frame_normal)

    //local rotation matrix
    console.log(middle_forward)
    console.log(frame_normal)
    console.log(frame_tangent2)

    var v1 = middle_forward.clone().normalize();
    var v2 = frame_normal.clone().normalize();
    var v3 = frame_tangent2.clone().normalize();

    var localm4 = new THREE.Matrix4();
    localm4.makeBasis( middle_forward.normalize() ,frame_tangent2.normalize(), frame_normal.normalize() )
    var localm4_inverse = new THREE.Matrix4();
    localm4_inverse = localm4.invert()

    //GET REFERENCE TO OBJECT TO MOVE
    var mask
    for (var i = 0; i < scene.children.length; i++) {
      if(scene.children[i].name =="rmask"){
        mask = scene.children[i]
      }
    }

    mask.translateX(markerpositions.a.x*-1)
    mask.translateY(markerpositions.a.y*-1)
    mask.translateZ(markerpositions.a.z*-1)
    mask.updateMatrix();
    mask.applyMatrix4(localm4_inverse);

    //clean transform
    mask.updateMatrix();
    mask.geometry.applyMatrix4( mask.matrix );
    mask.position.set( 0, 0, 0 );
    mask.rotation.set( 0, 0, 0 );
    mask.scale.set( 1, 1, 1 );
    mask.updateMatrix();




    //arrowHelper1 = new THREE.ArrowHelper( arrowDirection, markerpos, 0.9, 0xffff00, 0.25, 0.08 );
    var arrow_1 = new THREE.ArrowHelper( frame_forward.normalize(), markerpositions.a, 0.2, 0xff0000, 0.01, 0.01 );
    var arrow_2 = new THREE.ArrowHelper( frame_tangent.normalize(), markerpositions.a, 0.2, 0x00ff00, 0.01, 0.01 );
    var arrow_3 = new THREE.ArrowHelper( frame_normal.normalize(), markerpositions.a, 0.2, 0x1111FF, 0.01, 0.01 );
    var arrow_4 = new THREE.ArrowHelper( frame_tangent2.normalize(), markerpositions.a, 0.2, 0x00FFFF, 0.01, 0.01 );
    var arrow_5 = new THREE.ArrowHelper( middle_forward.normalize(), markerpositions.a, 0.2, 0xFFFFFF, 0.01, 0.01 );
		scene.add( arrow_1 );
    scene.add( arrow_2 );
    scene.add( arrow_3 );
    scene.add( arrow_4 );
    scene.add( arrow_5 );


    console.log( getbbox(mask) )
  }

  var bboxresult = {min:[0,0,0],max:[0,0,0]}
  //send an object to this function to get an upper and lower bounds on the x,y,z
  function getbbox(threeobj) {
    console.log(threeobj)
    var geo = threeobj.geometry
    var pts = geo.attributes.position.array
    var count = geo.attributes.position.count
    //set these variables to the first point coordinates as a default
    var minx= geo.attributes.position.array[0]
    var maxx= geo.attributes.position.array[0]
    var miny= geo.attributes.position.array[1]
    var maxy= geo.attributes.position.array[1]
    var minz= geo.attributes.position.array[2]
    var maxz= geo.attributes.position.array[2]

    for(var i = 0 ; i<count; i++){
      let ix = geo.attributes.position.array[i*3+0]
      let iy = geo.attributes.position.array[i*3+1]
      let iz = geo.attributes.position.array[i*3+2]
      //do the comarisons
      minx = Math.min(minx,ix)
      miny = Math.min(miny,iy)
      minz = Math.min(minz,iz)
      maxx = Math.max(maxx,ix)
      maxy = Math.max(maxy,iy)
      maxz = Math.max(maxz,iz)
    }

    /* //draw bbox
    var geocube = new THREE.BoxGeometry(Math.abs(maxx-minx), Math.abs(maxy-miny), Math.abs(maxz-minz), 20,20,20 )
    const pointcube = new THREE.Points( geocube, mat_white );
    pointcube.translateX( (minx+maxx)/2 )
    pointcube.translateY( (miny+maxy)/2 )
    pointcube.translateZ( (minz+maxz)/2 )
    scene.add(pointcube)
    */
    //share bbox
    bboxresult = ( {min:[minx,miny,minz],max:[maxx,maxy,maxz]} )
    return bboxresult
  }
  function getbbox_from_positions(vec3array){

    let result = {min: vec3array[0].clone(),max: vec3array[0].clone()}
    for (var i = 0; i < vec3array.length; i++) {
      let x = vec3array[i].clone().x;
      let y = vec3array[i].clone().y;
      let z = vec3array[i].clone().z;
      result.min.x = Math.min(result.min.x, x);
      result.min.y = Math.min(result.min.y, y);
      result.min.z = Math.min(result.min.z, z);
      result.max.x = Math.max(result.max.x, x);
      result.max.y = Math.max(result.max.y, y);
      result.max.z = Math.max(result.max.z, z);
    }
    return result;
  }

  function map_range(value, low1, high1, low2, high2) {
      return low2 + (high2 - low2) * (value - low1) / (high1 - low1);
  }
  var calculation_time = 55;
  var surface_UI_note = <div id="note" style={heel_style}> Minimum Distance Field Solved! Time Elapsed: {calculation_time}ms </div>
  //tells what square patch the current index belongs to, size is pixels^2
  function calc_cell(rez_x,rez_y,size,index){
    let pixel_x =           (index%rez_x)
    let pixel_y = Math.floor(index/rez_x)
    let cells_per_row = Math.floor(rez_x/size)
    //var index_cell =  parseInt(j/subgridsize) - parseInt( (j/resolution_x) * index_rows ) + parseInt( j/(resolution_x*subgridsize) * index_rows )
    let index_cell = parseInt(pixel_x/size) + (( parseInt( pixel_y/size ) * (cells_per_row)) )
    return(index_cell)
  }
  function generate_surface (){
    var beginTime = Date.now()
    //var resolution_x = 640
    //var resolution_y = 480
    var resolution_x = 200
    var resolution_y = 100
    var spacing = 0.002 //pixel density

    var mid_x = resolution_x/2*spacing
    var mid_y = resolution_y/2*spacing

    var world_mid_x = (bboxresult.max[0]-bboxresult.min[0])/2
    var world_mid_y = (bboxresult.max[1]-bboxresult.min[1])/2

    var mask //if we want to do distance field, the reference is here.
    for (var i = 0; i < scene.children.length; i++)  {
      if(scene.children[i].name =="rmask"){
        mask = scene.children[i]
      }
    }

    var minimum_distance = 99999; var maximum_distance= 0 //useful for creating color gradient



    const surface = new THREE.BufferGeometry();
    surface.name= "surface"
    surface.resolution_x = resolution_x
    surface.resolution_y = resolution_y
    var distances = new Float32Array(resolution_x*resolution_y)
    var vertices = new Float32Array(resolution_x*resolution_y*3)
    var color = new Float32Array(resolution_x*resolution_y*3)

    for( var j = 0; j< resolution_x*resolution_y; j++)/*create array of vertex locations, and calculate distance*/{
      var px = spacing*(j%resolution_x) +bboxresult.min[0]-.02
      var py = spacing*(j/resolution_x) +bboxresult.min[1]-.02
      var pz = bboxresult.min[2] //z is minimum
      vertices[j*3+0]= px
      vertices[j*3+1]= py
      vertices[j*3+2]= pz

      var distance = 999999// default is max distance
      //go through each point in Lidar
      let p1 = new THREE.Vector3( px, py, pz )
      for(var l=0;l<mask.geometry.attributes.position.count;l++){
        let p2 = new THREE.Vector3( mask.geometry.attributes.position.array[l*3+0], mask.geometry.attributes.position.array[l*3+1], mask.geometry.attributes.position.array[l*3+2])
        let d = p1.distanceTo(p2)
        distance = Math.min(distance,d) //get minimum distance
      }
      distances[j]=distance
      minimum_distance = Math.min(minimum_distance, distance)
      maximum_distance = Math.max(maximum_distance, distance)
    }


    for( var j = 0; j< resolution_x*resolution_y; j++)/*utilize mni and max distance to create gradient*/{
      var value = map_range(distances[j], minimum_distance, maximum_distance, 0 ,360)
      //var rgb = colorsys.hsv_to_rgb( {h:value, s:1.0, v:0.5} )
      var rgb = new THREE.Color("hsl("+value+", 100%, 50%)")
      color[j*3+0]=rgb.r
      color[j*3+1]=rgb.g
      color[j*3+2]=rgb.b
    }

    surface.setAttribute('position', new THREE.BufferAttribute(vertices,3))
    surface.setAttribute('distance', new THREE.BufferAttribute(distances,1))
    surface.setAttribute('color', new THREE.BufferAttribute(color,3))
    const surfacePoints = new THREE.Points(surface, mat_default)
    surfacePoints.name = "SURFACE"
    surfacePoints.max_value = maximum_distance
    surfacePoints.min_value = minimum_distance
    scene.add(surfacePoints)
    console.log(surfacePoints)

    var endTime = Date.now()
    calculation_time = (endTime - beginTime)
    surface_UI_note = <div id="note" style={heel_style}> Minimum Distance Field Solved! Time Elapsed: {calculation_time}ms, total distance comparisons: {mask.geometry.attributes.position.count*resolution_x*resolution_y} </div>

    ReactDOM.render(surface_UI_note , document.getElementById('uistate'));
  }
  function generate_surface_v2 (){
    var beginTime = Date.now()
    var resolution_x = 200
    var resolution_y = 100
    var subgridsize = 10
    var spacing = 0.002 //pixel density

    var mid_x = resolution_x/2*spacing
    var mid_y = resolution_y/2*spacing
    var world_mid_x = (bboxresult.max[0]-bboxresult.min[0])/2
    var world_mid_y = (bboxresult.max[1]-bboxresult.min[1])/2

    var mask; for (var i = 0; i < scene.children.length; i++)/*get reference to pointcloud object*/{
      if(scene.children[i].name =="rmask"){
        mask = scene.children[i]
      }
    }
    var minimum_distance = 99999; var maximum_distance= 0 //useful for creating color gradient

    document.cellcalc = calc_cell(resolution_x,resolution_y, 10, resolution_x*resolution_y)

    const surface = new THREE.BufferGeometry();
    surface.name= "surface"
    surface.resolution_x = resolution_x
    surface.resolution_y = resolution_y
    var distances = new Float32Array(resolution_x*resolution_y)
    var vertices = new Float32Array(resolution_x*resolution_y*3)
    var color = new Float32Array(resolution_x*resolution_y*3)

    var keypoint = [] //this is where we will store a two dimensional array. [cellid][items]
    var maxdistance = 0.05

    for( var j = 0; j< resolution_x*resolution_y; j++)/*create array of vertex locations*/{
      var index_cell = calc_cell(resolution_x,resolution_y,subgridsize,j)

      //define location of vertices....
      var px = spacing*(j%resolution_x) +bboxresult.min[0]-.02
      var py = spacing*(j/resolution_x) +bboxresult.min[1]-.02
      var pz = bboxresult.min[2]-.001 //z is minimum
      vertices[j*3+0]= px
      vertices[j*3+1]= py
      vertices[j*3+2]= pz
      var p1 = new THREE.Vector3( px, py, pz )
    }
    for( var j = 0; j< resolution_x*resolution_y; j++)/*solve key points.*/{
      var pixel_x = parseInt(j%resolution_x)
      var pixel_y = parseInt(j/resolution_y)
      if( (pixel_x%subgridsize)==0 && (pixel_y%subgridsize)==0 ) /*if j is a keypoint, we do a full solve.*/ {
        var index_cell = calc_cell(resolution_x,resolution_y,subgridsize,j)
        var p1 = new THREE.Vector3( vertices[j*3+0], vertices[j*3+1], vertices[j*3+2] )
        var index_closestpoints = []
        var index_current_proximity = 0
        var distance = 9999999999// default is max distance

        //go through each point in Lidar
        for(var k=0;k<mask.geometry.attributes.position.count;k++)/*go through all positions*/{
          let p2 = new THREE.Vector3(
            mask.geometry.attributes.position.array[k*3+0],
            mask.geometry.attributes.position.array[k*3+1],
            mask.geometry.attributes.position.array[k*3+2]) /*p2 is lidar point x,y,z*/
          let d = p1.distanceTo(p2)
          if( d < distance){
            distance = d
            index_current_proximity = k
          }
          if(distance<maxdistance)/*get indicies of all points within distance*/{
            index_closestpoints.push(k)
          }
        }
        index_closestpoints.push(index_current_proximity)//will have at least one index
        keypoint.push(index_closestpoints)
        distances[j]=distance
        minimum_distance = Math.min(minimum_distance, distance)
        maximum_distance = Math.max(maximum_distance, distance)
      }
    }
    document.keypoint = keypoint
    for( var j = 0; j< resolution_x*resolution_y; j++)/*solve NON-key points.*/{
      var pixel_x = parseInt(j%resolution_x)
      var pixel_y = parseInt(j/resolution_y)
      if( (pixel_x%subgridsize)!=0 || (pixel_y%subgridsize)!=0 ) /*if j is NOT a keypoint, we do a local solve.*/ {
        var index_cell = calc_cell(resolution_x,resolution_y,subgridsize,j)
        var p1 = new THREE.Vector3( vertices[j*3+0], vertices[j*3+1], vertices[j*3+2] )
        var distance = 9999999999// default is max distance

        //go through each point in relevent keypoint index
        document.m = index_cell
        if(keypoint[index_cell]!=undefined){
          for(var m=0;m<keypoint[index_cell].length;m++)/*go through all indices for this keypoint*/{
            let p2 = new THREE.Vector3(
              mask.geometry.attributes.position.array[ keypoint[index_cell][m] *3+0  ],
              mask.geometry.attributes.position.array[ keypoint[index_cell][m] *3+1  ],
            mask.geometry.attributes.position.array[ keypoint[index_cell][m] *3+2  ]) /*p2 is lidar point x,y,z*/
            let d = p1.distanceTo(p2)
            if( d < distance){
                distance = d
            }
          }
          distances[j]=distance
          minimum_distance = Math.min(minimum_distance, distance)
          maximum_distance = Math.max(maximum_distance, distance)
          }
        }
    }

    for( var j = 0; j< resolution_x*resolution_y; j++)/*///////////////GRADIENT////////////////////////*/{
      let pixel_x = parseInt(j%resolution_x)
      let pixel_y = parseInt(j/resolution_x)
      var value = map_range(distances[j], minimum_distance, maximum_distance, 1 ,360).toFixed(0)

      var id_remap = map_range( calc_cell(resolution_x,resolution_y,subgridsize,j), 0, 100, 40 ,60).toFixed(0)
      //var rgb = colorsys.hsv_to_rgb( {h:value, s:1.0, v:0.5} )

      var rgb = new THREE.Color("hsl("+value+", 100%, "+ 50 +"%)")
      //if( pixel_x%subgridsize==0 && pixel_y%subgridsize==0 ){rgb = new THREE.Color("hsl("+value+", 100%, 60%)")}
      color[j*3+0]=rgb.r
      color[j*3+1]=rgb.g
      color[j*3+2]=rgb.b
    }

    surface.setAttribute('position', new THREE.BufferAttribute(vertices,3))
    surface.setAttribute('distance', new THREE.BufferAttribute(distances,1))
    surface.setAttribute('color', new THREE.BufferAttribute(color,3))
    const surfacePoints = new THREE.Points(surface, mat_default)
    surfacePoints.name = "SURFACE"
    surfacePoints.max_value = maximum_distance
    surfacePoints.min_value = minimum_distance
    scene.add(surfacePoints)
    console.log(surfacePoints)

    var endTime = Date.now()
    calculation_time = (endTime - beginTime)
    var totalkeypoints = 0
    for (var i = 0; i < keypoint.length; i++) {
      totalkeypoints += keypoint[i].length *subgridsize*subgridsize
    }
    var totalCalculations = (calc_cell(resolution_x,resolution_y,subgridsize,resolution_x*resolution_y)*mask.geometry.attributes.position.count) + totalkeypoints
    surface_UI_note = <div id="note" style={heel_style}> Minimum Distance Field Solved! Time Elapsed: {calculation_time}ms, total distance comparisons: {totalCalculations} </div>

    ReactDOM.render(surface_UI_note , document.getElementById('uistate'));
    create_image()
  }

  function test_color(){
    const color6 = new THREE.Color("hsl(240, 100%, 50%)");
    console.log(color6)
  }

// will store array of names here, when creating an image, we will use the name of input 3d file to generate output file name.
// in the future if the input ply file has visitid_date_etc.ply as a name, when we generate a new image, the image can have the visit name in the title.
// this will make it easier to patch it into the existing database.
  document.output = ["sample"]
  function create_image(){

    //var surface /*get reference to scanner surface*/
    //for (var i = 0; i < scene.children.length; i++){
      //if(scene.children[i].name =="SURFACE"){
    //    surface = scene.children[i]
    //  }
    //}
    //console.log(surface)

    var surface = {resolution_x:500,resolution_y:500}
    /*
    var maxdist = Math.max(...surface.geometry.attributes.distance.array)
    var mindist = Math.min(...surface.geometry.attributes.distance.array)
    */

    var canvas = document.createElement('canvas');
    var context = canvas.getContext('2d');
    var imgData = context.createImageData(surface.resolution_x, surface.resolution_y);
    console.log(imgData)
    canvas.height = surface.resolution_y;
    canvas.width = surface.resolution_x;

    for( var j = 0; j< surface.resolution_x*surface.resolution_y; j++)/*go through each point in surface, and use point data to create new pixel array.*/{
      var pixel_x = parseInt(j%surface.resolution_x)
      var pixel_y = parseInt(j/surface.resolution_y)
      //var dist = surface.geometry.attributes.distance.array[j]

      imgData.data[j*4 + 0] = 255;
      imgData.data[j*4 + 1] = 0;
      imgData.data[j*4 + 2] = 255;
      imgData.data[j*4 + 3] = 255; // Alpha channel
    }
    //document.body.appendChild(canvas)
    // put data to context at (0, 0)
    context.putImageData(imgData, 0, 0);
    context.fillStyle = "green"
    context.fillRect(0,0,100,100)
    // output image
    var img = new Image();
    img.src = canvas.toDataURL('image/png');
    console.log(img.src)

    // add image to body (or whatever you want to do)
    document.body.appendChild(img);


  }

  function loaderChange(event){

    var selectedObject = scene.getObjectByName("lidar");


    scene.remove( selectedObject );
    const files = event.target.files;
    let dots = files[0].name.split(".")
    let extension = dots[dots.length-1]
    console.log(extension)
    console.log(files)
    document.output.push(files[0].name)
    if(extension=="ply"){
      var loader2 = new PLYLoader()
      loader2.load( URL.createObjectURL(files[0]) , function ( geometry ) {
          geometry.computeVertexNormals();
          var material = new THREE.MeshStandardMaterial( { color: 0x0055ff, flatShading: true } );
          var mesh = new THREE.Points( geometry, mat_default );
          mesh.bRectSelectArray = new Array(mesh.geometry.attributes.position.count).fill(true);
          mesh.name = files[0].name
          //mesh.castShadow = true;
          //mesh.receiveShadow = true;
          scene.add( mesh );
          FileList_create(files[0].name,mesh)
        })
    }
    if(extension=="obj"){
      var loader2 = new OBJLoader()
      loader2.load(
        URL.createObjectURL(files[0]),
        function(object){//on load
          let pointcloudobjs = []
          object.traverse( function( child ) {
              console.log("child: ",child)
              if(child.geometry!=undefined && child.geometry.attributes.position!=undefined){
                console.log("ADDING COLOR",child.geometry.attributes.position.count )
                //go through points and create a new color attribute
                let color = new Float32Array(child.geometry.attributes.position.count*3)
                for (var i = 0; i < child.geometry.attributes.position.count; i++) {
                  var pos = new THREE.Vector3(
                    child.geometry.attributes.position.array[i*3],
                    child.geometry.attributes.position.array[i*3+1],
                    child.geometry.attributes.position.array[i*3+2]
                  )
                  color[i*3] = pos.normalize().x
                  color[i*3+1] = pos.normalize().y
                  color[i*3+2] = pos.normalize().z

                  // var rgb = new THREE.Color("hsl("+0+", 100%, 50%)")
                  // color[i*3] = rgb.r
                  // color[i*3+1] = rgb.g
                  // color[i*3+2] = rgb.b
                }
                child.geometry.setAttribute('color', new THREE.BufferAttribute(color,3))
                child.material = mat_default
                child.bRectSelectArray = new Array(child.geometry.attributes.position.count).fill(true);
                child.name = files[0].name
                pointcloudobjs.push(child)
                //child.geometry.attributes.color = color
              }

          } );
          pointcloudobjs.forEach((item, i) => {
            scene.add(item)
            FileList_create(files[0].name,item)
          });

          // scene.add(object);
          // console.log(object)
          // FileList_create(files[0].name,object)
        }
      )
    }

  }
  //fires form style file browser when button is pressed, and also attaches an event for when the file target changes
  function start_file_load(){
    var uploader = document.getElementById("file-uploader");
    uploader.click();
    uploader.onchange = loaderChange
  }


  function FileList_create(name, obj){
    console.log("creating object entry")
    //what is a file list item?
    //1. it displays the name of the loaded file
    //2. it displays the status of the loaded file (selected or not-selected)
    //3. it has a button to SELECT
    //4. it has a button to DESELECT
    //5. it has a button to DELETE

    //where will we display it?
    var output_parent = document.getElementById("UI_filelist")
    if(output_parent!=undefined){

      // create the box
      let a = document.createElement("div")
        a.innerHTML = name
        a.style = {ui_filelist_style}
        a.style.backgroundColor="hsla(200, 100%, 40%, 0.2)"
        a.style.borderColor = "hsla(200, 100%, 75%, .8)"
        a.style.borderStyle = "solid"
        a.style.padding = "2px"
        a.style.margin = "0.2em"
        console.log(a)

        //select button with function
        var button_select = document.createElement("button")

        button_select.innerHTML = "SELECT"
        button_select.style.marginLeft = "1em"
        a.appendChild(button_select)


        //deselect button with function
        var button_deselect = document.createElement("button")
        button_deselect.innerHTML = "DESELECT"
        button_deselect.style.marginLeft = "1em"
        a.appendChild(button_deselect)

        //clean span
        var clean_span = document.createElement("span")
        clean_span.style.padding="5px"
        clean_span.style.border="solid"
        var clean_start = document.createElement("button")
        clean_start.innerHTML = "CLEAN"
        var clean_add = document.createElement("button")
        clean_add.innerHTML = "+ADD"
        clean_add.style.display = "none"
        var clean_remove = document.createElement("button")
        clean_remove.innerHTML = "-REMOVE"
        clean_remove.style.display = "none"
        var clean_done = document.createElement("button")
        clean_done.innerHTML = "FINISH"
        clean_done.style.display = "none"
        obj.selected = true
        clean_span.threeobj = obj


        button_select.addEventListener("click", function(){
          FileList_select(a, clean_span)
        })
        button_deselect.addEventListener("click", function(){
          FileList_deselect(a,clean_span)
        })
        clean_start.addEventListener("click", function(){

          FileList_clean(a,clean_span)
        })
        clean_add.addEventListener("click", function(){

          FileList_clean_keep(a,clean_span)
        })
        clean_remove.addEventListener("click", function(){

          FileList_clean_discard(a,clean_span)
        })
        clean_done.addEventListener("click", function(){

          FileList_clean_finish(a,clean_span)
        })

        clean_span.appendChild(clean_start)
        clean_span.appendChild(clean_add)
        clean_span.appendChild(clean_remove)
        clean_span.appendChild(clean_done)
        a.appendChild(clean_span)
        console.log(clean_span)






        //remove button with functions
        var button_remove = document.createElement("button")
        button_remove.innerHTML = "X"
        button_remove.style.marginLeft = "1em"
        button_remove.addEventListener("click", function(){
          FileList_delete(a, clean_span)
        })
        a.appendChild(button_remove)




      output_parent.appendChild(a)
      FileList_select(a,clean_span)
      //scene.remove(obj)

    }


  }
  //I'm going to store three objects in the span. preview_add, preview_sub, and original obj in array ... span.processobj
  //not sure if this is a good pattern or not.
  function FileList_clean(name,span,obj){
    console.log("starting object clean")
    console.log(span)
    span.children[0].style.display = "none" //clean btn
    span.children[1].style.display = ""     //add btn
    span.children[2].style.display = ""     //remove btn
    span.children[3].style.display = ""     //done btn

  }
  function FileList_preview(){
    let obj = selectionRectangle.span.threeobj
    let span = selectionRectangle.span
    let name = selectionRectangle.name

    //probably not the nicest place to put these references
    let preview_add = span.preview_add
    let preview_sub = span.preview_sub
    //if they exist we delete them...
    if(preview_add!=undefined){
      scene.remove(preview_add)
    }
    if(preview_sub!=undefined){
      scene.remove(preview_sub)
    }

    let bRectSelectArray = obj.bRectSelectArray
    //testing...

    // for (var i = 0; i < 1000; i++) {
    //   bRectSelectArray[i]=false
    // }


    //In the original I will create a bool array that will
    //track the selection of each point
    let frustum = frustumFromRect(camera, selectionRectangle.start, selectionRectangle.end)
    let isfrusted = new Array(obj.geometry.attributes.position.count).fill(false);
    let frustcount = 0
    for (var i = 0; i < isfrusted.length; i++) {
      let vec3 = new THREE.Vector3(
        obj.geometry.attributes.position.array[i*3],
        obj.geometry.attributes.position.array[i*3+1],
        obj.geometry.attributes.position.array[i*3+2]
      )
      isfrusted[i] = frustum.containsPoint(vec3)
      if(isfrusted[i]){frustcount++}
    }
    console.log("frustcount: ",frustcount)
    let totalpts = 0



    //I'm going to do this the dumb way.
    //if it is true, I will create a new vector3 to store the value
    let add_pts = []
    let add_clr = []
    let sub_pts = []
    //add selections
    if(selectionRectangle.type==1){
      console.log("was additive selection")
      for (var i = 0; i < bRectSelectArray.length; i++) {
        if(isfrusted[i]==true){bRectSelectArray[i]=true}
        if(bRectSelectArray[i]==true){
          totalpts++
          add_pts.push(new THREE.Vector3(
            obj.geometry.attributes.position.array[i*3],
            obj.geometry.attributes.position.array[i*3+1],
            obj.geometry.attributes.position.array[i*3+2]
          ))
          add_clr.push(new THREE.Vector3(
            obj.geometry.attributes.color.array[i*3],
            obj.geometry.attributes.color.array[i*3+1],
            obj.geometry.attributes.color.array[i*3+2]
          ))
        }
        if(bRectSelectArray[i]==false){
          sub_pts.push(new THREE.Vector3(
            obj.geometry.attributes.position.array[i*3],
            obj.geometry.attributes.position.array[i*3+1],
            obj.geometry.attributes.position.array[i*3+2]
          ))
        }
      }
    }
    if(selectionRectangle.type==2){
      console.log("was subtractive selection")
      for (var i = 0; i < bRectSelectArray.length; i++) {
        if(isfrusted[i]==true){
          bRectSelectArray[i]=false
        }
        if(bRectSelectArray[i]==true){
          totalpts++
          add_pts.push(new THREE.Vector3(
            obj.geometry.attributes.position.array[i*3],
            obj.geometry.attributes.position.array[i*3+1],
            obj.geometry.attributes.position.array[i*3+2]
          ))
          add_clr.push(new THREE.Vector3(
            obj.geometry.attributes.color.array[i*3],
            obj.geometry.attributes.color.array[i*3+1],
            obj.geometry.attributes.color.array[i*3+2]
          ))
        }
        if(bRectSelectArray[i]==false){
          sub_pts.push(new THREE.Vector3(
            obj.geometry.attributes.position.array[i*3],
            obj.geometry.attributes.position.array[i*3+1],
            obj.geometry.attributes.position.array[i*3+2]
          ))
        }
      }
    }

    // for (var i = 0; i < bRectSelectArray.length; i++) {
    //   bRectSelectArray[i] = false
    // }

    //obj.bRectSelectArray = bRectSelectArray
    //console.log(obj.bRectSelectArray)
    //console.log(bRectSelectArray)

    //now we use   obj.bRectSelectArray to create two new objects....

    //1st, we create selected preview
    let preview_add_container = new THREE.BufferGeometry();
    let preview_sub_container = new THREE.BufferGeometry();
    var vertices = new Float32Array(totalpts*3)
    var color = new Float32Array(totalpts*3)
    var vertices2 = new Float32Array(sub_pts.length*3)

    add_pts.forEach((item, i) => {
      vertices[i*3]= item.x
      vertices[i*3+1]= item.y
      vertices[i*3+2]= item.z

      color[i*3]=add_clr[i].x
      color[i*3+1]=add_clr[i].y
      color[i*3+2]=add_clr[i].z
    });
    sub_pts.forEach((item, i) => {
      vertices2[i*3]= item.x
      vertices2[i*3+1]= item.y
      vertices2[i*3+2]= item.z
    })


    preview_add_container.setAttribute('position', new THREE.BufferAttribute(vertices,3))
    preview_add_container.setAttribute('color', new THREE.BufferAttribute(color,3))
    preview_add = new THREE.Points(preview_add_container, mat_default)
    preview_add.name = "preview_add"
    preview_add.bRectSelectArray = new Array(totalpts).fill(true);
    preview_sub_container.setAttribute('position', new THREE.BufferAttribute(vertices2,3))
    preview_sub = new THREE.Points(preview_sub_container, mat_red)
    preview_sub.name = "preview_sub"

    scene.add(preview_add)
    scene.add(preview_sub)

    // console.log("preview_add",preview_add)
    // console.log("preview_sub",preview_sub)
    // console.log("preview_add_points",preview_add.geometry.attributes.position.count)
    // console.log("preview_sub_points",preview_sub.geometry.attributes.position.count)

    span.preview_add =preview_add
    span.preview_sub =preview_sub
    obj.visible=false
  }
  function FileList_clean_keep(name,span){

    console.log("adding selection")
    selectionRectangle.termination = FileList_preview
    selectionRectangle.obj = span.threeobj
    selectionRectangle.span = span
    selectionRectangle.name = name
    startRectangle("add")
    //rectangle is not done yet
  }
  function FileList_clean_discard(name,span){
    console.log("removing selection")
    selectionRectangle.termination = FileList_preview
    selectionRectangle.obj = span.threeobj
    selectionRectangle.span = span
    selectionRectangle.name = name
    startRectangle("sub")
  }
  function FileList_clean_finish(name,span){
    console.log("clean complete")
    span.children[0].style.display = ""
    span.children[1].style.display = "none"
    span.children[2].style.display = "none"
    span.children[3].style.display = "none"

    //we replace the current object with the preview_add
    scene.remove(span.preview_sub)
    //scene.remove(span.preview_add)
    //let obj = span.preview_add
    span.preview_add.select = span.threeobj.select
    span.threeobj = span.preview_add

  }
  function FileList_select(parent,span){
    console.log("fl_select")
    parent.style.backgroundColor="hsla(60, 100%, 40%, 0.4)"
    parent.style.borderColor = "hsla(60, 100%, 50%, 1.0)"
    parent.style.color = "hsla(60, 100%, 50%, 1.0)"
    span.threeobj.select=true
  }
  function FileList_deselect(parent,span){
    console.log("fl_deselect")
    parent.style.backgroundColor="hsla(200, 100%, 40%, 0.4)"
    parent.style.borderColor = "hsla(200, 100%, 50%, 1.0)"
    parent.style.color = "hsla(200, 100%, 50%, 1.0)"
    span.threeobj.select=false
  }
  function FileList_delete(parent,span){

    parent.remove()
    scene.remove(span.threeobj)
  }


  //MATCH MECHANICS.
  var match_UI_note1 = <div id="heel" style={heel_style}>Click on template object you want to remain in place.</div>
  var match_UI_note3 = <div id="heel" style={heel_style}>Click on the object you want to match to first object.</div>
  var match_UI_note4 = <div id="heel" style={heel_style}>Place three markers in equivilent locations, in same order.</div>




  // Some setup for the object matching system.
  var ObjectSelectedSubset = [] //what objects are we interested in raycasting? The ones that are selected.
  // match object is the thing we are building,
  //it will have the template(to) that will carry the reference of the object we are moving to.
  //it will also have the mover(from) object reference of the object that will be moved to match the template(to)
  //a0,a1,a2, are the vectors used to build the basis transform of the template object (to)
  //b0,b1,b2 are the vectors used to build the basis transform of the moved object (from)
  var MatchObj = {
    to: undefined,
    from: undefined,
    basis1: {a:undefined,b:undefined,c:undefined},
    basis2: {a:undefined,b:undefined,c:undefined},
    basis1Obj: [],
    basis2Obj: []
  }
  function start_match(){

    ReactDOM.render(match_UI_note1, document.getElementById('uistate')); //give user instructions.
    //limit what the raycaster is doing by creating a subset of objects to test against.
    for (var i = 0; i < scene.children.length; i++) {
      if(scene.children[i].select==true){
        ObjectSelectedSubset.push(scene.children[i])
      }
    }
    //new events!
    window.addEventListener('touchstart', click_template_object);
    window.addEventListener('mousedown', click_template_object);
  }
  function click_template_object(){
    //do raycast
    const raycaster = new THREE.Raycaster();
    raycaster.params.Points.threshold = 0.01;
    raycaster.setFromCamera(screen_xy,camera)
    const intersects = raycaster.intersectObjects( ObjectSelectedSubset ); //only test against relevent objects
    var selectedObject = ""
    if(intersects[0]==undefined){
        selectedObject = "( NONE -plase try again )"
        //the first time we run this, MatchObj.to should be undefined
        if(MatchObj.to==undefined){
          var match_UI_note2 = <div id="heel" style={heel_style}>Sorry, the raycast failed and no template object was selected. Please try again.</div>
          ReactDOM.render(match_UI_note2 , document.getElementById('uistate'));
        }
        //the second time we run this, MatchObj.to should be already defined. In the second execution we notify the user that second object is not selected.
        else{
          var match_UI_note2 = <div id="heel" style={heel_style}>Sorry, the raycast failed and no second object was selected. Please try again.</div>
          ReactDOM.render(match_UI_note2 , document.getElementById('uistate'));
        }

    }else{
        if(MatchObj.to==undefined){
          selectedObject = intersects[0].object.name
          MatchObj.to = intersects[0].object
          var match_UI_button_template_markers = <button  onClick={match_btn_mark_template_points}> PLACE MARKERS </button>
          var match_UI_note2 = <div id="heel" style={heel_style}>Good, <b>{selectedObject}</b> is selected. Place three markers on this object. {match_UI_button_template_markers}</div>
          ReactDOM.render(match_UI_note2 , document.getElementById('uistate'));
        }
        else{
          selectedObject = intersects[0].object.name
          MatchObj.from = intersects[0].object
          var match_UI_button_template_markers = <button  onClick={match_btn_mark_template_points}> PLACE MARKERS </button>
          var match_UI_note2 = <div id="heel" style={heel_style}>Good, <b>{selectedObject}</b> is now active. Place three markers on this object. {match_UI_button_template_markers}</div>
          ReactDOM.render(match_UI_note2 , document.getElementById('uistate'));
        }




      //remove current event listeners.
      window.removeEventListener('touchstart', click_template_object);
      window.removeEventListener('mousedown', click_template_object);
    }
  }
  function match_btn_mark_template_points(){
    //add event listeners for next step
    if(MatchObj.from==undefined){
      window.addEventListener('touchstart', click_match_marker);
      window.addEventListener('mousedown', click_match_marker);
      var ui_marker_start_note = <div id="heel" style={heel_style}> Place three markers on template mesh.</div>
      ReactDOM.render(ui_marker_start_note , document.getElementById('uistate'));
    }
    else{
      window.addEventListener('touchstart', click_match_marker2);
      window.addEventListener('mousedown', click_match_marker2);
      var ui_marker_start_note = <div id="heel" style={heel_style}> Place three markers of second mesh.</div>
      ReactDOM.render(ui_marker_start_note , document.getElementById('uistate'));
    }

  }
  function match_btn_select_active_object(){
    window.addEventListener('touchstart', click_template_object);
    window.addEventListener('mousedown', click_template_object);
  }
  function click_match_marker() {

    //do raycast
    const raycaster = new THREE.Raycaster();
    raycaster.params.Points.threshold = 0.01;
    raycaster.setFromCamera(screen_xy,camera)
    const intersects = raycaster.intersectObjects( [MatchObj.to] ); //only test against one object (optimize)
    if(intersects[0]!=undefined){
      //snap the raycast location to the closest points
      let min_distance = 999999999999
      let closestPosition = new THREE.Vector3(-1,-1,-1)
      for (var i = 0; i < MatchObj.to.geometry.attributes.position.count; i++) {
        var geopoint = new THREE.Vector3(
          MatchObj.to.geometry.attributes.position.array[i*3  ],
          MatchObj.to.geometry.attributes.position.array[i*3+1],
          MatchObj.to.geometry.attributes.position.array[i*3+2]
        )
        var this_distance = intersects[0].point.distanceTo(geopoint)
        if(this_distance<min_distance){
          min_distance = this_distance
          closestPosition = geopoint
        }
      }

      if(MatchObj.basis1.a==undefined){
        MatchObj.basis1.a=closestPosition
        let marker = ui_create_childSphere(closestPosition,0xff0000,MatchObj.to)
        MatchObj.basis1Obj.push(marker)
        MatchObj.basis1.a = marker.position
      }
      else if(MatchObj.basis1.b==undefined){
        let marker = ui_create_childSphere(closestPosition,0x00ff00,MatchObj.to)
        MatchObj.basis1Obj.push(marker)
        MatchObj.basis1.b= marker.position
      }
      else if(MatchObj.basis1.c==undefined){
        let marker = ui_create_sphere(closestPosition,0x0000ff,MatchObj.to)
        MatchObj.basis1Obj.push(marker)
        MatchObj.basis1.c=marker.position
        //time to progress to next step
        //remove event
        window.removeEventListener('touchstart', click_match_marker);
        window.removeEventListener('mousedown', click_match_marker);
        //add button to select second object
        var match_UI_button_template_markers = <button  onClick={match_btn_select_active_object}> Select Next Object </button>
        var match_UI_note2 = <div id="heel" style={heel_style}>Three Points have been specified. Please {match_UI_button_template_markers}</div>
        ReactDOM.render(match_UI_note2 , document.getElementById('uistate'));

        console.log(MatchObj)
      }
    }
  }
  function click_match_marker2() {
    //do raycast
    const raycaster = new THREE.Raycaster();
    raycaster.params.Points.threshold = 0.01;
    raycaster.setFromCamera(screen_xy,camera)
    const intersects = raycaster.intersectObjects( [MatchObj.from] ); //only test against one object (optimize)
    if(intersects[0]!=undefined){
      //snap the raycast location to the closest points
      let min_distance = 999999999999
      let closestPosition = new THREE.Vector3(-1,-1,-1)
      for (var i = 0; i < MatchObj.from.geometry.attributes.position.count; i++) {
        var geopoint = new THREE.Vector3(
          MatchObj.from.geometry.attributes.position.array[i*3  ],
          MatchObj.from.geometry.attributes.position.array[i*3+1],
          MatchObj.from.geometry.attributes.position.array[i*3+2]
        )
        var this_distance = intersects[0].point.distanceTo(geopoint)
        if(this_distance<min_distance){
          min_distance = this_distance
          closestPosition = geopoint
        }
      }

      if(MatchObj.basis2.a==undefined){
        let marker = ui_create_childSphere(closestPosition,0xff0000, MatchObj.from)
        MatchObj.basis2.a=marker.position
        MatchObj.basis2Obj.push(marker)
      }
      else if(MatchObj.basis2.b==undefined){
        let marker = ui_create_childSphere(closestPosition,0x00ff00, MatchObj.from)
        MatchObj.basis2.b=marker.position
        MatchObj.basis2Obj.push(marker)

      }
      else if(MatchObj.basis2.c==undefined){
        let marker = ui_create_childSphere(closestPosition,0x0000ff, MatchObj.from)
        MatchObj.basis2.c=marker.position
        MatchObj.basis2Obj.push(marker)
        //time to progress to next step
        //remove event
        window.removeEventListener('touchstart', click_match_marker2);
        window.removeEventListener('mousedown', click_match_marker2);
        //add button to select second object
        var match_UI_note2 = <div id="heel" style={heel_style}><b>SELECTION STEP COMPLETE.</b> Two objects and 6 points have been specified.</div>
        ReactDOM.render(match_UI_note2 , document.getElementById('uistate'));
        tweak_match_marker()
        console.log(MatchObj)
      }
    }
  }
  function unclick__match_marker(){
    window.removeEventListener('touchstart', click_match_marker);
    window.removeEventListener('mousedown', click_match_marker);
    window.removeEventListener('touchend', unclick__match_marker);
    window.removeEventListener('mouseup', unclick__match_marker);
    ReactDOM.render(heel_UI_note_none, document.getElementById('uistate'));
  }
  var drag //where the drag controls will be stored!
  function tweak_match_marker(){
    drag = new DragControls(
      [MatchObj.basis1Obj[0],
      MatchObj.basis1Obj[1],
      MatchObj.basis1Obj[2],
      MatchObj.basis2Obj[0],
      MatchObj.basis2Obj[1],
      MatchObj.basis2Obj[2]],
      camera, renderer.domElement );
    var dragEvent = drag.addEventListener( 'dragend', function ( event ) {
      //move all points to closest point in pointcloud
      var closest = new THREE.Vector3()
      closest = findClosestPoint(MatchObj.to, MatchObj.basis1Obj[0].position)
      MatchObj.basis1Obj[0].position.x = closest.x
      MatchObj.basis1Obj[0].position.y = closest.y
      MatchObj.basis1Obj[0].position.z = closest.z

      closest = findClosestPoint(MatchObj.to, MatchObj.basis1Obj[1].position)
      MatchObj.basis1Obj[1].position.x = closest.x
      MatchObj.basis1Obj[1].position.y = closest.y
      MatchObj.basis1Obj[1].position.z = closest.z

      closest = findClosestPoint(MatchObj.to, MatchObj.basis1Obj[2].position)
      MatchObj.basis1Obj[2].position.x = closest.x
      MatchObj.basis1Obj[2].position.y = closest.y
      MatchObj.basis1Obj[2].position.z = closest.z
      /////////////////////
      closest = findClosestPoint(MatchObj.from, MatchObj.basis2Obj[0].position)
      MatchObj.basis2Obj[0].position.x = closest.x
      MatchObj.basis2Obj[0].position.y = closest.y
      MatchObj.basis2Obj[0].position.z = closest.z

      closest = findClosestPoint(MatchObj.from, MatchObj.basis2Obj[1].position)
      MatchObj.basis2Obj[1].position.x = closest.x
      MatchObj.basis2Obj[1].position.y = closest.y
      MatchObj.basis2Obj[1].position.z = closest.z

      closest = findClosestPoint(MatchObj.from, MatchObj.basis2Obj[2].position)
      MatchObj.basis2Obj[2].position.x = closest.x
      MatchObj.basis2Obj[2].position.y = closest.y
      MatchObj.basis2Obj[2].position.z = closest.z



    } );


     var btn_freezeOrbit =<button onClick={ui_freeze_orbit}>freeze camera movement</button>
     var btn_activateOrbit =<button onClick={ui_activate_orbit}>activate camera movement</button>
     var btn_done =  <div><button onClick={calculate_match}>done</button></div>

    var ui_marker_start_note = <div id="heel" style={heel_style}>Markers placed. Drag points to revise them?<span>{btn_freezeOrbit}{btn_activateOrbit}</span><hr/>{btn_done} </div>
    ReactDOM.render(ui_marker_start_note , document.getElementById('uistate'));
  }
  function calculate_match(){
    drag.deactivate() // drag control were crashing the app, so i disable it here.
    controls.enabled = true;
    var aframe_forward = new THREE.Vector3()
    var aframe_normal = new THREE.Vector3()
    var aframe_tangent = new THREE.Vector3()
    var aframe_tangent2 = new THREE.Vector3()

    var bframe_forward = new THREE.Vector3()
    var bframe_normal = new THREE.Vector3()
    var bframe_tangent = new THREE.Vector3()
    var bframe_tangent2 = new THREE.Vector3()

    var w_forward = new THREE.Vector3(1,0,0)
    var w_right =   new THREE.Vector3(0,1,0)
    var w_up =      new THREE.Vector3(0,0,1)
    var w_down =    new THREE.Vector3(0,0,-1)


    aframe_forward.subVectors(MatchObj.basis1.b.clone(),MatchObj.basis1.a.clone())
    aframe_tangent.subVectors(MatchObj.basis1.c.clone(),MatchObj.basis1.a.clone())
    aframe_normal.crossVectors(aframe_forward,aframe_tangent)
    aframe_tangent2.crossVectors(aframe_forward, aframe_normal)

    bframe_forward.subVectors(MatchObj.basis2.b.clone(),MatchObj.basis2.a.clone())
    bframe_tangent.subVectors(MatchObj.basis2.c.clone(),MatchObj.basis2.a.clone())
    bframe_normal.crossVectors(bframe_forward,bframe_tangent)
    bframe_tangent2.crossVectors(bframe_forward, bframe_normal)

    ui_create_arrow(aframe_forward.normalize(), MatchObj.basis1.a,0xFF0000,8000)
    ui_create_arrow(aframe_tangent.normalize(), MatchObj.basis1.a,0x00FF00,8000)
    ui_create_arrow(aframe_normal.normalize(), MatchObj.basis1.a,0x0000FF,8000)
    ui_create_arrow(aframe_tangent2.normalize(), MatchObj.basis1.a,0xFF00FF,8000)

    ui_create_arrow(bframe_forward.normalize(), MatchObj.basis2.a,0xFF0000,8000)
    ui_create_arrow(bframe_tangent.normalize(), MatchObj.basis2.a,0x00FF00,8000)
    ui_create_arrow(bframe_normal.normalize(), MatchObj.basis2.a,0x0000FF,8000)
    ui_create_arrow(bframe_tangent2.normalize(), MatchObj.basis2.a,0xFF00FF,8000)



    //(to matrix)
    var a_m4 = new THREE.Matrix4();
    a_m4.makeBasis( aframe_forward.normalize() ,aframe_tangent2.normalize(), aframe_normal.normalize() )
    a_m4.setPosition(MatchObj.basis1.a)

    //(from matrix) , we need to "undo" this rotation and position
    var b_m4 = new THREE.Matrix4();
    b_m4.makeBasis( bframe_forward.normalize() ,bframe_tangent2.normalize(), bframe_normal.normalize() )


    MatchObj.from.translateX(MatchObj.basis2.a.x*-1)
    MatchObj.from.translateY(MatchObj.basis2.a.y*-1)
    MatchObj.from.translateZ(MatchObj.basis2.a.z*-1)
    MatchObj.from.applyMatrix4( b_m4.invert() )
    MatchObj.from.applyMatrix4( a_m4 )
    MatchObj.from.updateMatrix();

    //IPM_V1(MatchObj)
    //IPM_V2(MatchObj)

    var ui_marker_start_note = <div id="heel" style={heel_style}><b>Match Complete!</b></div>
    ReactDOM.render(ui_marker_start_note , document.getElementById('uistate'));
    //reset the variable for the next run.
    MatchObj = {
      to: undefined,
      from: undefined,
      basis1: {a:undefined,b:undefined,c:undefined},
      basis2: {a:undefined,b:undefined,c:undefined},
      basis1Obj: [],
      basis2Obj: []
    }
    //remove spheres

    // var spherestoremove= []
    // for (var i = 0; i < scene.children.length; i++) {
    //   if(scene.children[i].name=="ui_sphere_marker"){
    //     spherestoremove.push(scene.children[i])
    //   }
    // }
    // for (var i = 0; i < spherestoremove.length; i++) {
    //   scene.remove(spherestoremove[i])
    // }
  }


//the next step is moving the object so that a cross section lies along the xz plane.
//how to do this? we mark the bridge of the nose, the tear duct, and the ear canal.
//then we go through each of the selected objects and invert a transform so they lie at the origin.
//lastly we create a new object that only contains the points on the plane +- tolerance.
//we  call this new object "cross-section"

var skull_iterator = 0;
var ui_skull_array = ["nose tip",
                      "nose bridge",
                      "left tear duct",
                      "left eye pupil",
                      "left mouth corner",
                      "left ear canal",
                      "right tear duct",
                      "right eye pupil",
                      "right mouth corner",
                      "right ear canal",
                      "back of head",
                      "top of head"]
var ui_skull_marker_note_0 = <div  style={heel_style}>specify <b>{ui_skull_array[0]}</b></div>
var skull
function start_skull(){
  //create new skull
  skull = new THREE.Object3D
  skull.name = "skull"
  scene.add(skull)
  //make all selected objects inherit transform of skull (1. build array, 2.go through array and assign parent)
  ObjectSelectedSubset = []
  for (var i = 0; i < scene.children.length; i++) {
    if(scene.children[i].select==true){
      ObjectSelectedSubset.push(scene.children[i])
    }
  }
  for (var i = 0; i < ObjectSelectedSubset.length; i++) {
    skull.attach(ObjectSelectedSubset[i])
  }
  //Give instructions, and listen for user input
  ReactDOM.render(ui_skull_marker_note_0, document.getElementById('uistate')); //give user instructions.
  window.addEventListener('touchstart', start_skull_marker);
  window.addEventListener('mousedown', start_skull_marker);
}
function start_skull_marker(){
  var ui_skull_marker_note = <div  style={heel_style}>specify <b>{ui_skull_array[skull_iterator+1]}</b></div>
  ReactDOM.render(ui_skull_marker_note, document.getElementById('uistate')); //give user instructions.

  //do raycast
  let raycaster = new THREE.Raycaster();
  raycaster.params.Points.threshold = 0.01;
  raycaster.setFromCamera(screen_xy,camera)

  let intersects = raycaster.intersectObjects( ObjectSelectedSubset );
  if(intersects[0]!=undefined){
    let position = new THREE.Vector3()
    position.x = intersects[0].point.x
    position.y = intersects[0].point.y
    position.z = intersects[0].point.z
    var newmarker = ui_create_childSphere(position, 0xffff00, skull)
    newmarker.name = newmarker.name + skull_iterator.toString()
    skull_iterator++
  }

  if(skull_iterator>11){
    skull_iterator=0
    let ui_skull_marker_note = <div  style={heel_style}> All markers placed.</div>
    ReactDOM.render(ui_skull_marker_note, document.getElementById('uistate')); //give user instructions.
    window.removeEventListener('touchstart', start_skull_marker);
    window.removeEventListener('mousedown', start_skull_marker);
    console.log(scene)
    start_skull_move()
  }
}
function start_skull_move(){

  //I'm going to do something simple and use the bridge of the nose and tear ducts to do my first orientation.
  let a = new THREE.Vector3()
  let b = new THREE.Vector3()
  let c = new THREE.Vector3()
  let frame_forward = new THREE.Vector3()
  let frame_tangent = new THREE.Vector3()
  let frame_normal = new THREE.Vector3()
  let frame_tangent2 = new THREE.Vector3()
  for (var i = 0; i < skull.children.length; i++) {
    //bridge marker
    if(skull.children[i].name=="ui_sphere_marker1"){
      a= skull.children[i].position
    }
    //left eye
    if(skull.children[i].name=="ui_sphere_marker2"){
      b= skull.children[i].position
    }
    //right eye
    if(skull.children[i].name=="ui_sphere_marker6"){
      c= skull.children[i].position
    }
  }


  frame_forward.subVectors(b,a) //bridge to left eye
  frame_tangent.subVectors(c,a) //bridge to right eye
  frame_normal.crossVectors(frame_forward,frame_tangent)

  //ALIGN CENTER OF FOOT TO THE MIDDLE OF SURFACE
  var middle_forward = new THREE.Vector3()
  middle_forward = b.clone().lerp(c.clone(),0.5)  //new point at midpoint between eyes.
  middle_forward.sub(a)   //now is a vector from the bridge
  frame_tangent2.crossVectors(middle_forward,frame_normal) //get orthogonal vector between


  //arrowHelper1 = new THREE.ArrowHelper( arrowDirection, markerpos, 0.9, 0xffff00, 0.25, 0.08 );
  var arrow_1 = new THREE.ArrowHelper( frame_forward.normalize(), a, 0.2, 0xff0000, 0.01, 0.01 );
  var arrow_2 = new THREE.ArrowHelper( frame_tangent.normalize(), a, 0.2, 0x00ff00, 0.01, 0.01 );
  var arrow_3 = new THREE.ArrowHelper( frame_normal.normalize(), a, 0.2, 0x1111FF, 0.01, 0.01 );
  var arrow_4 = new THREE.ArrowHelper( frame_tangent2.normalize(), a, 0.2, 0x00FFFF, 0.01, 0.01 );
  var arrow_5 = new THREE.ArrowHelper( middle_forward.normalize(), a, 0.2, 0xFFFFFF, 0.01, 0.01 );
  skull.add( arrow_1 );
  skull.add( arrow_2 );
  skull.add( arrow_3 );
  skull.add( arrow_4 );
  skull.add( arrow_5 );



  var v1 = middle_forward.clone().normalize();
  var v2 = frame_normal.clone().normalize();
  var v3 = frame_tangent2.clone().normalize();

  var localm4 = new THREE.Matrix4();
  //localm4.makeBasis( middle_forward.normalize() ,frame_tangent2.normalize(), frame_normal.normalize() )
  localm4.makeBasis( frame_tangent2.normalize() ,frame_normal.normalize(), middle_forward.normalize().negate() )

  skull.translateX(a.x*-1)
  skull.translateY(a.y*-1)
  skull.translateZ(a.z*-1)
  skull.applyMatrix4(localm4.invert());

  skull.updateWorldMatrix(true,true) //arg1:apply parent transform //arg2:apply child transform

  console.log("SKULL", skull)
  //goto next step
  start_skull_waterLine()
}
function start_skull_waterLine(){
  /////////////////////////////////////////////////////////////////////////////////////////
  //after orientation, I'm going to use the points to generate a waterline (new object....)

  //lets create an array of positions.
  let positions = []

  //go through all children objects.
  let tolerance = 0.002;
  for (var i = 0; i < skull.children.length; i++) {
    if(skull.children[i].name.split("_")[0]!="ui" && skull.children[i].name!=undefined){
      //temp1.children[3].children[0].geometry.attributes.position.array
      let geo = skull.children[i].geometry
      if( geo!=undefined){
        let xyzarray = geo.attributes.position
        for (var p = 0; p < geo.attributes.position.count; p++) {
          var pvector = new THREE.Vector3(
            xyzarray.array[p*3+0],
            xyzarray.array[p*3+1],
            xyzarray.array[p*3+2]
          )
          pvector.applyMatrix4(skull.children[i].matrixWorld)
          //pvector.applyMatrix4(skull.children[i].matrix)
          //positions.push(pvector)
          //check y component of position
          if( pvector.y > (tolerance*-1) && pvector.y < tolerance ){
          //if( geo.attributes.position.array[p*3+1] > 0.0 ){
            positions.push(pvector)
          }
        }
      }
    }
  }

  //create new buffer geometry
  let waterline = new THREE.BufferGeometry();
  var vertices = new Float32Array(positions.length*3)
  for (var j = 0; j < positions.length; j++) {
    vertices[j*3] = positions[j].x
    vertices[j*3+1] = positions[j].y
    vertices[j*3+2] = positions[j].z
  }

  //centroid(positions)
  waterline.setAttribute('position', new THREE.BufferAttribute(vertices,3))
  let contour = new THREE.Points(waterline, mat_green)
  contour.name = "waterline"
  contour.translateZ(0.5)
  scene.add(contour)


  //find center of waterline pointcloud
  let box = getbbox_from_positions(positions)
  console.log(box)
  let centerPoint = new THREE.Vector3()
  centerPoint= box.min.lerp(box.max,0.5)
  console.log(centerPoint)
  ui_create_sphere(centerPoint.clone().add(new THREE.Vector3(0,0,0.5)), 0x88FF00)


  //lets make a polyline from the waterline pointcloud
  waterline_polyline(positions, centerPoint)

}
//export file with appended points and markers.
function start_skull_export(){
}

//an attempt at iterative point matching
function IPM_V1(matchobj){
  //list of ingredients
    //two objects to match
    //2 trangles one per object (6 markers) (18vec3s)
  //process
    //first, we calculate the centroid for both of the triangles
    //second, we get all points within radius of the centroid and create two sets, SETA, SETB
    //third, for each of these local points, we calculate and store each points distance to the centroid
    // the disance will be used to weight the scoring mechanism

    let centroidA = centroid([
      matchobj.basis1Obj[0].getWorldPosition(new THREE.Vector3()),
      matchobj.basis1Obj[1].getWorldPosition(new THREE.Vector3()),
      matchobj.basis1Obj[2].getWorldPosition(new THREE.Vector3())]);  //won't know until later, but I may need to apply a 4x4 matrix to thse to get them in the right space.
    let centroidB = centroid(
      [matchobj.basis2Obj[0].getWorldPosition(new THREE.Vector3()),
      matchobj.basis2Obj[1].getWorldPosition(new THREE.Vector3()),
      matchobj.basis2Obj[2].getWorldPosition(new THREE.Vector3())]);
    centroidB = centroidB.clone()
    var cutoff = 0.05; //0.1 = 10 cm (note: must be greater than zero!!!)
    let setA = [];
    let setB = [];
    // let weightA = []
    // let weightB = []

    let debug_points = []
    //for points in obj1, if they are within radius cutoff of centroid, they are added to array
    for (var t = 0; t < matchobj.to.geometry.attributes.position.count; t++) {
      let iVector = new THREE.Vector3(
        matchobj.to.geometry.attributes.position.array[t*3],
        matchobj.to.geometry.attributes.position.array[t*3+1],
        matchobj.to.geometry.attributes.position.array[t*3+2]
      )
      iVector.applyMatrix4(matchobj.to.matrix)
      if(iVector.distanceTo(centroidA)<cutoff){
        iVector.weight =  (cutoff - iVector.distanceTo(centroidA)) / cutoff
        setA.push(iVector.clone())
        debug_points.push(iVector.clone())
        //weightA.push( (cutoff - iVector.distanceTo(centroidA)) / cutoff )
      }
    }
    for (var f = 0; f < matchobj.from.geometry.attributes.position.count; f++) {
      let iVector = new THREE.Vector3(
        matchobj.from.geometry.attributes.position.array[f*3  ],
        matchobj.from.geometry.attributes.position.array[f*3+1],
        matchobj.from.geometry.attributes.position.array[f*3+2]
      )
      iVector.applyMatrix4(matchobj.from.matrix)
      if(iVector.distanceTo(centroidA)<cutoff){
        iVector.weight =  (cutoff - iVector.distanceTo(centroidA)) / cutoff
        setB.push(iVector.clone())
        debug_points.push(iVector.clone())
        //weightB.push( (cutoff - iVector.distanceTo(centroidB)) / cutoff )
      }
    }
    //visual inspection to see if points are where they should be....
    ui_create_sphere(centroidA,0xFFFFFF);
    ui_create_sphere(centroidB,0xFF00FF);
    //pointcloudFromVec3array(debug_points,0xFFFFFF);
    //MSE!
    let baseMSE = MSE_V1(setA,setB);
    //let baseMSE = MSE_V1(setB,setA);
    let previousMSE = baseMSE

  let movement = 0.0002 //5mm
  let rotation = degrees_to_radians(0.02) //1 degree increments
  let max_steps =300
  let vec3offset = new THREE.Vector3( 0, 0, 0 );
  let vec3angle = new THREE.Vector3( 0, 0, 0); //radians!

  //My objective is to move it back to origin
  //get and store original location of object...
  let from_originalposition = matchobj.from.position
  //reverse original world offset
  matchobj.from.translateX(-from_originalposition.x)
  matchobj.from.translateY(-from_originalposition.y)
  matchobj.from.translateZ(-from_originalposition.z)

  let centroidB_world = centroidB.clone()
  //move the centroid because it was originally offset by object transform
  //the centroid is in world space. to accurately find its local space position, we need to offset it by the objects position first?
  //this code is probably more complex than it needs to be...
  centroidB_world.x = centroidB_world.x + from_originalposition.x
  centroidB_world.y = centroidB_world.y + from_originalposition.y
  centroidB_world.z = centroidB_world.z + from_originalposition.z

  centroidB_world = matchobj.from.worldToLocal(centroidB_world)

  ui_create_sphere_special(centroidB_world, 0xFF2222)//red sphere
  ui_create_sphere_special(matchobj.from.position, 0xFFFFFF)
  matchobj.from.translateX(-centroidB_world.x)
  matchobj.from.translateY(-centroidB_world.y)
  matchobj.from.translateZ(-centroidB_world.z)
  matchobj.from.updateMatrix();

  matchobj.from.geometry.applyMatrix4( matchobj.from.matrix );

  matchobj.from.position.set( 0, 0, 0 );
  matchobj.from.rotation.set( 0, 0, 0 );
  matchobj.from.scale.set( 1, 1, 1 );
  matchobj.from.updateMatrix();



  let rotationMatrix4 = new THREE.Matrix4()
  //rotationMatrix4.makeRotationAxis(new THREE.Vector3(1,0,0), degrees_to_radians(90))
  //matchobj.from.applyMatrix4(rotationMatrix4)
  let original = matchobj.from.clone()
  let reverse = matchobj.from.clone()
  // reverse.rotateX(degrees_to_radians(delta_angle_x1*-1))
  // matchobj.from.rotateX(degrees_to_radians(delta_angle_x1))  //the angle we found earlier...

  //move the object back!
  matchobj.from.position.x = centroidB.x
  matchobj.from.position.y = centroidB.y
  matchobj.from.position.z = centroidB.z
  original.position.x = centroidB.x
  original.position.y = centroidB.y
  original.position.z = centroidB.z
  reverse.position.x = centroidB.x
  reverse.position.y = centroidB.y
  reverse.position.z = centroidB.z


  for (var i = 0; i < max_steps; i++) {
    //create array with all moves. A move includes rotation, and translation amounts, with resulting MSE
    let moves = []
      //±x,±y,±z,±x°,±y°,±z° (12 total)
      moves.push({
        loc: vec3offset.clone().add(new THREE.Vector3(movement,0.0,0.0) ), //+x
        rot: vec3angle.clone(),
      })
      moves.push({
        loc: vec3offset.clone().add(new THREE.Vector3(-movement,0.0,0.0) ),//-x
        rot: vec3angle.clone(),
      })
      moves.push({
        loc: vec3offset.clone().add(new THREE.Vector3(0.0,movement,0.0) ),//+y
        rot: vec3angle.clone(),
      })
      moves.push({
        loc: vec3offset.clone().add(new THREE.Vector3(0.0,-movement,0.0) ),//-y
        rot: vec3angle.clone(),
      })
      moves.push({
        loc: vec3offset.clone().add(new THREE.Vector3(0.0,0.0,movement) ),//+z
        rot: vec3angle.clone(),
      })
      moves.push({
        loc: vec3offset.clone().add(new THREE.Vector3(0.0,0.0,-movement) ),//-z
        rot: vec3angle.clone(),
      })
      moves.push({
        loc: vec3offset.clone(),
        rot: vec3angle.clone().add(new THREE.Vector3( rotation,0.0,0.0) ),//+x°
      })
      moves.push({
        loc: vec3offset.clone(),
        rot: vec3angle.clone().add(new THREE.Vector3( -rotation,0.0,0.0) ),//-x°
      })
      moves.push({
        loc: vec3offset.clone(),
        rot: vec3angle.clone().add(new THREE.Vector3( 0.0, rotation,0.0) ),//+y°
      })
      moves.push({
        loc: vec3offset.clone(),
        rot: vec3angle.clone().add(new THREE.Vector3( 0.0, -rotation,0.0) ),//-y°
      })
      moves.push({
        loc: vec3offset.clone(),
        rot: vec3angle.clone().add(new THREE.Vector3( 0.0,0.0, rotation) ),//+z°
      })
      moves.push({
        loc: vec3offset.clone(),
        rot: vec3angle.clone().add(new THREE.Vector3( 0.0,0.0,-rotation) ),//-z°
      })
      moves.forEach((item) => {
        let solve = MSE_transformer(setA,setB,centroidB,item.loc,item.rot)
        //let solve = MSE_transformer(setB,setA,centroidB,item.loc,item.rot)
        item.mse = solve.mse
        item.points = solve.points
      });

      //sort the array by MSE
      moves.sort((a, b) => (a.mse >= b.mse) ? 1 : -1)
      //console.log(moves)
      //pointcloudFromVec3array(moves[0].points, new THREE.Color("hsl("+(i/max_steps)*360+", 100%, 50%)"))
      if(i%2==0){
      pointcloudFromVec3array(moves[0].points, new THREE.Color("hsl("+(i/(max_steps))*360+", 100%, 50%)"))
      }
      if(moves[0].mse<previousMSE){
        vec3offset = moves[0].loc
        vec3angle = moves[0].rot
        previousMSE = moves[0].mse
      }
  }
  //make original points smaller so it's easier to see steps
  matchobj.from.material = new THREE.PointsMaterial({size: 0.0001,vertexColors: THREE.VertexColors })
  matchobj.to.material = new THREE.PointsMaterial({size: 0.0001,vertexColors: THREE.VertexColors })
}
function IPM_V2(matchobj){

    //SHOW the initial position/rotation of the matchobj.from, before modifications.
    let startobj = matchobj.from.clone(true)
    startobj.geometry = matchobj.from.geometry.clone()
    startobj.updateMatrix()
    startobj.material = new THREE.PointsMaterial({size: 0.0001,color: 0x454545})
    scene.add(startobj)





    let zero3 = new THREE.Vector3(0,0,0)
    let centroidA = centroid([
      matchobj.basis1Obj[0].getWorldPosition(zero3),
      matchobj.basis1Obj[1].getWorldPosition(zero3),
      matchobj.basis1Obj[2].getWorldPosition(zero3)]);
    let centroidB = centroid([
      // startobj.getWorldPosition(matchobj.basis2Obj[0].position),
      // startobj.getWorldPosition(matchobj.basis2Obj[1].position),
      // startobj.getWorldPosition(matchobj.basis2Obj[2].position)
      matchobj.basis2Obj[0].getWorldPosition(zero3),
      matchobj.basis2Obj[1].getWorldPosition(zero3),
      matchobj.basis2Obj[2].getWorldPosition(zero3)
    ]);
    // let centroidB = centroid([
    //   matchobj.basis2Obj[0].getWorldPosition(new THREE.Vector3()),
    //   matchobj.basis2Obj[1].getWorldPosition(new THREE.Vector3()),
    //   matchobj.basis2Obj[2].getWorldPosition(new THREE.Vector3())]);
    centroidA = centroidA.clone()
    centroidB = centroidB.clone()
    var cutoff = 0.05; //0.1 = 10 cm (note: must be greater than zero!!!)
    let setA = [];
    let setB = [];

    //for points in obj1, if they are within radius cutoff of centroid, they are added to array
    for (var t = 0; t < matchobj.to.geometry.attributes.position.count; t++) {
      let iVector = new THREE.Vector3(
        matchobj.to.geometry.attributes.position.array[t*3],
        matchobj.to.geometry.attributes.position.array[t*3+1],
        matchobj.to.geometry.attributes.position.array[t*3+2]
      )
      iVector.applyMatrix4(matchobj.to.matrix)
      if(iVector.distanceTo(centroidA)<cutoff){
        iVector.weight =  (cutoff - iVector.distanceTo(centroidA)) / cutoff
        setA.push(iVector.clone())
        //weightA.push( (cutoff - iVector.distanceTo(centroidA)) / cutoff )
      }
    }
    for (var f = 0; f < matchobj.from.geometry.attributes.position.count; f++) {
      let iVector = new THREE.Vector3(
        matchobj.from.geometry.attributes.position.array[f*3  ],
        matchobj.from.geometry.attributes.position.array[f*3+1],
        matchobj.from.geometry.attributes.position.array[f*3+2]
      )
      iVector.applyMatrix4(matchobj.from.matrix)
      if(iVector.distanceTo(centroidA)<cutoff){
        iVector.weight =  (cutoff - iVector.distanceTo(centroidA)) / cutoff
        setB.push(iVector.clone())
        //weightB.push( (cutoff - iVector.distanceTo(centroidB)) / cutoff )
      }
    }

    matchobj.from.material = new THREE.PointsMaterial({size: 0.0001,vertexColors: THREE.VertexColors })
    matchobj.to.material = new THREE.PointsMaterial({size: 0.0001,vertexColors: THREE.VertexColors })



    //now we move matchobj.from around ▩ ▩ ▩ ▩ ▩ ▩ ▩ ▩ ▩ ▩ ▩ ▩ ▩ ▩ ▩

    let old_position = matchobj.from.position.clone()
    let centroidB_local = matchobj.from.worldToLocal(centroidB).clone()
    matchobj.from.position.x = 0
    matchobj.from.position.y = 0
    matchobj.from.position.z = 0
    matchobj.from.translateX(-centroidB_local.x)
    matchobj.from.translateY(-centroidB_local.y)
    matchobj.from.translateZ(-centroidB_local.z)

    //apply transform
    matchobj.from.updateMatrix();
    matchobj.from.geometry.applyMatrix4( matchobj.from.matrix );
    matchobj.from.position.set( 0, 0, 0 );
    matchobj.from.rotation.set( 0, 0, 0 );
    matchobj.from.scale.set( 1, 1, 1 );
    matchobj.from.updateMatrix();



    let axisfrom = new THREE.AxesHelper(0.05)
     axisfrom.position.x = matchobj.from.position.x
     axisfrom.position.y = matchobj.from.position.y
     axisfrom.position.z = matchobj.from.position.z
     axisfrom.rotation.x = matchobj.from.rotation.x
     axisfrom.rotation.y = matchobj.from.rotation.y
     axisfrom.rotation.z = matchobj.from.rotation.z
     scene.add(axisfrom)



    //add rotation
    //matchobj.from.rotateX(Math.PI/2)

    //move back
    matchobj.from.position.x = centroidB.x
    matchobj.from.position.y = centroidB.y
    matchobj.from.position.z = centroidB.z
    // matchobj.from.translateX(centroidB.x)
    // matchobj.from.translateY(centroidB.y)
    // matchobj.from.translateZ(centroidB.z)

     axisfrom = new THREE.AxesHelper(0.05)
      axisfrom.position.x = matchobj.from.position.x
      axisfrom.position.y = matchobj.from.position.y
      axisfrom.position.z = matchobj.from.position.z
      axisfrom.rotation.x = matchobj.from.rotation.x
      axisfrom.rotation.y = matchobj.from.rotation.y
      axisfrom.rotation.z = matchobj.from.rotation.z
      scene.add(axisfrom)


      axisfrom = new THREE.AxesHelper(0.05)
       axisfrom.position.x =  centroidB.x
       axisfrom.position.y = centroidB.y
       axisfrom.rotation.y = Math.PI/4
       axisfrom.position.z =  centroidB.z
       scene.add(axisfrom)

       axisfrom = new THREE.AxesHelper(0.05)
        axisfrom.position.x =  startobj.x
        axisfrom.position.y = startobj.y
        axisfrom.rotation.y = Math.PI/4
        axisfrom.position.z =  startobj.z
        scene.add(axisfrom)










  console.log("IPMV2---",scene)
}
function find_rotation_X(v3a,v3b,pivot){
  //isolate a curve from setA and setB  (all points close to x=0)
  //isolate points on these curves that lie within ± range distance
  //average these local pointgroups into 4 locations.
  //compare 4 averaged locations to centroid, and find projected yz angle.
  //once solved I should have 4 angles, (a)seta1-vs-centroid, (b)setb1-vs-centroid, (c)seta2-vs-centroid, (d)setb2-vs-centroid,
  //from this I should get 2 angles,  delta1°=a-b, delta2°=c-d
  //these two angles should be the same or similar, (if they are the same that is a good sign)
  //return the rotation*-1, so the transformer can remove the difference.
  let v3a_curve = []
  let v3b_curve = []
  let tolerance = 0.002
  let distance =  0.030

  v3a.forEach((item) => {
    let condition1 = (item.x-pivot.x)<tolerance
    let condition2 = (item.x-pivot.x)>-tolerance
    if(condition1&&condition2){
      v3a_curve.push(item)
    }
  });
  v3b.forEach((item) => {
    let condition1 = (item.x-pivot.x)<tolerance
    let condition2 = (item.x-pivot.x)>-tolerance
    if(condition1&&condition2){
      v3b_curve.push(item)
    }
  });

  //debug, show both sets!
  pointcloudFromVec3array_v(v3a_curve,0xFF0000, new THREE.Vector3(0,0,0.05))
  pointcloudFromVec3array_v(v3b_curve,0x00FF00, new THREE.Vector3(0,0,0.05))

  //check for points that are within distance of centroid
  let v3a_side1 = []
  let v3a_side2 = []
  let v3b_side1 = []
  let v3b_side2 = []

  tolerance *= 2 // we double the tolerance for the radius select.
  v3a_curve.forEach((item) => {
    let ptdistance = item.distanceTo(pivot)
    let condition1 = ptdistance<(distance+tolerance)
    let condition2 = ptdistance>(distance-tolerance)
    let condition3 = item.y>pivot.y
    if(condition1&&condition2){
      if(condition3){
        v3a_side1.push(item)
      }else{
        v3a_side2.push(item)
      }
    }
  });
  v3b_curve.forEach((item) => {
    let ptdistance = item.distanceTo(pivot)
    let condition1 = ptdistance<(distance+tolerance)
    let condition2 = ptdistance>(distance-tolerance)
    let condition3 = item.y>pivot.y
    if(condition1&&condition2){
      if(condition3){
        v3b_side1.push(item)
      }else{
        v3b_side2.push(item)
      }
    }
  });

  pointcloudFromVec3array_v(v3a_side1,0xFF0000, new THREE.Vector3(0.001,0,0.06))
  pointcloudFromVec3array_v(v3a_side2,0xFF9900, new THREE.Vector3(0.002,0,0.06))
  pointcloudFromVec3array_v(v3b_side1,0x0000FF, new THREE.Vector3(0.003,0,0.06))
  pointcloudFromVec3array_v(v3b_side2,0x0099FF, new THREE.Vector3(0.004,0,0.06))

  let ca1 = centroid(v3a_side1)
  let ca2 = centroid(v3a_side2)
  let cb1 = centroid(v3b_side1)
  let cb2 = centroid(v3b_side2)
  ui_create_sphere(ca1, 0xAA2200)
  ui_create_sphere(ca2, 0xAA2200)
  ui_create_sphere(cb1, 0x00FF00)
  ui_create_sphere(cb2, 0x00FF00)


  //calculate angles

  scene.add( new THREE.ArrowHelper( ca1.sub(pivot).multiply(new THREE.Vector3(0,1,1)).normalize(), pivot, 0.2, 0xFF0000 ) );
  scene.add( new THREE.ArrowHelper( ca2.sub(pivot).multiply(new THREE.Vector3(0,1,1)).normalize(), pivot, 0.2, 0xFF0000 ) );
  scene.add( new THREE.ArrowHelper( cb1.sub(pivot).multiply(new THREE.Vector3(0,1,1)).normalize(), pivot, 0.2, 0xFF8800 ) );
  scene.add( new THREE.ArrowHelper( cb2.sub(pivot).multiply(new THREE.Vector3(0,1,1)).normalize(), pivot, 0.2, 0xFF8800 ) );



  let angle1a = Math.atan2(ca1.z-pivot.z ,ca1.y-pivot.y)
  let angle1b = Math.atan2(ca2.z-pivot.z ,ca2.y-pivot.y)
  let angle2a = Math.atan2(cb1.z-pivot.z ,cb1.y-pivot.y)
  let angle2b = Math.atan2(cb2.z-pivot.z ,cb2.y-pivot.y)
  let shortest1 = findShortestAngle_RADIANS(angle1a,angle1b)
  let shortest2 = findShortestAngle_RADIANS(angle2a,angle2b)
  let average = (shortest1+shortest2)/2


  console.log("angle1a:",angle1a)
  console.log("angle2a:",angle2a)
  console.log("angle1b:",angle1b)
  console.log("angle2b:",angle2b)

  console.log("angle1a°:",radToDeg(angle1a))
  console.log("angle2a°:",radToDeg(angle2a))
  console.log("angle1b°:",radToDeg(angle1b))
  console.log("angle2b°:",radToDeg(angle2b))

  console.log("angle1-delta:",findShortestAngle_RADIANS(angle1a,angle1b))
  console.log("angle2-delta:",findShortestAngle_RADIANS(angle2a,angle2b))
  console.log("angle1-delta°:",radToDeg(findShortestAngle_RADIANS(angle1a,angle1b)))
  console.log("angle2-delta°:",radToDeg(findShortestAngle_RADIANS(angle2a,angle2b)))

  console.log("angle_average:",radToDeg(average))
  return(average)
}

var MatchObj_test = {
  to: undefined,
  from: undefined,
  basis1: {a:undefined,b:undefined,c:undefined},
  basis2: {a:undefined,b:undefined,c:undefined},
  basis1Obj: [],
  basis2Obj: []
}
const loader = new PLYLoader()
loader.load(
    'head_front.ply',
    function (geometry) {
        geometry.computeVertexNormals()
        const mesh = new THREE.Points(geometry, mat_default)
        mesh.name = "head_front.ply"
        mesh.geometry.attributes.color2 =  mesh.geometry.attributes.color
        mesh.visible=false
        /*
        for (var i = 0; i < mesh.geometry.attributes.color.array.length/3; i++) {
          mesh.geometry.attributes.color.array[i*3]=Math.random()
          mesh.geometry.attributes.color.array[i*3+1]=Math.random()
          mesh.geometry.attributes.color.array[i*3+2]=Math.random()
        }*/ //RANDOM COLORS


        //geometry.colorsNeedUpdate = true
        //console.log(mesh.geometry.attributes)
        //mesh.rotateX(-Math.PI / 2)
        scene.add(mesh)


    },
    (xhr) => {

    },
    (error) => {
        console.log(error)
    }
)
loader.load(
    'head_jaw.ply',
    function (geometry) {
        geometry.computeVertexNormals()
        const mesh = new THREE.Points(geometry, mat_default)
        mesh.name = "head_jaw.ply"
        mesh.geometry.attributes.color2 =  mesh.geometry.attributes.color
        mesh.visible=false
        /*
        for (var i = 0; i < mesh.geometry.attributes.color.array.length/3; i++) {
          mesh.geometry.attributes.color.array[i*3]=Math.random()
          mesh.geometry.attributes.color.array[i*3+1]=Math.random()
          mesh.geometry.attributes.color.array[i*3+2]=Math.random()
        }*/ //RANDOM COLORS


        //geometry.colorsNeedUpdate = true
        //console.log(mesh.geometry.attributes)
        //mesh.rotateX(-Math.PI / 2)
        scene.add(mesh)


    },
    (xhr) => {
    },
    (error) => {
        console.log(error)
    }
)
//quick test for matching heads...
function debug_ipm2(){
  //build debug object...
  for (var i = 0; i < scene.children.length; i++) {
    if(scene.children[i].name =="head_front.ply"){
      MatchObj_test.to = scene.children[i]
    }
  }
  for (var i = 0; i < scene.children.length; i++) {
    if(scene.children[i].name =="head_jaw.ply"){
      MatchObj_test.from = scene.children[i]
    }
  }

  MatchObj_test.basis1.a= new THREE.Vector3(-0.42762649059295654,1.022815465927124,0.5064135193824768)
  let marker = ui_create_childSphere(  MatchObj_test.basis1.a,0xff0000,MatchObj_test.to)
  MatchObj_test.basis1Obj.push(marker)
  MatchObj_test.basis1.b= new THREE.Vector3(-0.36344191431999207,1.048547387123108,0.506193220615387)
   marker = ui_create_childSphere(  MatchObj_test.basis1.b,0xff0000,MatchObj_test.to)
  MatchObj_test.basis1Obj.push(marker)
  MatchObj_test.basis1.c= new THREE.Vector3(-0.3779715895652771,0.9917995929718018,0.5084936618804932)
   marker = ui_create_childSphere(  MatchObj_test.basis1.c,0xff0000,MatchObj_test.to)
  MatchObj_test.basis1Obj.push(marker)

  MatchObj_test.basis2.a= new THREE.Vector3(0.08017580211162567,1.1967631578445435,0.5788690447807312)
   marker = ui_create_childSphere(  MatchObj_test.basis2.a,0xff4400,MatchObj_test.from)
  MatchObj_test.basis2Obj.push(marker)
  MatchObj_test.basis2.b= new THREE.Vector3(0.14922192692756653,1.1909799575805664,0.5624843835830688)
   marker = ui_create_childSphere(  MatchObj_test.basis2.b,0xff4400,MatchObj_test.from)
  MatchObj_test.basis2Obj.push(marker)
  MatchObj_test.basis2.c= new THREE.Vector3(0.11221146583557129,1.1473891735076904,0.5810386538505554)
   marker = ui_create_childSphere(  MatchObj_test.basis2.c,0xff4400,MatchObj_test.from)
  MatchObj_test.basis2Obj.push(marker)


  // I SHOULD MAKE THIS A FUNCTION (next 200 lines)


  var aframe_forward = new THREE.Vector3()
  var aframe_normal = new THREE.Vector3()
  var aframe_tangent = new THREE.Vector3()
  var aframe_tangent2 = new THREE.Vector3()

  var bframe_forward = new THREE.Vector3()
  var bframe_normal = new THREE.Vector3()
  var bframe_tangent = new THREE.Vector3()
  var bframe_tangent2 = new THREE.Vector3()

  var w_forward = new THREE.Vector3(1,0,0)
  var w_right =   new THREE.Vector3(0,1,0)
  var w_up =      new THREE.Vector3(0,0,1)
  var w_down =    new THREE.Vector3(0,0,-1)


  aframe_forward.subVectors(MatchObj_test.basis1.b.clone(),MatchObj_test.basis1.a.clone())
  aframe_tangent.subVectors(MatchObj_test.basis1.c.clone(),MatchObj_test.basis1.a.clone())
  aframe_normal.crossVectors(aframe_forward,aframe_tangent)
  aframe_tangent2.crossVectors(aframe_forward, aframe_normal)

  bframe_forward.subVectors(MatchObj_test.basis2.b.clone(),MatchObj_test.basis2.a.clone())
  bframe_tangent.subVectors(MatchObj_test.basis2.c.clone(),MatchObj_test.basis2.a.clone())
  bframe_normal.crossVectors(bframe_forward,bframe_tangent)
  bframe_tangent2.crossVectors(bframe_forward, bframe_normal)

  ui_create_arrow(aframe_forward.normalize(), MatchObj_test.basis1.a,0xFF0000,8000)
  ui_create_arrow(aframe_tangent.normalize(), MatchObj_test.basis1.a,0x00FF00,8000)
  ui_create_arrow(aframe_normal.normalize(), MatchObj_test.basis1.a,0x0000FF,8000)
  ui_create_arrow(aframe_tangent2.normalize(), MatchObj_test.basis1.a,0xFF00FF,8000)

  ui_create_arrow(bframe_forward.normalize(), MatchObj_test.basis2.a,0xFF0000,8000)
  ui_create_arrow(bframe_tangent.normalize(), MatchObj_test.basis2.a,0x00FF00,8000)
  ui_create_arrow(bframe_normal.normalize(), MatchObj_test.basis2.a,0x0000FF,8000)
  ui_create_arrow(bframe_tangent2.normalize(), MatchObj_test.basis2.a,0xFF00FF,8000)



  //(to matrix)
  var a_m4 = new THREE.Matrix4();
  a_m4.makeBasis( aframe_forward.normalize() ,aframe_tangent2.normalize(), aframe_normal.normalize() )
  a_m4.setPosition(MatchObj_test.basis1.a)

  //(from matrix) , we need to "undo" this rotation and position
  var b_m4 = new THREE.Matrix4();
  b_m4.makeBasis( bframe_forward.normalize() ,bframe_tangent2.normalize(), bframe_normal.normalize() )


  MatchObj_test.from.translateX(MatchObj_test.basis2.a.x*-1)
  MatchObj_test.from.translateY(MatchObj_test.basis2.a.y*-1)
  MatchObj_test.from.translateZ(MatchObj_test.basis2.a.z*-1)
  MatchObj_test.from.applyMatrix4( b_m4.invert() )
  MatchObj_test.from.applyMatrix4( a_m4 )
  MatchObj_test.from.updateMatrix();


  MatchObj_test.from.visible=true
  MatchObj_test.to.visible=true
  console.log("preIPM_mo_t",MatchObj_test)
  IPM_V1(MatchObj_test)
  //IPM_V2(MatchObj_test)
}



//this function attempts to determine the exact angle needed to align
function find_rotation(v3a,v3b,pivot,axis){
  //a modified version of find_rotation, plan is to use .getComponent(0/1/2)
  //to swizzle/extend this functionality to other dimensions.
  console.log(pivot.getComponent(0))
  console.log(pivot.getComponent(1))
  console.log(pivot.getComponent(2))

  let axis1 = parseInt(0);
  let axis2 = parseInt(0);
  //axis = 0 (find x axis rotation, analysis is on YZ)
  if(axis==0){
    axis1 = parseInt(1)
    axis2 =  parseInt(2)
  }
  //axis = 1 (find y axis rotation, analysis is on XZ)
  if(axis==1){
    axis1 =  parseInt(0)
    axis2 =  parseInt(2)
  }
  //axis = 2 (find z axis rotation, analysis is on XY)
  if(axis==2){
    axis1 =  parseInt(0)
    axis2 =  parseInt(1)
  }


  let v3a_curve = []
  let v3b_curve = []
  let tolerance = 0.002
  let distance =  0.030

if(axis==0||axis==1){
  v3a.forEach((item) => {

    let itemValue = item.getComponent(axis)
    let pivotValue = pivot.getComponent(axis)
    let condition1 = (  itemValue - pivotValue  ) <   tolerance
    let condition2 = (  itemValue - pivotValue  ) >-  tolerance
    if(condition1&&condition2){
      v3a_curve.push(item)
    }
  });
  v3b.forEach((item) => {
    let itemValue = item.getComponent(axis)
    let pivotValue = pivot.getComponent(axis)
    let condition1 = (  itemValue - pivotValue  ) <   tolerance
    let condition2 = (  itemValue - pivotValue  ) >-  tolerance
    if(condition1&&condition2){
      v3b_curve.push(item)
    }
  });
}

  //debug, show both sets!
  pointcloudFromVec3array_v(v3a_curve,0xFF0000, new THREE.Vector3(0,0,(.05*(axis+1))))
  pointcloudFromVec3array_v(v3b_curve,0x00FF00, new THREE.Vector3(0,0,(.05*(axis+1))))

  //check for points that are within distance of centroid
  let v3a_side1 = []
  let v3a_side2 = []
  let v3b_side1 = []
  let v3b_side2 = []

  if(axis==0||axis==1){
    tolerance *= 2 // we double the tolerance for the radius select.
    v3a_curve.forEach((item) => {
      let ptdistance = item.distanceTo(pivot)
      let condition1 = ptdistance<(distance+tolerance)
      let condition2 = ptdistance>(distance-tolerance)
      let condition3 = item.getComponent(axis1)>pivot.getComponent(axis1)
      if(condition1&&condition2){
        if(condition3){
          v3a_side1.push(item)
        }else{
          v3a_side2.push(item)
        }
      }
    });
    v3b_curve.forEach((item) => {
      let ptdistance = item.distanceTo(pivot)
      let condition1 = ptdistance<(distance+tolerance)
      let condition2 = ptdistance>(distance-tolerance)
      let condition3 = item.getComponent(axis1)>pivot.getComponent(axis1)
      if(condition1&&condition2){
        if(condition3){
          v3b_side1.push(item)
        }else{
          v3b_side2.push(item)
        }
      }
    });
  }
  if(axis==2){
    let separation = .01
    //if we are solving for z rotation
    //essentially issolate to slices along y+1 and y-1
    //then isolate points within radius of centroid
    v3a.forEach((item) => {
      let itemValue = item.y
      let pivotValue = pivot.y

      //contour on y (upper)
      let condition1 = (  itemValue - pivotValue  ) <  ( tolerance +separation)
      let condition2 = (  itemValue - pivotValue  ) > ( -tolerance +separation)
      let condition3 = (  itemValue - pivotValue  ) <  ( tolerance -separation)
      let condition4 = (  itemValue - pivotValue  ) > ( -tolerance -separation)
      if(condition1&&condition2){
        v3a_side1.push(item)
      }
      if(condition3&&condition4){
        v3a_side2.push(item)
      }
    });
    v3b.forEach((item) => {
      let itemValue = item.y
      let pivotValue = pivot.y

      //contour on y (upper)
      let condition1 = (  itemValue - pivotValue  ) <  ( tolerance +separation)
      let condition2 = (  itemValue - pivotValue  ) > ( -tolerance +separation)
      let condition3 = (  itemValue - pivotValue  ) <  ( tolerance -separation)
      let condition4 = (  itemValue - pivotValue  ) > ( -tolerance -separation)
      if(condition1&&condition2){
        v3b_side1.push(item)
      }
      if(condition3&&condition4){
        v3b_side2.push(item)
      }
    });

  }





  pointcloudFromVec3array_v(v3a_side1,0xFF0000, new THREE.Vector3(0,0,0.01+(.05*(axis+1))))
  pointcloudFromVec3array_v(v3a_side2,0xFF9900, new THREE.Vector3(0,0,0.01+(.05*(axis+1))))
  pointcloudFromVec3array_v(v3b_side1,0x0000FF, new THREE.Vector3(0,0,0.01+(.05*(axis+1))))
  pointcloudFromVec3array_v(v3b_side2,0x0099FF, new THREE.Vector3(0,0,0.01+(.05*(axis+1))))

  let ca1 = centroid(v3a_side1)
  let ca2 = centroid(v3a_side2)
  let cb1 = centroid(v3b_side1)
  let cb2 = centroid(v3b_side2)
  ui_create_sphere(ca1.clone().add(new THREE.Vector3(0,0,.06+(.05*axis))), 0xAA2200)
  ui_create_sphere(ca2.clone().add(new THREE.Vector3(0,0,.06+(.05*axis))), 0xAA2200)
  ui_create_sphere(cb1.clone().add(new THREE.Vector3(0,0,.06+(.05*axis))), 0x00FF00)
  ui_create_sphere(cb2.clone().add(new THREE.Vector3(0,0,.06+(.05*axis))), 0x00FF00)


  //calculate angles

  //draw arrows
  /*
  scene.add(
    new THREE.ArrowHelper( ca1.sub(pivot).normalize(),
    pivot.clone().add(new THREE.Vector3(0,0,(.05*(axis+1)))),
    0.1,
    0xFF0000 ));
  scene.add(
    new THREE.ArrowHelper(
      ca2.sub(pivot).normalize(),
      pivot.clone().add(new THREE.Vector3(0,0,(.05*(axis+1)))),
      0.1,
      0xFF0000 ));
  scene.add(
      new THREE.ArrowHelper(
       cb1.sub(pivot).normalize(),
       pivot.clone().add(new THREE.Vector3(0,0,(.05*(axis+1)))),
       0.1,
       0xFF8800 ));
  scene.add(
      new THREE.ArrowHelper(
      cb2.sub(pivot).normalize(),
      pivot.clone().add(new THREE.Vector3(0,0,(.05*(axis+1)))),
      0.1,
      0xFF8800 ));
  */


  let angle1a = Math.atan2(ca1.getComponent(axis2)-pivot.getComponent(axis2) ,ca1.getComponent(axis1)-pivot.getComponent(axis1))
  let angle1b = Math.atan2(ca2.getComponent(axis2)-pivot.getComponent(axis2) ,ca2.getComponent(axis1)-pivot.getComponent(axis1))
  let angle2a = Math.atan2(cb1.getComponent(axis2)-pivot.getComponent(axis2) ,cb1.getComponent(axis1)-pivot.getComponent(axis1))
  let angle2b = Math.atan2(cb2.getComponent(axis2)-pivot.getComponent(axis2) ,cb2.getComponent(axis1)-pivot.getComponent(axis1))
  let shortest1 = findShortestAngle_RADIANS(angle1a,angle1b)
  let shortest2 = findShortestAngle_RADIANS(angle2a,angle2b)
  let average = (shortest1+shortest2)/2

  console.log("mode:",axis)
  console.log("angle1a:",angle1a)
  console.log("angle2a:",angle2a)
  console.log("angle1b:",angle1b)
  console.log("angle2b:",angle2b)

  console.log("angle1a°:",radToDeg(angle1a))
  console.log("angle2a°:",radToDeg(angle2a))
  console.log("angle1b°:",radToDeg(angle1b))
  console.log("angle2b°:",radToDeg(angle2b))

  console.log("angle1-delta:",findShortestAngle_RADIANS(angle1a,angle1b))
  console.log("angle2-delta:",findShortestAngle_RADIANS(angle2a,angle2b))
  console.log("angle1-delta°:",radToDeg(findShortestAngle_RADIANS(angle1a,angle1b)))
  console.log("angle2-delta°:",radToDeg(findShortestAngle_RADIANS(angle2a,angle2b)))

  console.log("angle_average:",radToDeg(average))
  return(average)
}
function findShortestAngle_RADIANS(angle1,angle2){
  //findShortestAngle_RADIANS(-10°,10°) =-20°
  //atan2 likes to spit out range -pi (-180) to pi (180)
  //this function finds the shortest actual difference
  //IE: if one angle is -170 and another is 160, the shortest angle is 30, not 230
  let diff = ( angle2 - angle1 + Math.PI ) % (Math.PI*2) - Math.PI;
  return diff < -Math.PI ? diff + (Math.PI*2) : diff;
}
function MSE_transformer(v3a,v3b,pivot,loc,rot){
  let v3b_projected = []
  v3b.forEach((item, i) => {
    let adjustedPoint = item.clone()
    let rot_matrix = new THREE.Matrix4()
    rot_matrix.makeRotationFromEuler( new  THREE.Euler( rot.x,rot.y,rot.z, 'XYZ' ) )

    //move to origin
    adjustedPoint.sub(pivot)
    //rotate
    adjustedPoint.applyMatrix4(rot_matrix)
    //move back
    adjustedPoint.add(pivot)
    //offset
    adjustedPoint.add(loc)
    v3b_projected.push(adjustedPoint)
  });
  return({
    mse: MSE_V1(v3a,v3b_projected),
    points: v3b_projected
  })
}
function MSE_V1(v3a,v3b){
  // input1: vector 3 array a
  // input2: vector 3 array b

  // Shuffle array
  let v3a_s = v3a.sort(() => 0.5 - Math.random());
  let v3b_s = v3b.sort(() => 0.5 - Math.random());
  // Get sub-array of first n elements after shuffled
  let size = Math.min(100,v3a.length,v3b.length)
  let selected_a = v3a_s.slice(0, size);
  let selected_b = v3b_s.slice(0, size);

  //for each point we find closest point from other set.
  for (var i = 0; i < selected_a.length; i++) {
    let distance = 999999999
    let match= 0
    for (var k = 0; k < selected_b.length; k++) {
      let d = selected_a[i].distanceTo(selected_b[k])
      if(d<distance){
        distance = d
        match = k
      }
      selected_a[k].distance = distance
      selected_a[k].match = k
    }
  }

  //sum square of all distances
  let squared_min_distance = 0
  for (var i = 0; i < selected_a.length; i++) {
    squared_min_distance += Math.pow(selected_a[i].distance,2)
  }

  //find and return the mean of this value
  let mse = squared_min_distance/selected_a.length
  return(mse);
}
function pointcloudFromVec3array(vec3array,color){
  let mat = new THREE.PointsMaterial({ color: color, size: 0.00025 })
  let cloneArray = []
  vec3array.forEach((item) => {
    cloneArray.push(item.clone())
  });

  let debug_points_pc = new THREE.BufferGeometry().setFromPoints(cloneArray);
  let debug_point_obj = new THREE.Points(debug_points_pc, mat)
  debug_point_obj.name = "debug_stuff"
  //debug_point_obj.translateZ(0.001)
  scene.add(debug_point_obj)
}
function pointcloudFromVec3array_v(vec3array,color,vec3){
  let mat = new THREE.PointsMaterial({ color: color, size: 0.00025 })
  let cloneArray = []
  vec3array.forEach((item) => {
    cloneArray.push(item.clone())
  });

  let debug_points_pc = new THREE.BufferGeometry().setFromPoints(cloneArray);
  let debug_point_obj = new THREE.Points(debug_points_pc, mat)
  debug_point_obj.name = "debug_stuff"
  debug_point_obj.translateX(vec3.x)
  debug_point_obj.translateY(vec3.y)
  debug_point_obj.translateZ(vec3.z)
  scene.add(debug_point_obj)

}

function centroid(vec3array){
  let average = new THREE.Vector3()
  for (var i = 0; i < vec3array.length; i++) {
    average.add(vec3array[i])
  }
  average.divideScalar(vec3array.length)
  //console.log(average)
  //ui_create_sphere(average, 0x00FF00)
  return average
}
function waterline_polyline(positions, center){
  //atan2 all the things!
  //first we go through and add rotation value to each position.
  for (var i = 0; i < positions.length; i++) {
    positions[i].angle = Math.atan2( positions[i].z-center.z,positions[i].x-center.x ) //atan2(y,x)
    if(positions[i].angle<0){positions[i].angle+=(Math.PI*2)}
  }
  //then we sort the array based on the rotation
  positions.sort((a, b) => (a.angle >= b.angle) ? 1 : -1)
  generate_smooth_contour(positions)

  //then we build a polyline and add it to the scene.
  //let polyline = new THREE.BufferGeometry();
  let polyline = new THREE.BufferGeometry().setFromPoints(positions);
  let polyline_vertices = new Float32Array(positions.length*3)
  let polyline_colors = new Float32Array(positions.length*3)
  for (var j = 0; j < positions.length; j++) {
    polyline_vertices[j*3] = positions[j].x
    polyline_vertices[j*3+1] = positions[j].y + (j/20000)
    polyline_vertices[j*3+2] = positions[j].z

    let hue = map_range( j, 0, positions.length, 1 ,360).toFixed(0)
    var rgb = new THREE.Color("hsl("+hue+", 100%, 50%)")
    polyline_colors[j*3] = rgb.r
    polyline_colors[j*3+1] = rgb.g
    polyline_colors[j*3+2] = rgb.b
  }

  //polyline.setAttribute('position', new THREE.BufferAttribute(polyline_vertices,3))
  polyline.setAttribute('color', new THREE.BufferAttribute(polyline_colors,3))
  let contour = new THREE.Line(polyline, mat_default)
  contour.name = "polyline_waterline"
  contour.translateZ(0.5)
  scene.add(contour)

  /*
  let spline = new THREE.SplineCurve3(positions)
  var material = new THREE.LineBasicMaterial({color:0xFFFF00})
  let spline_obj = new THREE.Geometry()*/


  //sum the distances along each edge and output to console
}

function polyline(positions, name, xoffset, zoffset){

  let polyline = new THREE.BufferGeometry().setFromPoints(positions);
  let polyline_vertices = new Float32Array(positions.length*3)
  let polyline_colors = new Float32Array(positions.length*3)
  let summed_distance = 0
  for (var j = 0; j < positions.length; j++) {
    if(j<positions.length-1){
      summed_distance += positions[j].distanceTo(positions[j+1])
    }

    polyline_vertices[j*3] = positions[j].x
    polyline_vertices[j*3+1] = positions[j].y + (j/20000)
    polyline_vertices[j*3+2] = positions[j].z

    let hue = map_range( j, 0, positions.length, 1 ,120).toFixed(0)
    var rgb = new THREE.Color("hsl("+hue+", 100%, 50%)")
    polyline_colors[j*3] = rgb.r
    polyline_colors[j*3+1] = rgb.g
    polyline_colors[j*3+2] = rgb.b
  }
  polyline.setAttribute('color', new THREE.BufferAttribute(polyline_colors,3))
  let contour = new THREE.Line(polyline, mat_default)
  contour.name = name
  contour.distance = summed_distance
  console.log("distance is:",summed_distance)
  contour.translateX(xoffset)
  contour.translateZ(zoffset)
  scene.add(contour)
}


function createTangentCircle(a,b,c){
  let up = new THREE.Vector3(0,1,0)
  //find midpoint of ab (mab)
  let mab = a.clone().lerp(b,0.5)
  //find midpoint of bc (mbc)
  let mbc = b.clone().lerp(c,0.5)
  //find inverse of ab (iab)
  let ab = b.clone().sub(a)
  let iab = ab.applyAxisAngle ( up, -Math.PI/2 ).normalize()
  //find inverse of bc (ibc)
  let bc = c.clone().sub(b)
  let ibc = bc.applyAxisAngle ( up, -Math.PI/2 ).normalize()
  //find intersection between iab and ibc through mab and mbc
  let intersection = IntersectLines(
    [mab.x,mab.z], //ray origin 1
    [iab.x,iab.z], //ray direction 1
    [mbc.x,mbc.z], //ray origin 2
    [ibc.x,ibc.z]) //ray direction 2
  let inter = new THREE.Vector3(
    intersection[0],
    0,
    intersection[1])

  //find distance between new point and a
  let distance = inter.distanceTo(a)
  //generate circle
  let pointarray = []
  let circleResolution = 300
  for (var i = 0; i < circleResolution+1; i++) {
    let radian = (i/circleResolution)*(Math.PI*2)
    //solve for a point on the circle
    pointarray.push( new THREE.Vector3(
      intersection[0] + distance*Math.sin(radian),
      -.01,
      intersection[1] + distance*Math.cos(radian),
    ))
  }
  //add circle to scene
  //let polyline = new THREE.BufferGeometry();
  let circle_geo = new THREE.BufferGeometry().setFromPoints(pointarray);
  let circle_colors = new Float32Array(pointarray.length*3)
  for (var j = 0; j < pointarray.length; j++) {

    let hue = map_range( j, 0, pointarray.length, 1 ,360).toFixed(0)
    var rgb = new THREE.Color("hsl("+hue+", 100%, 25%)")
    circle_colors[j*3] = rgb.r
    circle_colors[j*3+1] = rgb.g
    circle_colors[j*3+2] = rgb.b
  }
  circle_geo.setAttribute('color', new THREE.BufferAttribute(circle_colors,3))
  let circle = new THREE.Line(circle_geo, mat_default)
  circle.name = "debug_circle"
  circle.translateZ(0.5)
  scene.add(circle)
}
function IntersectLines( P, r, Q, s ) {
// http://walter.bislins.ch
// line1 = P + lambda1 * r
// line2 = Q + lambda2 * s
// r and s must be normalized (length = 1)
// returns intersection point O of line1 with line2 = [ Ox, Oy ]
// returns null if lines do not intersect or are identical
var PQx = Q[0] - P[0];
var PQy = Q[1] - P[1];
var rx = r[0];
var ry = r[1];
var rxt = -ry;
var ryt = rx;
var qx = PQx * rx + PQy * ry;
var qy = PQx * rxt + PQy * ryt;
var sx = s[0] * rx + s[1] * ry;
var sy = s[0] * rxt + s[1] * ryt;
// if lines are identical or do not cross...
if (sy == 0) return null;
var a = qx - qy * sx / sy;
return [ P[0] + a * rx, P[1] + a * ry ];
}
function generate_smooth_contour(vec3array_withAngles){
  //this function needs [THREE.Vec3 with additional .angle property (in radians)] ... that is _.x, _.y, _.z, _.angle
  //For a first pass, I want to downsample the contour, so there is a point for every half degree. for a total of 720 points.
  //assuming that the array is *presorted* by angle...
  let newpositions= []
  let steps = 720
  for (var i = 0; i < steps; i++) {
    let start_radians = map_range( i, 0, steps, 0 , Math.PI*2)
    let end_radians = map_range( i+1, 0, steps, 0 , Math.PI*2)
    let angle_positions= []
    let angle_tolerance = 0.1
    //go through and find relavent points
    for (var k = 0; k < vec3array_withAngles.length; k++) {
      if(vec3array_withAngles[k].angle>=start_radians-angle_tolerance && vec3array_withAngles[k].angle<=end_radians+angle_tolerance){
        angle_positions.push(vec3array_withAngles[k])
      }
    }
    //once we have all items that fall within an angle, we find the average position
    if(angle_positions.length!=0){
        let smooth_position = new THREE.Vector3(0,0,0)
        for (var e = 0; e < angle_positions.length; e++) {
          smooth_position.add(angle_positions[e])
        }
        smooth_position.divideScalar(angle_positions.length)
        smooth_position.angle = start_radians
        smooth_position.y = 0 //snap to xz plane.
        newpositions.push(smooth_position)
      }
    }

  //close the loop
  if(newpositions.length>2){
    newpositions.push(newpositions[0])
  }

  //now we go through newpositions and create a new polyline.
  //let polyline = new THREE.BufferGeometry();
  let smoothline = new THREE.BufferGeometry().setFromPoints(newpositions);
  let smoothline_colors = new Float32Array(newpositions.length*3)
  for (var j = 0; j < newpositions.length; j++) {
    let hue = map_range( j, 0, newpositions.length, 1 ,360).toFixed(0)
    var rgb = new THREE.Color("hsl("+hue+", 100%, 50%)")
    smoothline_colors[j*3] = rgb.r
    smoothline_colors[j*3+1] = rgb.g
    smoothline_colors[j*3+2] = rgb.b
  }



  contour_measurement(newpositions,true);
  //addTangentCircles_v1(newpositions)

  generate_hemispheres(newpositions)

  //polyline.setAttribute('position', new THREE.BufferAttribute(polyline_vertices,3))
  smoothline.setAttribute('color', new THREE.BufferAttribute(smoothline_colors,3))
  let contour = new THREE.Line(smoothline, mat_red)
  contour.name = "polyline_waterline_smooth"
  contour.translateX(0.35)
  contour.translateZ(0.5)
  scene.add(contour)

}
function generate_hemispheres(vec3array){
  //make it a loop...
  vec3array.push(vec3array[0])
  //calculate bounding box center
  let bbox = getbbox_from_positions(vec3array)
  let point_center = new THREE.Vector3().lerpVectors(bbox.min,bbox.max,0.5)
  //if we do not subtract the center from the point locations, there is no guarantee that the loop will
  //cross the global x and z axis.
  //so...
  vec3array.forEach((item) => {
    item = item.sub(point_center)
  });

  //search for four inflections
  let inflections = []
  let inflection_index = []
  for (var i = 0; i < vec3array.length-1; i++) {
    let current = vec3array[i].clone()
    let next = vec3array[i+1].clone()
    //check for inflections on the x or y...
    if( Math.sign(current.x) != Math.sign(next.x)){
      //calculate intersection point
      //use remap to find percentage deltax to next versus deltax to 0
      //use calculated remap to find z
      let fraction = map_range(0, current.x,next.x, 0 , 1 ) //maprange declaration 636
      let intersect = current.lerp(next,fraction)
      ui_create_sphere(intersect, 0xFFFFFF)
      inflections.push(intersect)
      inflection_index.push(i)
    }
    if( Math.sign(current.z) != Math.sign(next.z)){
      //do the same for z
      let fraction = map_range(0, current.z,next.z, 0 , 1 ) //maprange declaration 636
      let intersect = current.lerp(next,fraction)
      ui_create_sphere(intersect, 0xFF00FF)
      inflections.push(intersect)
      inflection_index.push(i)
    }
  }

  //after checking inflections, we create the four polylines
  console.log("inflection_index",inflection_index)
  console.log("inflections",inflections)

  let hemisphere_pts = []
  let pts = []
  //regular
  for (var i = 0; i < 3; i++) {
    pts = []
    pts.push(inflections[i])
    pts = pts.concat(  vec3array.slice(inflection_index[i]+1,inflection_index[i+1] )  )
    pts.push(inflections[i+1])
    hemisphere_pts.push(pts)
    //polyline(pts, "hemisphere_"+i)
  }
  //special (polyline between first and last inflection)
  pts = []
  pts.push(inflections[3])
  //pts = pts.concat(vec3array.slice(inflection_index[3])) //add last part of array (odd result? unexpected point.)
  pts = pts.concat(vec3array.slice(1,inflection_index[0])) //add first part of array
  pts.push(inflections[0])
  hemisphere_pts.push(pts)

  if(hemisphere_pts[0]==undefined||hemisphere_pts[0]==undefined||hemisphere_pts[0]==undefined||hemisphere_pts[0]==undefined){
    console.log("one of the hemispheres was empty, contour splits could not be created.")
    return;
  }
  polyline(hemisphere_pts[0],"headContour_leftPosterior",   0-.02,1+.02)
  polyline(hemisphere_pts[1],"headContour_rightPosterior",  0-.02,1-.02)
  polyline(hemisphere_pts[2],"headContour_rightAnterior",   0+.02,1-.02)
  polyline(hemisphere_pts[3],"headContour_leftAnterior",    0+.02,1+.02)

  generate_SVG(
    hemisphere_pts[0],
    hemisphere_pts[1],
    hemisphere_pts[2],
    hemisphere_pts[3]
  )
  console.log(scene)

}

function generate_SVG(a,b,c,d){
  let sa =  parse_vec3array_to_string(a,false,false)
  let sb =  parse_vec3array_to_string(b,false,false)
  let sc =  parse_vec3array_to_string(c,false,false)
  let sd =  parse_vec3array_to_string(d,false,false)
  let measure_sa = contour_measurement(a,false)//front-right
  let measure_sb = contour_measurement(b,false)//back-right
  let measure_sc = contour_measurement(c,false)//back-left
  let measure_sd = contour_measurement(d,false)//front-left

  //lateral is left divided by right
  let lateral_ratio_front = (measure_sd/measure_sa).toFixed(2)
  let lateral_ratio_back = (measure_sc/measure_sb).toFixed(2)
  //ap is front divided by back
  let ap_ratio_left = (measure_sa/measure_sb).toFixed(2)
  let ap_ratio_right = (measure_sd/measure_sc).toFixed(2)

  let cephalicRatio = ((measure_sa+measure_sd)/(measure_sb+measure_sc)).toFixed(2)




  //convert from meters to cm
  let satocm = parseFloat(measure_sa*100).toFixed(2)
  let sbtocm = parseFloat(measure_sb*100).toFixed(2)
  let sctocm = parseFloat(measure_sc*100).toFixed(2)
  let sdtocm = parseFloat(measure_sd*100).toFixed(2)
  //project 4 hemispheres (read only x and z and swizzle them to x y coordinates)
  //create string with boilerplate ascii svg declaration
  //parse the lines to curves
  //add text for lengths of each segment
  let declaration = `
  <svg width="500" height="500" xmlns="http://www.w3.org/2000/svg">
  `
  let style = `
  <style>
    #dashedCurve{
      fill:none;
      stroke-width: 4px;
      stroke-dasharray:10,10;
      animation: dash 1s linear infinite;
    }
    #filledShape{
      fill: hsla(200,100%,50%,0.75);
      stroke-width: .1px;
      animation: dash 100s linear infinite;
    }
    @keyframes dash {
      from {
        stroke-dashoffset: 0;
      }
      to {
        stroke-dashoffset: -20;
      }
  </style>
  `
  let background = `
  <g><title>background</title><rect fill="#000" id="canvas_background" height="500" width="500" y="-1" x="-1"/></g>
  `
  let frontleft =  `<polyline id="dashedCurve" points="`+sa+`" fill="none"  stroke="hsla(0,100%,50%,0.75)" />
  `
  let frontright = `<polyline id="dashedCurve" points="`+sb+`" fill="none"  stroke="hsla(20,100%,50%,0.75)" />
  `
  let backleft =   `<polyline id="dashedCurve" points="`+sc+`" fill="none"  stroke="hsla(40,100%,50%,0.75)" />
  `
  let backright =  `<polyline id="dashedCurve" points="`+sd+`" fill="none"  stroke="hsla(60,100%,50%,0.75)" />
  `
  let readout_a = `
  <text x="10" y="20" font-family="Verdana" font-size="16" fill="hsla(0,100%,50%,1)">`+"FrontRight_length: "+satocm.toString() +`</text>
  `
  let readout_b = `
  <text x="10" y="40" font-family="Verdana" font-size="16" fill="hsla(20,100%,50%,1)">`+"BackRight_length: "+sbtocm.toString() +`</text>
  `
  let readout_c = `
  <text x="10" y="60" font-family="Verdana" font-size="16" fill="hsla(40,100%,50%,1)">`+"BackLeft_length: "+sctocm.toString() +`</text>
  `
  let readout_d = `
  <text x="10" y="80" font-family="Verdana" font-size="16" fill="hsla(60,100%,50%,1)">`+"FrontLeft_length: "+sdtocm.toString() +`</text>
  `

  let ratio_ap_l = `
  <text x="230" y="20" font-family="Verdana" font-size="16" fill="hsla(200,100%,50%,1)">`+"ap_ratio_left: "+ap_ratio_left.toString() +`</text>
  `
  let ratio_ap_r = `
  <text x="230" y="40" font-family="Verdana" font-size="16" fill="hsla(210,100%,50%,1)">`+"ap_ratio_right: "+ap_ratio_right.toString() +`</text>
  `
  let ratio_lat_f = `
  <text x="230" y="60" font-family="Verdana" font-size="16" fill="hsla(220,100%,50%,1)">`+"lateral_ratio_front: "+lateral_ratio_front.toString() +`</text>
  `
  let ratio_lat_b = `
  <text x="230" y="80" font-family="Verdana" font-size="16" fill="hsla(230,100%,50%,1)">`+"lateral_ratio_back: "+lateral_ratio_back.toString() +`</text>
  `
  let ratio_ceph = `
  <text x="230" y="100" font-family="Verdana" font-size="16" fill="hsla(240,100%,50%,1)">`+"Cephalic_ratio: "+cephalicRatio.toString() +`</text>
  `


  let terminal = `
  </svg>
  `

  let svg = declaration+style+frontright+frontleft+backleft+backright+readout_a+readout_b+readout_c+readout_d+ratio_ap_l+ratio_ap_r+ratio_lat_f+ratio_lat_b+ratio_ceph+terminal
  console.log(svg)
  //output to window, lower left...
  //ReactDOM.render(svg , document.getElementById('uistate'));
  document.getElementById('svg_container').innerHTML += svg

  // //add xml declaration
  // source = '<?xml version="1.0" standalone="no"?>\r\n' + source;
  //
  // //convert svg source to URI data scheme.
  // var url = "data:image/svg+xml;charset=utf-8,"+encodeURIComponent(source);
  //
  // //set url value to a element's href attribute.
  // document.getElementById("link").href = url;
  // //you can download svg file by right click menu.



}
function parse_vec3array_to_string(vec3array,xflip,yflip){
  if(xflip=true){xflip=-1}else{xflip=1}
  if(yflip=true){yflip=-1}else{yflip=1}
  let output = ""
  vec3array.forEach((item, i) => {
    let x = (1000*item.x.toFixed(5)*xflip)  +250
    let y = (1000*item.z.toFixed(5)*yflip)  +250
    output += x+","+y+" "

  });
  return output
}
function addTangentCircles_v1(vec3array){
  //in this function we go through all segments, and find the center of the contour.
  const box = getbbox_from_positions(vec3array)
  let center = new THREE.Vector3()
  center.lerpVectors(box.min.clone(), box.max.clone(), 0.5)
  center = box.min.clone().add(box.max.clone())
  center = center.multiplyScalar(0.5)
  //we also find add a tangent circle every time we detect an edge that is too long
  for (var i = 0; i < vec3array.length-1; i++) {

    let current = vec3array[i].clone();
    let next = vec3array[i+1].clone();
    if( current.distanceTo(next)>0.001){
      //add a tangent circle!
      // console.log(current, next, box, center)
      // ui_create_sphere(current,0xFF0000)
      // ui_create_sphere(next,0x00FF00)
      // ui_create_sphere(center,0xFF00FF)
      createTangentCircle( current, next, center);
    }
  }

}
function contour_measurement(vec3array, is_loop){
  //this function goes through a polyline, and sums the edge lengths.
  //if is_loop, an additional length from index[end] to index[start] is added to sum, closing the loop.
  if(vec3array.length<2){
    //escape early if the array is empty or is single point.
    var measurment_note = <div  style={heel_style}>contour needs at least two points to have distance attribute</div>
    ReactDOM.render(measurment_note, document.getElementById('uistate'));
    return 0;
  }
  let sum = 0
  for (var i = 0; i < vec3array.length-1; i++) {
    sum += vec3array[i].distanceTo( vec3array[i+1])
  }
  if(is_loop==true){
    sum+= vec3array[0].distanceTo(vec3array[vec3array.length-1])
  }
  var measurement = <div  style={heel_style}>Contour is {sum}</div>
  ReactDOM.render(measurement, document.getElementById('uistate'));
  console.log("SUM:",sum)
  return sum;
}
function radToDeg(rad) {
  return rad / (Math.PI / 180);
};
function degrees_to_radians(degrees){
  let radians = (degrees/180)*Math.PI;
  return radians;
}
function ui_create_sphere(vec3, hex){
  var sconstruct=  new THREE.SphereGeometry(0.002, 3, 3)
  var material = new THREE.MeshBasicMaterial( { color: hex } );
  var sphere = new THREE.Mesh( sconstruct, material );
  sphere.position.x = vec3.x
  sphere.position.y = vec3.y
  sphere.position.z = vec3.z
  sphere.name = "ui_sphere_marker"
  scene.add(sphere)
  return(sphere)
  //setTimeout(() => { scene.remove(sphere)}, 16000)
}
function ui_create_sphere_special(vec3, hex){
  var sconstruct=  new THREE.SphereGeometry(0.004, 10, 10)
  var material = new THREE.MeshBasicMaterial( { color: hex } );
  var sphere = new THREE.Mesh( sconstruct, material );
  sphere.position.x = vec3.x
  sphere.position.y = vec3.y
  sphere.position.z = vec3.z
  sphere.name = "ui_sphere_marker"
  scene.add(sphere)
  return(sphere)
  //setTimeout(() => { scene.remove(sphere)}, 16000)
}
function ui_create_childSphere(vec3, hex, parent){
  var sconstruct=  new THREE.SphereGeometry(0.002, 6, 6)
  var material = new THREE.MeshBasicMaterial( { color: hex } );
  var sphere = new THREE.Mesh( sconstruct, material );
  sphere.position.x = vec3.x
  sphere.position.y = vec3.y
  sphere.position.z = vec3.z
  sphere.name = "ui_sphere_marker"
  parent.add(sphere)
  return(sphere)
  //setTimeout(() => { scene.remove(sphere)}, 16000)

}
function ui_create_arrow(vector,origin,hex,time){
  var arrow = new THREE.ArrowHelper( vector, origin, 0.2, hex, 0.01, 0.01 )
  scene.add(arrow)
  setTimeout(() => { scene.remove(arrow)}, time)

}
function ui_freeze_orbit(){
  controls.enabled = false;
  console.log(controls)
}
function ui_activate_orbit(){
  controls.enabled = true;
  console.log(controls)
}
function findClosestPoint(obj, vec3){
  let min_distance = 999999999999
  let closestPosition = new THREE.Vector3(-1,-1,-1)
  for (var i = 0; i < obj.geometry.attributes.position.count; i++) {
    var geopoint = new THREE.Vector3(
      obj.geometry.attributes.position.array[i*3  ],
      obj.geometry.attributes.position.array[i*3+1],
      obj.geometry.attributes.position.array[i*3+2]
    )
    var this_distance = vec3.distanceTo(geopoint)
    if(this_distance<min_distance){
      min_distance = this_distance
      closestPosition = geopoint
    }
  }
  return closestPosition
}



// function helmetHeightmap(){
//   // What do I want to do?
//   // Import the helmet template object and a head to use.
//   let helmet_loader = new OBJLoader()
//   helmet_loader.load(
//     "helmetAsub3.obj",
//     function(object){//on load
//       let pointcloudobjs = []
//       object.traverse( function( child ) {
//           console.log("child: ",child)
//           if(child.geometry!=undefined && child.geometry.attributes.position!=undefined){
//             //go through points and create a new color attribute
//             let color = new Float32Array(child.geometry.attributes.position.count*3)
//             for (var i = 0; i < child.geometry.attributes.position.count; i++) {
//               var pos = new THREE.Vector3(
//                 child.geometry.attributes.position.array[i*3],
//                 child.geometry.attributes.position.array[i*3+1],
//                 child.geometry.attributes.position.array[i*3+2]
//               )
//               color[i*3] = pos.normalize().x
//               color[i*3+1] = pos.normalize().y
//               color[i*3+2] = pos.normalize().z
//
//               // var rgb = new THREE.Color("hsl("+0+", 100%, 50%)")
//               // color[i*3] = rgb.r
//               // color[i*3+1] = rgb.g
//               // color[i*3+2] = rgb.b
//             }
//             child.geometry.setAttribute('color', new THREE.BufferAttribute(color,3))
//             let helmet_mat = new THREE.MeshStandardMaterial({
//               //size: 0.00040,
//               color: 0xFF0000,
//               emissive: 0x004444,
//               wireframe: true,
//               vertexColors: THREE.VertexColors,
//              })
//             child.material = helmet_mat
//             //child.material = mat_red
//             // child.material = new THREE.PointsMaterial({
//             //   size: 0.0001,
//             //   vertexColors: THREE.VertexColors,
//             //   name: "POINT MAT"
//             // })
//             child.bRectSelectArray = new Array(child.geometry.attributes.position.count).fill(true);
//             child.name = "helmet"
//             //pointcloudobjs.push(child)
//             //child.geometry.attributes.color = color
//             scene.add(child)
//           }
//
//       } );
//       console.log("scene: ",scene)
//
//       // scene.add(object);
//       // console.log(object)
//       // FileList_create(files[0].name,object)
//     }
//   )
//
//   let head_loader = new PLYLoader()
//   head_loader.load(
//       'head_truth.ply',
//       function (geometry) {
//           geometry.computeVertexNormals()
//           const mesh = new THREE.Points(geometry, mat_default)
//           mesh.name = "helmet_target"
//           mesh.geometry.attributes.color2 =  mesh.geometry.attributes.color
//           scene.add(mesh)
//       },
//       (xhr) => {
//       },
//       (error) => {
//           console.log(error)
//       }
//   )
//
//   // Create texture map (will just do svg for now?)
//
//   //I want to plot all uv locations to an image.
//   //let's start!
//   let declaration = `
//   <svg width="500" height="500" xmlns="http://www.w3.org/2000/svg">
//   `
//   let style = `
//   <style>
//     #dashedCurve{
//       fill:none;
//       stroke-width: 4px;
//       stroke-dasharray:10,10;
//       animation: dash 1s linear infinite;
//     }
//     #filledShape{
//       fill: hsla(200,100%,50%,0.75);
//       stroke-width: .1px;
//       animation: dash 100s linear infinite;
//     }
//     @keyframes dash {
//       from {
//         stroke-dashoffset: 0;
//       }
//       to {
//         stroke-dashoffset: -20;
//       }
//   </style>
//   `
//   let background = `
//   <g><title>background</title><rect fill="#000" id="canvas_background" height="500" width="500" y="-1" x="-1"/></g>
//   `
//   let terminal = `
//   </svg>
//   `
//
//   let body = ""
//   //for each uv coordinate, we plot.
//
//
//   // Go from uv coordinate to world space
//   // Get normal of that world space point
//   // Raycast from that point to the head
//   // Find distance
//   // Plot that distance to the texture
//   // Output the texture
// }
// function createHelmetSVG(uvobj, targetobj){
//   //I want to plot uvs.
//   //I also want to plot points, with color dependent on
// }
// helmetHeightmap()




  /*keeping track of pointer mouse/touch position at all times*/
  window.addEventListener('mousemove', set_screen_xy);
  window.addEventListener('mouseout', clearPickPosition);
  window.addEventListener('mouseleave', clearPickPosition);
  window.addEventListener('resize', onWindowResize, false)

  function onWindowResize() {
      camera.aspect = window.innerWidth / window.innerHeight
      camera.updateProjectionMatrix()
      renderer.setSize(window.innerWidth, window.innerHeight)
      render()
  }
  //const stats = Stats()
  //  stats.dom.id = "stats"
  //  document.body.appendChild(stats.dom)


  const timestart = new Date().getTime()
  function animate() {
      requestAnimationFrame(animate)
      var timenow = new Date().getTime()
      var t = (timenow-timestart)

      rectangle_update()
      counter_update()


      //scene.rotation.x = t*0.001
      controls.update()
      render()
    //  stats.update()
  }
  function render() {
      renderer.render(scene, camera)
  }
  animate()
/////////////////////////////////////

const ui_style = /*style for container where the buttons are*/ {
  zIndex: "3",
  display:"hidden",
  fontSize: "1em",
  position: "absolute",
  paddingLeft: "10%",
}
const ui_filelist_style = {
  position:"absolute",
  right:"2em",
  top:"2em",
  color: "hsla(200,100%,50%,1.0)",
  zIndex:"3",
  padding: "1em",
  backgroundColor:"hsla(200, 100%, 40%, 0.2)",
  borderColor:"hsla(200, 100%, 75%, .8)",
  borderStyle:"dotted",
}
const btn_style =  /*style for buttons*/ {
  fontSize: "2em",
  marginRight:"1em",
  marginTop:"1em"
}
const webgl_style ={
  position: "absolute",
  left:"0px",
  top:"0px",
  zIndex: "0",
}
const negativeRectangle_style = /*style for selection rectangle*/ {
  backgroundColor:"hsla(0, 100%, 40%, 0.2)",
  borderColor:"hsla(0, 100%, 75%, .8)",
  borderStyle:"dotted",
  position:"absolute",
  left: "400px",
  top: "600px",
  width:"200px",
  height:"150px",
  strokeWidth:"5px",
  zIndex:"2",
  display:"none",
}
const positiveRectangle_style = /*style for selection rectangle*/ {
  backgroundColor:"hsla(120, 100%, 40%, 0.2)",
  borderColor:"hsla(120, 100%, 75%, .8)",
  borderStyle:"dotted",
  position:"absolute",
  left: "400px",
  top: "400px",
  width:"20px",
  height:"20px",
  strokeWidth:"5px",
  zIndex:"2",
  display:"none",
}
const counter_style = /*style for indicator that follows mouse*/ {
  backgroundColor:"hsla(60, 100%, 40%, 0.2)",
  borderColor:"hsla(60, 100%, 75%, .8)",
  color: "hsla(60, 100%, 50%, 1.0)",
  borderStyle:"dotted",
  position:"absolute",
  left: "400px",
  top: "400px",
  width:"20px",
  height:"20px",
  strokeWidth:"5px",
  zIndex:"2",
}
const status_style = /*lower-left tooltip*/ {
  backgroundColor:"hsla(120, 10%, 40%, 0.2)",
  borderColor:"hsla(120, 10%, 75%, .8)",
  borderStyle:"none",
  position:"absolute",
  left: "0px",
  bottom: "0px",
  height:"2px",
  strokeWidth:"5px",
  zIndex:"2",
}
const svg_container = {
  position: "fixed",
  bottom: "10px",
  right: "10px"
}
const icon_style = /**/{
  width:"2em",
  height:"2em",

}
const title_style = /**/{
  width:"20em",
  marginTop:"2em",
  paddingRight:"2em",
}
const file_browser_style = /*we only want to see the button*/{
  display:"none"
}


//the idea here is to load multiple files, then orient a scan using another scan, with the final step generating a new file from the others.
//how will this be done?
//each time the user hits the add button, we will update the scene, and a div list
//to orient one object to another, the operator will select three points on one item, and then the other.
//once 6 points are detected, the orientation will happen
//user can export at any time.

function match_App() {
  return (
    <div className="App">
      <div id="webglcontext_match" style={webgl_style}></div>
      <div id="ADDrect" style={positiveRectangle_style}></div>
      <div id="SUBrect" style={negativeRectangle_style}></div>
      <div id="UI_filelist" style={ui_filelist_style}>
        — Loaded Files —
      </div>
      <div id="UI" style={ui_style} >
        <input type="file" id="file-uploader" style={file_browser_style}/>
        <button id="btnload" type="file" style={btn_style} onClick={start_file_load}><img style={icon_style} src={add}/></button>
        <button id="p0"      style={btn_style} onClick={start_marker} ><img style={icon_style} src={mark}/></button>
        <button id="match"      style={btn_style} onClick={start_match} ><img style={icon_style} src={match}/></button>
        <button id="move"    style={btn_style} onClick={calc_orient}> <img style={icon_style} src={orient}/> </button>
        <button id="skullMark"    style={btn_style} onClick={start_skull}> SKULL </button>
        <button id="test_ipm"    style={btn_style} onClick={debug_ipm2}> IPMV2 </button>
      </div>
      <div id="uistate" style={status_style}>-1</div>
      <div id="svg_container" style={svg_container}></div>
    </div>
  );
}
export default match_App;
