import React, { createRef, useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import './App.css';
import ReactHTMLDatalist from 'react-html-datalist';
import 'react-datalist-input/dist/styles.css';
import axios from 'axios';

import { MeshBVH, computeBoundsTree, disposeBoundsTree, acceleratedRaycast } from 'three-mesh-bvh';
import * as THREE from 'three';
import { SpriteText2D, textAlign } from 'three-text2d'
import { TrackballControls } from 'three/examples/jsm/controls/TrackballControls';
import { PLYLoader } from 'three/examples/jsm/loaders/PLYLoader';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry';
import {PNG} from 'pngjs'
import Rusha from 'rusha';
import { render } from '@testing-library/react';
import {
  BrowserRouter as Router,
  Link,
  useLocation
} from "react-router-dom";
import { MathUtils } from 'three';
var rusha = new Rusha();
THREE.BufferGeometry.prototype.computeBoundsTree = computeBoundsTree;
THREE.BufferGeometry.prototype.disposeBoundsTree = disposeBoundsTree;
THREE.Mesh.prototype.raycast = acceleratedRaycast;

var unprocessedScans = new Array();
var selectedScan = null;
var scanName = null;

var leftRef = createRef(null);
var rightRef = createRef(null);

const loader = new PLYLoader();
var leftScene;
var rightScene;
var leftCamera;
var rightCamera;
var leftRenderer;
var rightRenderer;
var leftControls;
var rightControls;
const envTexture = new THREE.CubeTextureLoader().load([
  'img/px_50.png',
  'img/nx_50.png',
  'img/py_50.png',
  'img/ny_50.png',
  'img/pz_50.png',
  'img/nz_50.png'
]);
envTexture.mapping = THREE.CubeReflectionMapping;
// const material = new THREE.MeshPhysicalMaterial({
//   color: 0xb2ffc8,
//   envMap: envTexture,
//   metalness: 0,
//   roughness: 0,
//   transparent: true,
//   transmission: 1.0,
//   side: THREE.DoubleSide,
//   clearcoat: 1.0,
//   clearcoatRoughness: 0.25
// });
const material = new THREE.MeshNormalMaterial({side:THREE.DoubleSide});
const mat_red = new THREE.PointsMaterial({ color: 0xFF0000, size: 0.00025 });
const mat_blue = new THREE.PointsMaterial({ color: 0x0000FF, size: 0.00025 });
const mat_green = new THREE.PointsMaterial({ color: 0x00FF00, size: 0.00025 });
const mat_cyan = new THREE.PointsMaterial({ color: 0x00FFFF, size: 0.00025 });
const mat_white = new THREE.PointsMaterial({ color: 0xFFFFFF, size: 0.00025 });



//when the page opens, the first thing it should do is check the url for parameters.
//who is the visit for? is this person in the list of unprocessed visits?
//example: localhost:3000/?fn=Taylor&ln=Young&email=newon3@gmail.com
function checkParameters(){
  var url_param_string = window.location.search
  var url_params = new URLSearchParams(url_param_string)
  var fn = url_params.get("fn")
  var ln = url_params.get("ln")
  var email = url_params.get("email")
  //choose the last visit that matches these parameters
  if(fn!=undefined && ln!=undefined && email!=undefined){
    //look through unprocessedScans, and find most recent visit that matches the parameters
    for(var n= unprocessedScans.length-1; n>=0;n--){
      if( unprocessedScans[n].fname == fn  && unprocessedScans[n].lname == ln && unprocessedScans[n].email == email){
        //select this item
        console.log("FOUND",n,unprocessedScans[n])
        selectScan(unprocessedScans[n])
        break;
      }
    }
  }else{console.log("one of the parameters were undefined")}
}

//populateDatalist();

const headstyle={
  position:"absolute",
  top: "1em",
  left: "10%",
  color: "white",
  background: "hsla(100,0%,50%,0.50)",
  padding: "0.5em",
  fontSize: "1em",
  zIndex:"2",
  color:"black",
}

const myStyle = {
  position: "absolute",
  top: "10%",
  left: "10%",
  "zIndex": 2
}

const myStyle2 = {
  position: "absolute",
  left: "10%"
}

function makebuttons(){
  var one = document.getElementById('one').innerHTML=""
  unprocessedScans.forEach(element => {
    console.log(element)
    var b = document.createElement("button")
    b.innerHTML=element.value
    b.style.display="block"
    b.addEventListener("click", ()=>{selectScan(element)})
    document.getElementById('one').appendChild(b)
  });
}
axios.defaults.headers.common = {'Authorization': `bearer 1844267ba3d9d8e03cfdfbdc85e787c52d9a8afae5bc2326852f763ea461b91f2a88585e655df3f8eeb405ecda517c6007a17afaeb1e9752705e6fe0999d3b61def6aa249efc88d285aee0fca7d2255b4e193ebb8088c51123d2f05390c01792409eb8f5173e1bf559aebe19966dea1076fc3b17301e1162b4bc470855ebf235`}
function populateDatalist() {
  axios.get('https://api.andbounds.com/api/scans?populate=*&pagination[pageSize]=1500').then((res) => {
    for (var i = 0; i < res['data']['data'].length; i++) {
      if (res['data']['data'][i]['attributes'] !== undefined) {
        if (res['data']['data'][i]['attributes']['left_scan'] !== undefined && res['data']['data'][i]['attributes']['right_scan'] !== undefined) {
          var currScan = {
            'text': res['data']['data'][i]['id'],
            'scan': {
              'left': res['data']['data'][i]['attributes']['left_scan']['data']['attributes']['url'] || null,
              'right': res['data']['data'][i]['attributes']['right_scan']['data']['attributes']['url'] || null
            }
          }
          if (res['data']['data'][i]['attributes']['users_permissions_users'] !== undefined) {
            for (var j = 0; j < res['data']['data'][i]['attributes']['users_permissions_users']['data'].length; j++) {
              currScan['text'] += ' - ' + res['data']['data'][i]['attributes']['users_permissions_users']['data'][j]['attributes']['email'];
            }
          }
          unprocessedScans.push(currScan);
        }
        else {
          console.log(res['data']['data'][i]['id']);
        }
      }
    }
  });
}

function setupTHREE() {
  leftScene = new THREE.Scene();
  leftScene.add(new THREE.AxesHelper(5));
  leftScene.background = new THREE.Color(0x222222);
  rightScene = new THREE.Scene();
  rightScene.add(new THREE.AxesHelper(5));
  rightScene.background = new THREE.Color(0x222222);
  const light = new THREE.SpotLight();
  light.position.set(20, 20, 20);
  leftScene.add(light);
  rightScene.add(light);
  leftCamera = new THREE.PerspectiveCamera(
      75,
      (window.innerWidth / 2) / (window.innerHeight * 0.95),
      0.1,
      1000
  );
  rightCamera = new THREE.PerspectiveCamera(
    75,
    (window.innerWidth / 2) / (window.innerHeight * 0.95),
    0.1,
    1000
  );
  leftCamera.position.z = 1;
  rightCamera.position.z = 1;
  leftRenderer = new THREE.WebGLRenderer();
  leftRenderer.outputEncoding = THREE.sRGBEncoding;
  leftRenderer.setSize((window.innerWidth / 2), (window.innerHeight * 0.95));
  leftRef.current.appendChild(leftRenderer.domElement);
  leftControls = new TrackballControls(leftCamera, leftRenderer.domElement);
  leftControls.enableDamping = true;
  rightRenderer = new THREE.WebGLRenderer();
  rightRenderer.outputEncoding = THREE.sRGBEncoding;
  rightRenderer.setSize((window.innerWidth / 2), (window.innerHeight * 0.95));
  rightRef.current.appendChild(rightRenderer.domElement);
  rightControls = new TrackballControls(rightCamera, rightRenderer.domElement);
  rightControls.enableDamping = true;
  window.addEventListener( 'resize', () => {
    leftCamera.aspect = (window.innerWidth / 2) / (window.innerHeight * 0.95);
    leftCamera.updateProjectionMatrix();
    rightCamera.aspect = (window.innerWidth / 2) / (window.innerHeight * 0.95);
    rightCamera.updateProjectionMatrix();
    leftRenderer.setSize((window.innerWidth / 2), (window.innerHeight * 0.95));
    rightRenderer.setSize((window.innerWidth / 2), (window.innerHeight * 0.95));
  }, false );
  leftRenderer.render(leftScene, leftCamera);
  rightRenderer.render(rightScene, rightCamera);
  leftAnimate();
  rightAnimate();
}

function leftAnimate() {
  requestAnimationFrame(leftAnimate);
  leftControls.update()
  leftRenderer.render(leftScene, leftCamera);
}

function rightAnimate() {
  requestAnimationFrame(rightAnimate);
  rightControls.update();
  rightRenderer.render(rightScene, rightCamera);
}

function selectScan(event) {
  if (unprocessedScans.some(e => {
    return e['text'] == event.target.text;
  })) {
    selectedScan = unprocessedScans.filter(e => {
      return e['text'] == event.target.text;
    })[0];
    setupTHREE();
    if (selectedScan['scan'] != null) {
      if (selectedScan['scan']['left'] != null) {
        loader.load(selectedScan['scan']['left'], (geometry) => {
          let mesh = new THREE.Mesh( geometry, material );
          mesh.name = "leftScan"
          leftScene.add(mesh);
        });
      }
      if (selectedScan['scan']['right'] != null) {
        loader.load(selectedScan['scan']['right'], (geometry) => {
          let mesh = new THREE.Mesh( geometry, material );
          mesh.name = "rightScan"
          rightScene.add(mesh);
        });
      }
      document.getElementById('customer').innerHTML = selectedScan['text'];
      document.getElementById('one').innerHTML=""
      document.getElementById('placeMarkersLeft').style.display = ""
      document.getElementById('placeMarkersRight').style.display=""
      document.getElementById('restartButton').style.display=""
    }
  }
}
var mutex = true;
function selectScanByURL(id) {
  console.log("If you get a 500 error, include a scan id. like '/?id=127' ")
  if (mutex) {
    mutex = false;
    axios.get('https://api.andbounds.com/api/scans/' + id + '?populate=*').then((res) => {
      if (res['data']['data']['attributes']['name'] !== undefined) {
        scanName = res['data']['data']['attributes']['name'];
      }
      selectedScan = {
        'text': res['data']['data']['id'],
        'scan': null
      }
      if (res['data']['data']['attributes']['users_permissions_users'] !== undefined) {
        for (var i = 0; i < res['data']['data']['attributes']['users_permissions_users']['data'].length; i++) {
          selectedScan['text'] += ' - ' + res['data']['data']['attributes']['users_permissions_users']['data'][i]['attributes']['email'];
        }
      }
      if (res['data']['data']['attributes']['left_scan'] !== undefined && res['data']['data']['attributes']['right_scan'] !== undefined) {
        selectedScan['scan'] = new Object();
        selectedScan['scan']['left'] = res['data']['data']['attributes']['left_scan']['data']['attributes']['url'];
        selectedScan['scan']['right'] = res['data']['data']['attributes']['right_scan']['data']['attributes']['url'];
      }
      setupTHREE();
      if (selectedScan['scan'] != null) {
        if (selectedScan['scan']['left'] != null) {
          loader.load(selectedScan['scan']['left'], (geometry) => {
            let mesh = new THREE.Mesh( geometry, material );
            mesh.name = "leftScan"
            leftScene.add(mesh);
          });
        }
        if (selectedScan['scan']['right'] != null) {
          loader.load(selectedScan['scan']['right'], (geometry) => {
            let mesh = new THREE.Mesh( geometry, material );
            mesh.name = "rightScan"
            rightScene.add(mesh);
          });
        }
        document.getElementById('customer').innerHTML = selectedScan['text'];
        //document.getElementById('one').innerHTML=""
        document.getElementById('placeMarkersLeft').style.display = ""
        document.getElementById('placeMarkersRight').style.display=""
        //document.getElementById('mirrorFeet').style.display=""
        document.getElementById('restartButton').style.display=""
      }
      else {
        alert('error: 2 scans required');
      }
    });
  }
}

//6-27-2022 reimplement
//position of mouse is important... difference from before is now that I have two coordinates, one for left, and one for right.
const screen_xy_l = {x:0,y:0,px:0,py:0};
const screen_xy_r = {x:0,y:0,px:0,py:0};
function getCanvasRelativePosition_l(event) /*utility, get normalized {x,y}*/ {
  var canvas = leftRenderer.domElement
  const rect = canvas.getBoundingClientRect();
  return {
    x: (event.clientX - rect.left) * canvas.width  / rect.width,
    y: (event.clientY - rect.top ) * canvas.height / rect.height,
  };
}
function getCanvasRelativePosition_r(event) /*utility, get normalized {x,y}*/ {
  var canvas = rightRenderer.domElement
  const rect = canvas.getBoundingClientRect();
  return {
    x: (event.clientX - rect.left) * canvas.width  / rect.width,
    y: (event.clientY - rect.top ) * canvas.height / rect.height,
  };
}
function set_screen_xy(event) {
  if(leftRenderer!=undefined && rightRenderer!=undefined){
    const pos_l = getCanvasRelativePosition_l(event);
    const pos_r = getCanvasRelativePosition_r(event);
    var canvas = leftRenderer.domElement
    screen_xy_l.px = pos_l.x
    screen_xy_l.py = pos_l.y
    screen_xy_l.x = (pos_l.x / canvas.width ) *  2 - 1;
    screen_xy_l.y = (pos_l.y / canvas.height) * -2 + 1;  // note we flip Y

    canvas = rightRenderer.domElement
    screen_xy_r.px = pos_r.x
    screen_xy_r.py = pos_r.y
    screen_xy_r.x = (pos_r.x / canvas.width ) *  2 - 1;
    screen_xy_r.y = (pos_r.y / canvas.height) * -2 + 1;  // note we flip Y
  }
}
window.addEventListener('mousemove', set_screen_xy);

var markerStorage_l= {points:[undefined,undefined,undefined]}
var markerStorage_r= {points:[undefined,undefined,undefined]}
var markerStorage_l_measure = {points:[undefined,undefined]}
var markerStorage_r_measure = {points:[undefined,undefined]}

function click_marker_l(){
  click_marker("leftScan",screen_xy_l,"orient")
}
function click_marker_r(){
  click_marker("rightScan",screen_xy_r,"orient")
}
function click_marker_l_measure(){
  click_marker("leftScan",screen_xy_l,"measure")
}
function click_marker_r_measure(){
  click_marker("rightScan",screen_xy_r,"measure")
}

function click_marker(objectName,screenpos,mode) {

  let obj = undefined;
  let scene = undefined;
  let camera = undefined;
  let storage= undefined;
  let col = 0xFFFFFF;
  if(objectName=="leftScan"){
    if(mode=="orient"){
      scene= leftScene;
      camera= leftCamera;
      storage= markerStorage_l;
      col = 0x00ff00;
    }
    if(mode=="measure"){
      scene= leftScene;
      camera= leftCamera;
      storage= markerStorage_l_measure;
      col = 0x00ffff;
    }
  }
  if(objectName=="rightScan"){
    if(mode=="orient"){
      scene= rightScene;
      camera= rightCamera;
      storage= markerStorage_r;
      col = 0xffaa00;
    }
    if(mode=="measure"){
      scene= rightScene;
      camera= rightCamera;
      storage= markerStorage_r_measure;
      col = 0xff00ff;
    }
  }

  //index is first undefined item
  let index=0;
  for(var i = 0; i<storage.points.length;i++){
    if(storage.points[i]==undefined){
      index=i
      break
    }
  }

  //console.log("click!", index, scene)

  for (var i = 0; i < scene.children.length; i++) {
    if(scene.children[i].name ==objectName){
      obj = scene.children[i]

      //do raycast
      const raycaster = new THREE.Raycaster();
      raycaster.params.Points.threshold = 0.01;
      raycaster.setFromCamera(screenpos,camera)

      const intersects = raycaster.intersectObjects( [obj] ); //only test against one object (optimize)
      if(intersects[0]!=undefined){
        var sconstruct=  new THREE.SphereGeometry(0.005, 6, 6)
        const material = new THREE.MeshBasicMaterial( { color: col } );
        const sphere = new THREE.Mesh( sconstruct, material );
        sphere.position.x = intersects[0].point.x
        sphere.position.y = intersects[0].point.y
        sphere.position.z = intersects[0].point.z
        sphere.name = "marksphere"
        scene.add(sphere)
        //console.log("marker attached to:",obj)

        storage.points[index]= intersects[0].point
        //console.log(intersects[0].point)
      }

      //detach onclick, all markers accounted for.
      if(index==storage.points.length-1){
        unclick_marker()
        if(mode="measure"){
          //console log distance between markers
          //let positionA = storage.points[0]
          //let positionB = storage.points[1]
          //console.log(positionA.distanceTo(positionB))
          // if(objectName=="leftScan"){
          //   document.getElementById("measureOutput_left").innerHTML="Left distance: "+positionA.distanceTo(positionB)+"m."
          // }
          // if(objectName=="rightScan"){
          //   document.getElementById("measureOutput_right").innerHTML="Right distance: "+positionA.distanceTo(positionB)+"m."
          // }


        }
      }


    }
  }
}



function unclick_marker(){
  window.removeEventListener('touchstart', click_marker_l);
  window.removeEventListener('mousedown', click_marker_l);
  window.removeEventListener('touchend', click_marker_l);
  window.removeEventListener('mouseup', click_marker_l);

  window.removeEventListener('touchstart', click_marker_r);
  window.removeEventListener('mousedown', click_marker_r);
  window.removeEventListener('touchend', click_marker_r);
  window.removeEventListener('mouseup', click_marker_r);

  window.removeEventListener('touchstart', click_marker_l_measure);
  window.removeEventListener('mousedown', click_marker_l_measure);
  window.removeEventListener('touchend', click_marker_l_measure);
  window.removeEventListener('mouseup', click_marker_l_measure);

  window.removeEventListener('touchstart', click_marker_r_measure);
  window.removeEventListener('mousedown', click_marker_r_measure);
  window.removeEventListener('touchend', click_marker_r_measure);
  window.removeEventListener('mouseup', click_marker_r_measure);


  //when three marks are added, we want to see if all 6 marks were added.
  //if 6 marks were added, we will orient both feet at the same time
  if(markerStorage_l.points[0]!=undefined && markerStorage_l.points[1]!=undefined && markerStorage_l.points[2]!=undefined   &&markerStorage_r.points[0]!=undefined && markerStorage_r.points[1]!=undefined && markerStorage_r.points[2]!=undefined ){
    console.log("ALL MARKS PLACED")
    calc_orient()
  }
}
function map_range(value, low1, high1, low2, high2) {
  return low2 + (high2 - low2) * (value - low1) / (high1 - low1);
}
function map_range_clamp(value, low1, high1, low2, high2) {
  if(value<low1){value=low1}
  if(value>high1){value=high1}
  return low2 + (high2 - low2) * (value - low1) / (high1 - low1);
}
var OrientedMarks = []
function calc_orient(){
  let leftFoot = undefined;
  let rightFoot = undefined;

  //get a reference to the left foot
  for (var i = 0; i < leftScene.children.length; i++) {
    if(leftScene.children[i].name =="leftScan"){
      leftFoot = leftScene.children[i]
    }
  }
  //get a reference to the right foot
  for (var i = 0; i < rightScene.children.length; i++) {
    if(rightScene.children[i].name =="rightScan"){
      rightFoot = rightScene.children[i]
    }
  }

  var frame_forward_l = new THREE.Vector3()
  var frame_normal_l = new THREE.Vector3()
  var frame_tangent_l = new THREE.Vector3()
  var frame_tangent2_l = new THREE.Vector3()

  var frame_forward_r = new THREE.Vector3()
  var frame_normal_r = new THREE.Vector3()
  var frame_tangent_r = new THREE.Vector3()
  var frame_tangent2_r = new THREE.Vector3()


  var w_forward = new THREE.Vector3(1,0,0)
  var w_right =   new THREE.Vector3(0,1,0)
  var w_up =      new THREE.Vector3(0,0,1)
  var w_down =    new THREE.Vector3(0,0,-1)


  frame_forward_l.subVectors( markerStorage_l.points[1].clone(), markerStorage_l.points[0].clone() )
  frame_tangent_l.subVectors( markerStorage_l.points[2].clone(), markerStorage_l.points[0].clone() )
  frame_normal_l.crossVectors( frame_forward_l, frame_tangent_l)
  var mid_l = new THREE.Vector3()
  mid_l = markerStorage_l.points[1].clone().lerp(markerStorage_l.points[2],0.5)
  mid_l.sub(markerStorage_l.points[0])
  frame_tangent2_l.crossVectors(mid_l,frame_normal_l)

  var localm4_l = new THREE.Matrix4();
  localm4_l.makeBasis( frame_tangent2_l.normalize() ,mid_l.normalize(), frame_normal_l.normalize() )
  var localm4_l_inverse = new THREE.Matrix4();
  localm4_l_inverse = localm4_l.invert()

  //center centroid of three markers at origin?
  var centroid_l = new THREE.Vector3()
  centroid_l = markerStorage_l.points[1].clone().sub(markerStorage_l.points[0].clone()).multiplyScalar(0.5)
  var centroid_project_l = centroid_l.clone().applyMatrix4(localm4_l_inverse)
  //console.log(centroid_l)

  leftFoot.translateX(markerStorage_l.points[0].x*-1)
  leftFoot.translateY(markerStorage_l.points[0].y*-1)
  leftFoot.translateZ(markerStorage_l.points[0].z*-1)
  leftFoot.updateMatrix();
  leftFoot.applyMatrix4(localm4_l_inverse);

  leftFoot.position.y = leftFoot.position.y - centroid_project_l.y
  leftFoot.updateMatrix();
  leftFoot.geometry.applyMatrix4( leftFoot.matrix );
  leftFoot.position.set( 0, 0, 0 );
  leftFoot.rotation.set( 0, 0, 0 );
  leftFoot.scale.set( 1, 1, 1 );
  leftFoot.updateMatrix();


  var centroid_ly = new THREE.Vector3(0,centroid_l.y,0)
  var m0 = new THREE.Vector3().sub(centroid_ly)
  var m1 = markerStorage_l.points[1].sub(markerStorage_l.points[0]).applyMatrix4(localm4_l_inverse).sub( centroid_ly)
  var m2 = markerStorage_l.points[2].sub(markerStorage_l.points[0]).applyMatrix4(localm4_l_inverse).sub( centroid_ly)

  var sconstruct=  new THREE.SphereGeometry(0.005, 6, 6)
  var material = new THREE.MeshBasicMaterial( { color: 0xFF00FF } );
  var sphere = new THREE.Mesh( sconstruct, material );
  sphere.position.x = m0.x 
  sphere.position.y = m0.y 
  sphere.position.z = m0.z 
  sphere.name = "marksphere"
  leftScene.add(sphere)
  var sconstruct1=  new THREE.SphereGeometry(0.005, 6, 6)
  var sphere1 = new THREE.Mesh( sconstruct1, material );
  sphere1.position.x = m1.x 
  sphere1.position.y = m1.y 
  sphere1.position.z = m1.z 
  sphere1.name = "marksphere"
  leftScene.add(sphere1)
  var sconstruct2=  new THREE.SphereGeometry(0.005, 6, 6)
  var sphere2 = new THREE.Mesh( sconstruct2, material );
  sphere2.position.x = m2.x 
  sphere2.position.y = m2.y 
  sphere2.position.z = m2.z 
  sphere2.name = "marksphere"
  leftScene.add(sphere2)

  frame_forward_r.subVectors( markerStorage_r.points[1], markerStorage_r.points[0] )
  frame_tangent_r.subVectors( markerStorage_r.points[2], markerStorage_r.points[0] )
  frame_normal_r.crossVectors( frame_forward_r, frame_tangent_r).multiplyScalar(-1)
  var mid_r = new THREE.Vector3()
  mid_r = markerStorage_r.points[1].clone().lerp(markerStorage_r.points[2],0.5)
  mid_r.sub(markerStorage_r.points[0])
  frame_tangent2_r.crossVectors(mid_r,frame_normal_r)

  var localm4_r = new THREE.Matrix4();
  localm4_r.makeBasis( frame_tangent2_r.normalize() ,mid_r.normalize(), frame_normal_r.normalize() )
  var localm4_r_inverse = new THREE.Matrix4();
  localm4_r_inverse = localm4_r.invert()

  //center centroid of three markers at origin?
  var centroid_r = new THREE.Vector3()
  centroid_r =  markerStorage_r.points[1].clone().sub(markerStorage_r.points[0].clone()).multiplyScalar(0.5)
  var centroid_project_r = centroid_r.clone().applyMatrix4(localm4_r_inverse)
  //console.log(centroid_r)


  rightFoot.translateX(markerStorage_r.points[0].x*-1)
  rightFoot.translateY(markerStorage_r.points[0].y*-1)
  rightFoot.translateZ(markerStorage_r.points[0].z*-1)
  rightFoot.updateMatrix();
  rightFoot.applyMatrix4(localm4_r_inverse);

  rightFoot.position.y = rightFoot.position.y - centroid_project_r.y
  rightFoot.updateMatrix();
  rightFoot.geometry.applyMatrix4( rightFoot.matrix );
  rightFoot.position.set( 0, 0, 0 );
  rightFoot.rotation.set( 0, 0, 0 );
  rightFoot.scale.set( 1, 1, 1 );
  rightFoot.updateMatrix();

  var centroid_ry = new THREE.Vector3(0,centroid_r.y,0)
  var m3 = new THREE.Vector3().sub(centroid_ry)
  var m4 = markerStorage_r.points[1].sub( markerStorage_r.points[0]).applyMatrix4(localm4_r_inverse).sub(centroid_ry)
  var m5 = markerStorage_r.points[2].sub( markerStorage_r.points[0]).applyMatrix4(localm4_r_inverse).sub(centroid_ry)



  var sconstruct=  new THREE.SphereGeometry(0.005, 6, 6)
  var material = new THREE.MeshBasicMaterial( { color: 0xFF00FF } );
  var sphere = new THREE.Mesh( sconstruct, material );
  sphere.position.x = m3.x 
  sphere.position.y = m3.y 
  sphere.position.z = m3.z 
  sphere.name = "marksphere"
  rightScene.add(sphere)
  var sconstruct1=  new THREE.SphereGeometry(0.005, 6, 6)
  var sphere1 = new THREE.Mesh( sconstruct1, material );
  sphere1.position.x = m4.x 
  sphere1.position.y = m4.y 
  sphere1.position.z = m4.z 
  sphere1.name = "marksphere"
  rightScene.add(sphere1)
  var sconstruct2=  new THREE.SphereGeometry(0.005, 6, 6)
  var sphere2 = new THREE.Mesh( sconstruct2, material );
  sphere2.position.x = m5.x 
  sphere2.position.y = m5.y 
  sphere2.position.z = m5.z 
  sphere2.name = "marksphere"
  rightScene.add(sphere2)

  OrientedMarks = {
    m0:{
      x: m0.x,
      y: m0.y,
      z: m0.z,
      u: 0.0,
      v: 0.0
    },
    m1:{
      x: m1.x,
      y: m1.y,
      z: m1.z,
      u: 0.0,
      v: 0.0
    },
    m2:{
      x: m2.x,
      y: m2.y,
      z: m2.z,
      u: 0.0,
      v: 0.0
    },

    m3:{
      x: m3.x,
      y: m3.y,
      z: m3.z,
      u: 0.0,
      v: 0.0
    },
    m4:{
      x: m4.x,
      y: m4.y,
      z: m4.z,
      u: 0.0,
      v: 0.0
    },
    m5:{
      x: m5.x,
      y: m5.y,
      z: m5.z,
      u: 0.0,
      v: 0.0
    }
  }
  setImmediate(() => {
    generate_surface_v4(leftScene, leftFoot, rusha.digest(JSON.stringify(leftFoot)), () => {});
  });
  setImmediate(() => {
    generate_surface_v4(rightScene, rightFoot, rusha.digest(JSON.stringify(rightFoot)), () => {});
  });
  setTimeout(() => {
    newVisit(leftScene, leftFoot, rightScene, rightFoot);
  }, 5000);
}

function newVisit(leftScene, leftFoot, rightScene, rightFoot) {

  if (scanName !== undefined) {
    selectedScan['scanName'] = scanName;
  }
  selectedScan['marks'] = OrientedMarks;
  axios.post('https://pscan.andbounds.com/newVisit', {
    'leftFoot': rusha.digest(JSON.stringify(leftFoot)),
    'rightFoot': rusha.digest(JSON.stringify(rightFoot)),
    'selectedScan': selectedScan
  }).then(() => {
    // alert('creating visit');
    // generate_surface_v4(leftScene, leftFoot, rusha.digest(JSON.stringify(leftFoot)));
    // generate_surface_v4(rightScene, rightFoot, rusha.digest(JSON.stringify(rightFoot)));
  });
}

//helper functions for blur
function getrow(i,resx){
  return (parseInt(i/resx))
}
function getcol(i,resx){
  return (i%resx)
}
function getindex(row,column,resx){
  return (row*resx)+column
}
function checkindex_isout(i,resx,resy){
  if(i<=-1 || i>=resx*resy){return true}
  else{return(false)}
}

function clipTrangleMesh(scene,object,waterline){
  let isleft = (object.name=="leftScan")? true:false
  let isright = (object.name=="rightScan")? true:false
  let marks = []
  let maxRadius = 0.15 //imagine a sphere originating from each mark on the bottom of the foot.
  if(isleft){
    marks = [new THREE.Vector3(OrientedMarks.m0.x,OrientedMarks.m0.y,OrientedMarks.m0.z),new THREE.Vector3(OrientedMarks.m1.x,OrientedMarks.m1.y,OrientedMarks.m1.z),new THREE.Vector3(OrientedMarks.m2.x,OrientedMarks.m2.y,OrientedMarks.m2.z)]
  }
  if(isright){
    marks = [new THREE.Vector3(OrientedMarks.m3.x,OrientedMarks.m3.y,OrientedMarks.m3.z),new THREE.Vector3(OrientedMarks.m4.x,OrientedMarks.m4.y,OrientedMarks.m4.z),new THREE.Vector3(OrientedMarks.m5.x,OrientedMarks.m5.y,OrientedMarks.m5.z)]
  }
  //GO THROUGH EACH POINT
  //TRACK NUMBER OF INVALID POINTS PRECEDING POINT
  //IF INVALID, index will be negative

  //Each triangle is made of indexes to points, if we could the number of invalid points, we can find the point's new position on the array
  let invalidCounter =0
  let pt_offset = new Int32Array(object.geometry.attributes.position.count)
  for(var i=0; i<object.geometry.attributes.position.count;i++){
    var x = object.geometry.attributes.position.array[i*3+0]
    var y = object.geometry.attributes.position.array[i*3+1]
    var z = object.geometry.attributes.position.array[i*3+2]
    if(z<waterline){
      let thisPosition = new THREE.Vector3(x,y,z)
      if(thisPosition.distanceTo(marks[0])<maxRadius||thisPosition.distanceTo(marks[1])<maxRadius||thisPosition.distanceTo(marks[2])<maxRadius){
        //keep the point:
        pt_offset[i] = i-(invalidCounter) //point's new index is at new location, should never be less than 0.
      }else{
        pt_offset[i] = -10 //point is invalid
        invalidCounter++        
      }
    }
    else{
      pt_offset[i] = -10 //point is invalid
      invalidCounter++
    }
  }

  //console.log(pt_offset)

  //pack new position array
  let newposition = new Float32Array((object.geometry.attributes.position.count - invalidCounter)*3);
  for(let  i =0; i < object.geometry.attributes.position.count ; i++ ){ //go through all old points.
    let new_i = pt_offset[i]
    if(new_i>=0){ //if point is valid //new_i>=0
      var fx = object.geometry.attributes.position.array[ i*3+0 ]
      var fy = object.geometry.attributes.position.array[ i*3+1 ]
      var fz = object.geometry.attributes.position.array[ i*3+2]
      newposition[new_i*3+0] = fx
      newposition[new_i*3+1] = fy
      newposition[new_i*3+2] = fz
    }
  }


  //now go through all triangles
  let triangle_validity = new Array(object.geometry.index.count/3).fill(false)
  let valid_triangle_count = 0
  for(var i =0; i < object.geometry.index.count/3; i++){

    let trianglept1 = object.geometry.index.array[i*3+0]
    let trianglept2 = object.geometry.index.array[i*3+1]
    let trianglept3 = object.geometry.index.array[i*3+2]
    //check the points
    let ipt1 = pt_offset[trianglept1]
    let ipt2 = pt_offset[trianglept2]
    let ipt3 = pt_offset[trianglept3]

    if( ipt1<0 || ipt2<0 || ipt3<0 ){
      //no triangle here.
    }
    else{
      triangle_validity[i] = true
      valid_triangle_count++
      // vec_pt1 = object.attributes.position.array[ ipt1 ]
      // vec_pt2 = object.attributes.position.array[ ipt2 ]
      // vec_pt3 = object.attributes.position.array[ ipt3 ]
    }
  }


  //pack new trangle index array
  let newindex = []
  //let newindex = new Uint32Array(valid_triangle_count*3)
  let testindex = new Uint32Array(3);
  testindex[0]=0
  testindex[1]=1
  testindex[2]=2
  let currentTriangleToPack = -1
  for(var i = 0; i < object.geometry.index.count/3; i++){  //go through all triangles
    if(triangle_validity[i]==true){
      currentTriangleToPack++
      //get index of points
      var oldindex1 = object.geometry.index.array[i*3+0]
      var oldindex2 = object.geometry.index.array[i*3+1]
      var oldindex3 = object.geometry.index.array[i*3+2]

      //get offset point index
      var newindex1= pt_offset[oldindex1]
      var newindex2= pt_offset[oldindex2]
      var newindex3= pt_offset[oldindex3]

      //set these three pointers
      // newindex[currentTriangleToPack*3+0] =newindex1
      // newindex[currentTriangleToPack*3+1] =newindex2
      // newindex[currentTriangleToPack*3+2] =newindex3

      //with dynamic array, push three new items...
      newindex.push(newindex1)
      newindex.push(newindex2)
      newindex.push(newindex3)
    }
  }

  //make new geometry using new index and posiiton buffer...
  let geometry = new THREE.BufferGeometry();
  geometry.setAttribute( 'position', new THREE.BufferAttribute( newposition, 3 ) );
  geometry.setIndex(newindex)
  //geometry.setAttribute( 'index', new THREE.BufferAttribute( newindex, 3 ) );
  let material = new THREE.MeshBasicMaterial( { color: 0xff0000 } );
  let mesh = new THREE.Mesh( geometry, mat_red );
  mesh.name = object.name
  scene.add(mesh)
  console.log("mesh")
  console.log(mesh)
  return(mesh)
}
 

function generate_surface_v4 (scene, sceneobj, resourceId, callback){
  var spacing = 0.002 //pixel density
  let raydistancelimit=0.05;  //Clamp, increase this is arch is greater than 10cm.
  let isleft = (sceneobj.name=="leftScan")? true:false
  sceneobj = clipTrangleMesh(scene,sceneobj,raydistancelimit+0.01)
  var bbox = new THREE.Box3().setFromObject(sceneobj)
  //let bbox_centerx = (bbox.min.x + bbox.max.x) /2
  //let bbox_centery = (bbox.min.y + bbox.max.y) /2
  //using the spacing and a 3cm padding, we derive the xmin, xmax, ymin, and ymax of the surface.
  var bbox_minx = bbox.min.x-.03
  var bbox_maxx = bbox.max.x+.03
  var bbox_miny = bbox.min.y-.03
  var bbox_maxy = bbox.max.y+.03
  var bbox_width = bbox_maxx - bbox_minx
  var bbox_height = bbox_maxy -bbox_miny
  var bbox_center = THREE.MathUtils.lerp(bbox.min, bbox.max)
  var bbox_resolution_x = parseInt(bbox_width/spacing)
  var bbox_resolution_y = parseInt(bbox_height/spacing)



  //Adding things to the viewport 
  //10cm GRID
  let grid = new THREE.GridHelper(1, 10,0x111,0x000);
  grid.rotateOnAxis(new THREE.Vector3(1,0,0), Math.PI/2)
  grid.position.z = -.019
  scene.add(grid);

    //1cm GRID
    let grid2 = new THREE.GridHelper(.4, 40,0x111,0x000);
    grid2.rotateOnAxis(new THREE.Vector3(1,0,0), Math.PI/2)
    grid2.position.z = -.019
    scene.add(grid2);

  //CREATE GEOMETRY FONT FOR DIMENSION READOUT (26-9-2022)
  //WIDTH
  let loader = new FontLoader();
  loader.load( 'helvetiker_regular.typeface.json', function ( font ) {
    const color = 0xFF0000;
    const matDark = new THREE.LineBasicMaterial( {
      color: color,
      side: THREE.DoubleSide
    } );
    const matLite = new THREE.MeshBasicMaterial( {
      color: color,
      transparent: true,
      opacity: 0.4,
      side: THREE.DoubleSide
    } );
    const message = ((bbox.max.x-bbox.min.x)*1000).toFixed(2) +" mm";
    const shapes = font.generateShapes( message, 0.02 );
    const geometry = new THREE.ShapeGeometry( shapes );
    geometry.computeBoundingBox();
    const xMid = - 0.5 * ( geometry.boundingBox.max.x - geometry.boundingBox.min.x );
    geometry.translate( xMid, bbox.max.y +.03, 0 ); //(1/0.0003) * bbox.max.y +.03
    // make shape ( N.B. edge view not visible )
    const text = new THREE.Mesh( geometry, matLite );
    scene.add( text );
    // make line shape ( N.B. edge view remains visible )
    const holeShapes = [];
    for ( let i = 0; i < shapes.length; i ++ ) {
      const shape = shapes[ i ];
      if ( shape.holes && shape.holes.length > 0 ) {
        for ( let j = 0; j < shape.holes.length; j ++ ) {
          const hole = shape.holes[ j ];
          holeShapes.push( hole );
        }
      }
    }
    shapes.push.apply( shapes, holeShapes );
    const lineText = new THREE.Object3D();
    for ( let i = 0; i < shapes.length; i ++ ) {
      const shape = shapes[ i ];
      const points = shape.getPoints();
      const geometry = new THREE.BufferGeometry().setFromPoints( points );
      geometry.translate( xMid, bbox.max.y+.03, 0 );
      const lineMesh = new THREE.Line( geometry, matDark );
      lineText.add( lineMesh );
    }
    scene.add( lineText );
    render();
  },function(err){console.log("error")} );

    //CREATE GEOMETRY FONT FOR DIMENSION READOUT (26-9-2022)
    //HEIGHT
    loader = new FontLoader();
    loader.load( 'helvetiker_regular.typeface.json', function ( font ) {
      const color = 0x00FF00;
      const matDark = new THREE.LineBasicMaterial( {
        color: color,
        side: THREE.DoubleSide
      } );
      const matLite = new THREE.MeshBasicMaterial( {
        color: color,
        transparent: true,
        opacity: 0.4,
        side: THREE.DoubleSide
      } );
      const message =  ((bbox.max.y-bbox.min.y)*1000).toFixed(2) +" mm" ;
      const shapes = font.generateShapes( message, 0.02 );
      const geometry = new THREE.ShapeGeometry( shapes );
      geometry.computeBoundingBox();
      const xMid = - 0.5 * ( geometry.boundingBox.max.x - geometry.boundingBox.min.x );
      geometry.translate( bbox.max.x + .03 ,0 ,0);
      // make shape ( N.B. edge view not visible )
      const text = new THREE.Mesh( geometry, matLite );
      scene.add( text );
      // make line shape ( N.B. edge view remains visible )
      const holeShapes = [];
      for ( let i = 0; i < shapes.length; i ++ ) {
        const shape = shapes[ i ];
        if ( shape.holes && shape.holes.length > 0 ) {
          for ( let j = 0; j < shape.holes.length; j ++ ) {
            const hole = shape.holes[ j ];
            holeShapes.push( hole );
          }
        }
      }
      shapes.push.apply( shapes, holeShapes );
      const lineText = new THREE.Object3D();
      for ( let i = 0; i < shapes.length; i ++ ) {
        const shape = shapes[ i ];
        const points = shape.getPoints();
        const geometry = new THREE.BufferGeometry().setFromPoints( points );
        geometry.translate( bbox.max.x + .03 ,0 ,0);
        const lineMesh = new THREE.Line( geometry, matDark );
        lineText.add( lineMesh );
      }
      scene.add( lineText );
      render();
    },function(err){console.log("error")} );

  
  const mat_default = new THREE.PointsMaterial({size: 0.0040, vertexColors:true }) //,vertexColors: THREE.VertexColors
  //RAYCAST BASED
  var beginTime = Date.now()
  var resolution_x = bbox_resolution_x
  var resolution_y = bbox_resolution_y


  //version 2 xi,xf,yi,yf
  var xf = bbox_minx + (resolution_x*spacing)
  var xi = bbox_minx 

  var yf = bbox_miny + (resolution_y*spacing)
  var yi = bbox_miny

  if(isleft===true){
    OrientedMarks.m0.u = map_range_clamp(OrientedMarks.m0.x, xi, xf, 0, 1)
    OrientedMarks.m0.v = map_range_clamp(OrientedMarks.m0.y, yi, yf, 0, 1)
    OrientedMarks.m1.u = map_range_clamp(OrientedMarks.m1.x, xi, xf, 0, 1)
    OrientedMarks.m1.v = map_range_clamp(OrientedMarks.m1.y, yi, yf, 0, 1)
    OrientedMarks.m2.u = map_range_clamp(OrientedMarks.m2.x, xi, xf, 0, 1)
    OrientedMarks.m2.v = map_range_clamp(OrientedMarks.m2.y, yi, yf, 0, 1)
    
  }
  if(isleft===false){
    OrientedMarks.m3.u = map_range_clamp(OrientedMarks.m3.x, xi, xf, 0, 1)
    OrientedMarks.m3.v = map_range_clamp(OrientedMarks.m3.y, yi, yf, 0, 1)  
    OrientedMarks.m4.u = map_range_clamp(OrientedMarks.m4.x, xi, xf, 0, 1)
    OrientedMarks.m4.v = map_range_clamp(OrientedMarks.m4.y, yi, yf, 0, 1)  
    OrientedMarks.m5.u = map_range_clamp(OrientedMarks.m5.x, xi, xf, 0, 1)
    OrientedMarks.m5.v = map_range_clamp(OrientedMarks.m5.y, yi, yf, 0, 1)
  }



  sceneobj.updateMatrixWorld();
  console.log(sceneobj)
  let bvhobj = new MeshBVH( sceneobj.geometry );

  var minimum_distance = 0; var maximum_distance= 0 //useful for creating color gradient
  const surface = new THREE.BufferGeometry();
  var distances = new Float32Array(resolution_x*resolution_y)
  var vertices = new Float32Array(resolution_x*resolution_y*3)
  var color = new Float32Array(resolution_x*resolution_y*3)

  //V4 RAYCAST CODE GOES HERE ---- GENERATE SURFACE
  for(var i = 0; i < resolution_x*resolution_y; i ++){

    var xpos = i%resolution_x
    var ypos = Math.floor(i/resolution_x)
    var zpos = -.02

    //normalized space
    var u = xpos/resolution_x
    var v = ypos/resolution_y

    //define location of points
    vertices[i*3+0]= map_range(u,0,1,xi,xf)
    vertices[i*3+1]= map_range(v,0,1,yi,yf)
    vertices[i*3+2]= zpos

    //do raycast...

    let caster = new THREE.Raycaster( new THREE.Vector3(map_range(u,0,1,xi,xf),map_range(v,0,1,yi,yf),zpos), new THREE.Vector3(0.0,0.0,1.0), 0 , 0.05);
    //let hits = caster.intersectObject(sceneobj)

    let hit = bvhobj.raycastFirst(caster.ray)
    let hits = [hit]

    //get shortest distance...
    //hits.sort((a,b)=>b.distance-a.distance) //(SHOULD ALREADY BE SORTED)
    if(hits.length>0&&hits[0]!=null){
      let shortestDistance = hits[0].distance
      if(shortestDistance < minimum_distance){minimum_distance=shortestDistance}
      if(shortestDistance > maximum_distance){maximum_distance=shortestDistance}
      distances[i]= shortestDistance
      //console.log(hits)
    }
    else{
      distances[i]=0.0001
    }
  }

  //For each pixel we check to see if it is farther away than the limit, if it is, then we need to clamp it.
  for(var i = 0; i < resolution_x*resolution_y; i ++){
    if(distances[i]>raydistancelimit){  //distance is nearly 0 if over the limit.
      //distances[i]=0.0001
      distances[i]=raydistancelimit
    }

    if(distances[i]<0.0001){  //if ray hits nothing it will return nearly 0, if this happens use maxdist
      //distances[i]=0.0001
      distances[i]=raydistancelimit
    }

  }

  var distances_blur = new Float32Array(resolution_x*resolution_y)
  var maximum_dist2 = distances[0]
  //copy over distances array into new variable
  for(var i = 0; i<resolution_x*resolution_y;i++){
    distances_blur[i]=distances[i]
  }

  //scan the array first to find relative min and max.
  var newmin = 2000
  var newmax= -2000
  for(var i = 0; i<resolution_x*resolution_y;i++){
    if(distances[i]>newmax){newmax=distances[i]}
    if(distances[i]<newmin){newmin=distances[i]}
  }
  var upperfraction = MathUtils.lerp(newmin,newmax,0.75)





  //iteratively blur the distance array
  for (var iterations=0;iterations<50;iterations++){
    //console.log("iteration:",iterations)
    for(var i=0;i<resolution_x*resolution_y;i++){
      let currentrow = parseInt(i/resolution_x)
      let currentcol = i%resolution_x

      let previousrow =  currentrow-1
      let nextrow = currentrow+1
      let previouscol = currentcol-1
      let nextcol = currentcol+1

      //Get the 8 neighbors
      let neighbors = [
        getindex(previousrow,previouscol,resolution_x),
        getindex(previousrow,currentcol,resolution_x),
        getindex(previousrow,nextcol,resolution_x),

        getindex(currentrow,previouscol,resolution_x),
        i,
        getindex(currentrow,nextcol,resolution_x),

        getindex(nextrow,previouscol,resolution_x),
        getindex(nextrow,currentcol,resolution_x),
        getindex(nextrow,nextcol,resolution_x),
      ]

      //Check to see if index lies out of bounds, if it does.
      for(var n=0; n < neighbors.length; n++){
        if(checkindex_isout(neighbors[n],resolution_x,resolution_y)){
          neighbors[n]=i
        }
      }

      //Sum and average.
      let average = 0;
      for(var n=0; n < neighbors.length; n++){
        if(distances_blur[neighbors[n]]!=NaN){
          average += distances_blur[neighbors[n]]
        }

      }
      average = average/9;

      distances_blur[i] = average

      // on each step refresh foot surface
      // if(distances[i]<upperfraction){
      //   distances_blur[i] = distances[i]
      // }

      



    }
  }




  //final merge original and blurred distance array
  for(var i = 0; i < resolution_x*resolution_y; i++){
    // if(distances[i]==maximum_dist2){
    //   distances[i] = distances_blur[i]
    // }


    //refresh blend is continuous, this will be used to lerp between blurred value and original value.
    //intent is to have 1.0 (full) refresh over near pixels, and 0.0 refresh for high arch (more blurring)
    let blurIntensity = map_range_clamp(distances[i], MathUtils.lerp(newmin,newmax,0.33), MathUtils.lerp(newmin,newmax,0.8), 0.0, 1.0)  //normalize distance, max distance should have 0 refresh.
    distances[i] = MathUtils.lerp(distances[i],distances_blur[i], blurIntensity)
  }

  //scan the array to find new min and new max
  var newmin2 = 2000
  var newmax2= -2000
  for(var i = 0; i<resolution_x*resolution_y;i++){
    if(distances[i]>newmax2){newmax2=distances[i]}
    if(distances[i]<newmin2){newmin2=distances[i]}
  }

  minimum_distance=newmin
  maximum_distance=newmax


  //console.log("test: lerp(-50,100,.80",MathUtils.lerp(-50,100,.80))


  //make the colors:
  for( var j = 0; j< resolution_x*resolution_y; j++){
    var value = map_range(distances[j], minimum_distance, maximum_distance, 0.001 ,250)
    //var rgb = colorsys.hsv_to_rgb( {h:value, s:1.0, v:0.5} )
    var rgb = new THREE.Color("hsl("+value+", 100%, 50%)")
    color[j*3+0]=rgb.r
    color[j*3+1]=rgb.g
    color[j*3+2]=rgb.b
  }

  //make and add the object:
  surface.setAttribute('position', new THREE.BufferAttribute(vertices,3))
  surface.setAttribute('distance', new THREE.BufferAttribute(distances,1))
  surface.setAttribute('color', new THREE.BufferAttribute(color,3))
  const surfacePoints = new THREE.Points(surface, mat_default)
  surfacePoints.name = "SURFACE"
  surfacePoints.max_value = maximum_distance
  surfacePoints.min_value = minimum_distance
  surfacePoints.resolution_x = resolution_x
  surfacePoints.resolution_y = resolution_y
  surfacePoints.spacing = spacing
  scene.add(surfacePoints)
  console.log("finished making surface...")
  console.log(surfacePoints)

  var endTime = Date.now()
  var calculation_time = (endTime - beginTime)
  console.log(calculation_time)


  create_image_v2(surfacePoints, resourceId, callback)
}

function create_image_v2(surface, resourceId, callback){
  console.log("png v2")
  //encode the bits in two separate channels

  console.log(surface)
  let width = surface.resolution_x;
  let height = surface.resolution_y;

  let options = {
    width: width,
    height: height,
    //colorType: 4, //greyscale with alpha -- default is 6(rgba)
    //inputColorType: 0,
    bitDepth: 8,
    //inputHasAlpha: true
  }
  let png = new PNG(options)
  let pixels = new Uint16Array(width*height)
  let ga = new Uint8Array(width*height*4) //grey plus alpha
  let distance_array = surface.geometry.attributes.distance.array.reverse()
  /*go through each point in surface, and use point data to create new pixel array.*/
  for( var j = 0; j< width*height; j++){
    var d = distance_array[j]
    let full = map_range(d, surface.min_value,surface.max_value,0xFFFF,0 )  //#FF=8bits
    let part1 = full & 0xFF
    let part2 = (full>> 8) & 0xFF

    pixels[j] = full
    // pixels[j*2]= part1
    // pixels[j*2+1]= part2
    ga[j*4+0] = part2;//red
    ga[j*4+1] = part1;//green
    ga[j*4+2] = 0x00;//blue
    ga[j*4+3] = 0xFF;//alpha
    // if(j<10){
    //   console.log( full.toString(2) )
    //   console.log(part1+"_"+part2)
    // }

  }
    //png.data = new Uint8Array(pixels.buffer)
    png.data = ga
    let buffer = PNG.sync.write(png, options)
    let blob = new Blob([buffer],{type:'image/png'})
    let url = URL.createObjectURL(blob)
    var img = new Image();
    img.src = url;


    console.log(img.src)
    let paramdata = {
      minY: surface.resolution_x*(surface.spacing*-0.5) * 1000,
      maxY: surface.resolution_x*(surface.spacing*0.5) * 1000,
      minX: surface.resolution_y*(surface.spacing*-0.5) * 1000,
      maxX: surface.resolution_y*(surface.spacing*0.5) * 1000,
      minZ: surface.min_value * 1000,
      maxZ: surface.max_value * 1000,
      image: buffer,
      resourceId: resourceId
    }
    console.log(paramdata)
    console.log(JSON.stringify(paramdata))
    var formData = new FormData();
    formData.append('minX', paramdata['minX']);
    formData.append('maxX', paramdata['maxX']);
    formData.append('minY', paramdata['minY']);
    formData.append('maxY', paramdata['maxY']);
    formData.append('minZ', paramdata['minZ']);
    formData.append('maxZ', paramdata['maxZ']);
    formData.append('resourceId', paramdata['resourceId']);
    formData.append('image', blob, 'curr.png');
    axios({
           'method': 'post',
           'url': 'https://pscan.andbounds.com/import',
           'data': formData,
           'headers': {'content-type': 'multipart/form-data'}
    }).then(() => {
        document.body.appendChild(img);
        callback();
    });
}

function placeMarkersLeft() {
  window.addEventListener('touchstart', click_marker_l);
  window.addEventListener('mousedown', click_marker_l);
  document.getElementById('tooltip').style.display=""
}

function placeMarkersRight() {
  window.addEventListener('touchstart', click_marker_r);
  window.addEventListener('mousedown', click_marker_r);
  document.getElementById('tooltip').style.display=""

}
function placeMeasureLeft(){
  markerStorage_l_measure = {points:[undefined,undefined]}
  window.addEventListener('touchstart', click_marker_l_measure);
  window.addEventListener('mousedown', click_marker_l_measure);
  document.getElementById('tooltip').style.display=""
}
function placeMeasureRight(){
  markerStorage_l_measure = {points:[undefined,undefined]}
  window.addEventListener('touchstart', click_marker_r_measure);
  window.addEventListener('mousedown', click_marker_r_measure);
  document.getElementById('tooltip').style.display=""
}



function scaleLeftPLY(){

  let lscale = document.getElementById('leftscaleinput').value
  let rscale = document.getElementById('rightscaleinput').value
  if(lscale==0){lscale=1}
  if(rscale==0){rscale=1}

  //if(lscale==0||rscale==0){console.log("please do not scale by 0");return}
  console.log("apply scale", lscale, rscale)


  //first we check to see if a ply has been loaded.
  //then we check to see if the ply has been scaled before.
  //after those two checks we can apply a new scale and store a value that lets us know how big it is compared to what it was originally.
  //we should be done after these things are complete.
  let leftFoot = undefined;
  let rightFoot = undefined;

  //get a reference to the left foot
  for (var i = 0; i < leftScene.children.length; i++) {
    if(leftScene.children[i].name =="leftScan"){
      leftFoot = leftScene.children[i]
    }
  }
  //get a reference to the right foot
  for (var i = 0; i < rightScene.children.length; i++) {
    if(rightScene.children[i].name =="rightScan"){
      rightFoot = rightScene.children[i]
    }
  }


  //can only do something if the ply file is loaded.
  if(leftFoot!=undefined){
    //is this the first time we added new scale?
    if (leftFoot.lastScale == undefined){
      leftFoot.lastScale = Math.pow(lscale, -1)
      leftFoot.scale.set(lscale,lscale,lscale)
    }
    //this foot has been scaled before...
    else{
      leftFoot.scale.set(leftFoot.scale.x*leftFoot.lastScale,leftFoot.scale.y*leftFoot.lastScale,leftFoot.scale.z*leftFoot.lastScale)
      //leftFoot.scale *= leftFoot.lastScale //make the scale 1 again
      leftFoot.lastScale = Math.pow(lscale, -1)
      leftFoot.scale.set(lscale,lscale,lscale)
    }
  }

    //can only do something if the ply file is loaded.
    if(rightFoot!=undefined){
      //is this the first time we added new scale?
      if (rightFoot.lastScale == undefined){
        rightFoot.lastScale = Math.pow(rscale, -1)
        rightFoot.scale.set(rscale,rscale,rscale)
      }
      //this foot has been scaled before...
      else{
        rightFoot.scale.set(rightFoot.scale.x*rightFoot.lastScale,rightFoot.scale.y*rightFoot.lastScale,rightFoot.scale.z*rightFoot.lastScale)
        rightFoot.lastScale = Math.pow(rscale, -1)
        rightFoot.scale.set(rscale,rscale,rscale)
      }
    }
    console.log("leftfoot",leftFoot)
    console.log("rightfoot",rightFoot)


}
function scaleRightPLY(){}

const stylecontrols = {
  display:"none",
}
const stylecontrolsl = {
  display:"none",
  position: "absolute",
  left: "10%"
}

function mirrorFeet() {
  for (var i = 0; i < leftScene.children.length; i++) {
    if (leftScene.children[i].name == 'leftScan') {
      leftScene.children[i].scale.set(-1, 1, 1);
    }
  }
  for (var i = 0; i < rightScene.children.length; i++) {
    if (rightScene.children[i].name == 'rightScan') {
      rightScene.children[i].scale.set(-1, 1, 1);
    }
  }
}

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}
export default function App() {

  const [hidden, setHidden] = useState(true);
  const [readyToSubmit, setReadyToSubmit] = useState(false);
  const query = useQuery();
  selectScanByURL(query.get('id'));
  /*
    old datalist:
      <div id="one" style={myStyle}>
        <ReactHTMLDatalist
          onChange={selectScan}
          options={unprocessedScans}
        />
      </div>
  */
  return (
    <div className="App">
      <header className="App-header" >
      </header>
      <div id="body">
        <h1 id="customer" style={myStyle2}></h1>
        <button style={stylecontrolsl} id="placeMarkersLeft" onClick={placeMarkersLeft}>Place Markers</button>
        <button style={stylecontrols} id="placeMarkersRight" onClick={placeMarkersRight}>Place Markers</button>
        {false && <button style={stylecontrolsl} id="mirrorFeet" onClick={mirrorFeet}>Mirror Feet</button>}
        <img style={stylecontrols} id="tooltip" src="instructions.svg"/>
        <div ref={leftRef} id="leftTHREE" className="THREE"></div>
        <div ref={rightRef} id="rightTHREE" className="THREE"></div>
        <div id="footer">
          {readyToSubmit && <button id="submitButton">Submit</button>}
          <button style={stylecontrols} id="restartButton" onClick={() => window.location.reload(false)}>Restart</button>
        </div>
        <div id="resource"></div>
      </div>
    </div>
  );
}
