import logo from './logo.svg';
import './App.css';
import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import {Link} from "react-router-dom";

const app_background_style = {
  backgroundColor:"#050505",
  width: "100%",
  height: "100%",
  position: "absolute",
}
const nav_style = {
  position:"absolute",
  zIndex:"10",
  top:"2em",
  left:"2em",
  color:"white",
  fontSize:"4em",
}
const link_style={
  paddingRight:"2em"
}
function App() {
  return (
    <div className="App" style={app_background_style}>
      <nav style={nav_style}>
        {/* <Link to="/match"   style={link_style}>Match </Link> */}
        <Link to="/surface" style={link_style}>Surface </Link>
      </nav>
    </div>
  );
}
export default App;
